import React, { Component } from 'react';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Olayliste from "./olayliste";
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';
import moment from "moment";
import Translate from 'react-translate-component'
import ReactToExcel from 'react-html-table-to-excel'
import PdfExcelEvent from './PdfExcelEvents'



class Olaytable extends Component {
    constructor(props) {
        super(props);
        let now = new Date();
        let end = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
        let start = moment(end).subtract(1, "hours");

        this.state = {
            kuleklealertShow: false,
            kuleklealertMessage: "",
            kuleklealertType: "",
            ustleveluserid: "",
            firma: "",
            adminusersSelectlist: [],
            kulsearch: [],
            start: start,
            end: end,
            fsensor: -1,
            fdurum: -1
        };

        this.DateSelectapplyCallback = this.DateSelectapplyCallback.bind(this);

    }



    getDevEvents(devid, startTime, endTime) {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }


            let apiLink = 'https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/events/' + devid;
            if (startTime != -1 && endTime != -1) {
                apiLink = 'https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/events/' + devid + "/timerange/" + startTime + "/" + endTime;
            }
            // console.log(apiLink);
            const sessionToken = session.getIdToken().jwtToken;
            Axios.get(apiLink, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                let sensorArray= [];
                let drmArr = [];
                for (var i = 0; i < res.data.Items.length; i++) {
                    let dupname = false;
                    let dupdrm = false;
                    for (var j = 0; j < sensorArray.length; j++) {
                        if (sensorArray[j].label == res.data.Items[i].sensor_name) {
                            dupname = true;
                        } 
                    }
                    if (!dupname) {
                        sensorArray.push({
                            value: res.data.Items[i].sensor_name,
                            label: res.data.Items[i].sensor_name
                        });
                    }
                    let eventdrm;
                    if ((res.data.Items[i].event_t == "altAlarm") || (res.data.Items[i].event_t == "ustAlarm")) {
                        eventdrm = "Alarm";
                    } else if ((res.data.Items[i].event_t == "pilHata") || (res.data.Items[i].event_t == "comHata")) {
                        eventdrm = "Hata";
                    } else {
                        eventdrm = "Normal";
                    }
                    for (var j = 0; j < drmArr.length; j++) {
                        if (drmArr[j].label == eventdrm) {
                            dupdrm = true;
                        } 
                    }
                    if (!dupdrm) {
                        drmArr.push({
                            value: eventdrm,
                            label: eventdrm
                        });
                    }
                }
                this.setState({ drmList: drmArr, SensorList: sensorArray, deviceEvents: res.data.Items });


            }).catch((error) => {
                console.log("error");
                console.log(error);
            });
        });
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.kuleklealertType} role="alert">
                <strong>{this.state.kuleklealertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    DateSelectapplyCallback(startDate, endDate) {
        let startDateF = Math.floor(new Date(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime() / 1000);
        let endDateF = Math.floor(new Date(endDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime() / 1000);
        
        /*console.log('bir onceki startDateEpoch', this.state.startDateEpoch);
        console.log('bir onceki endDateEpoch', this.state.endDateEpoch);
        console.log('startDateF', startDateF);
        console.log('endDateF', endDateF);
        console.log('startDate', startDate);
        console.log('endDate', endDate);
        */
        if (startDateF != this.state.startDateEpoch || endDateF != this.state.endDateEpoch) {
            this.setState({
                start: startDate,
                end: endDate,
                startDateFormated: startDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                endDateFormatted: endDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                startDateEpoch: startDateF,
                endDateEpoch: endDateF,
            });
            
            setTimeout(() => {
                this.getDevEvents(this.props.deviceid, this.state.startDateEpoch, this.state.endDateEpoch);
              }, 1000
              );
        

        } else {
            console.log("no change in date time picker")
        }
        


    }

    componentDidMount() {
        this.setState({
            ustleveluserid: this.props.userinfo.userId,
            firma: this.props.userinfo.firm
        });

        this.getDevEvents(this.props.deviceid, -1, -1);


    }

    render() {
        const isEn=localStorage.getItem('lang');
        // console.log(this.state);
        const options1 = [
            { value: 'ALARM', label: 'ALARM' },
            { value: 'HATA', label: 'HATA' } 
        ];
        const options2 = [
            { value: 'SENSOR1', label: 'SENSOR1' },
            { value: 'SENSOR2', label: 'SENSOR2' } 
        ];

        let value = `${this.state.start.format(
            "DD-MM-YYYY HH:mm"
        )} - ${this.state.end.format("DD-MM-YYYY HH:mm")}`;
        let disabled = false;
        let now = new Date();
        let start = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
        );
        let end = moment(start)
            .add(1, "days")
            .subtract(1, "seconds");
        let exactNow = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())
        );
        let ranges = {
            "Son saat": [moment(exactNow).subtract(1, "hours"), moment(exactNow)],
            "Son 8 saat": [moment(exactNow).subtract(8, "hours"), moment(exactNow)],
            "Sadece bugün": [moment(start), moment(end)],
            "Sadece dün": [
                moment(start).subtract(1, "days"),
                moment(end).subtract(1, "days")
            ],
            "3 gün": [moment(start).subtract(3, "days"), moment(end)],
            "5 gün": [moment(start).subtract(5, "days"), moment(end)],
            "1 hafta": [moment(start).subtract(7, "days"), moment(end)],
            "2 hafta": [moment(start).subtract(14, "days"), moment(end)],
            "1 ay": [moment(start).subtract(1, "months"), moment(end)],
        };
        let local = {
            format: "DD-MM-YYYY HH:mm",
            sundayFirst: false,
            days: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
            months: [
                'Ocak',
                'Şubat',
                'Mart',
                'Nisan',
                'Mayıs',
                'Haziran',
                'Temmuz',
                'Ağustos',
                'Eylül',
                'Ekim',
                'Kasım',
                'Aralık',],
            fromDate: 'Bu tarihten',
            toDate: 'Bu tarihe',
            selectingFrom: 'Bu tarihten seçme',
            selectingTo: 'Bu tarihe seçme',
            maxDate: 'maksimum tarih',
            close: 'Kapat',
            apply: 'Uygula',
            cancel: 'İptal'
        };
        let maxDate = moment(end).add(24, "hour");
        return (
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="col-md-2 col-sm-12 col-xs-12">
                    <h3 class="m-t-0 m-b-30"><Translate content="event_paragraph" /></h3>
                </div>
                <div  class="col-md-10 col-sm-12 col-xs-12">
                    <div  class="row" >
                        <div class="col-md-2 col-sm-6 col-xs-12">
                        <div >
                       {
                           isEn?
                            <Select
                                options={this.state.drmList}
                                onChange={(value) => this.setState({ fdurum: value.value })}
                                placeholder={<Translate content="event_paragraph5" ></Translate>}
                                // placeholder={"CONDITION"}
                            />
                           :
                            <Select
                            options={this.state.drmList}
                            onChange={(value) => this.setState({ fdurum: value.value })}
                            
                            // placeholder={"DURUM"}
                            placeholder={<Translate content="event_paragraph5" ></Translate>}
                        /> 
                           
                       }
                       </div>
                                
                                <template>
                            <table  id="table-to-xls"  >
                                         <tr>
                                        
                                         <PdfExcelEvent cihazid={this.props.deviceid} eventlist={this.state.deviceEvents} kulsearch={this.state.kulsearch} filtersensor={this.state.fsensor} filterdrm={this.state.fdurum}/>
                                
                                         </tr>
                                     
                                     </table>
                            </template>


                            

                            {/* <Translate component="select"
                                    
                                    options={this.state.drmList}
                                    placeholder={{placeholder:'event_paragraph'}} 
                                    // placeholder="Şifre"
                                    onChange={(value) => this.setState({ fdurum: value.value })}
                                    
                                    /> */}

                        </div>
                        <div class="col-md-2 col-sm-6 col-xs-12">
                        <div >
                            <Select
                                options={this.state.SensorList}
                                onChange={(value) => this.setState({ fsensor: value.value })}
                                placeholder={<Translate content="event_paragraph9"></Translate>}
                            />
                        </div>
                        </div>
                        <div  class="col-md-8 col-sm-6 col-xs-12" >
                            <DateTimeRangeContainer
                                ranges={ranges}
                                start={this.state.start}
                                end={this.state.end}
                                local={local}
                                maxDate={maxDate}
                                applyCallback={this.DateSelectapplyCallback}
                                smartMode
                            >
                                <FormControl
                                    id="formControlsTextB"
                                    type="text"
                                    label="Text"
                                    placeholder="Enter text"
                                    style={{ cursor: "pointer" }}
                                    disabled={disabled}
                                    value={value}
                                />
                            </DateTimeRangeContainer>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="white-boxx"  style={{ "margin-bottom": "100px"}} >

                        <div class="table-responsive">
                            <div className="col-md-12 pull-right" style={{ "margin-bottom": "20px", "padding-left": "0", "padding-right": "2px" }}>
                                <div className="col-md-8" style={{ "padding-left": "0" }}>
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="event_paragraph1" /></button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="event_paragraph2" /></button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "70px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="event_paragraph3" /></button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="event_paragraph4" /></button> */}
                                    <ReactToExcel className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} id="tableExcel"  table="table-to-xls" table="table-to-xls" filename={`${this.props&&this.props.deviceid}-GECMIS_OLAYLAR`} sheet="Esense Report" buttonText="Excel" />
                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>

                            <Olayliste cihazid={this.props.deviceid} eventlist={this.state.deviceEvents} kulsearch={this.state.kulsearch} filtersensor={this.state.fsensor} filterdrm={this.state.fdurum}/>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Olaytable;
