import React, { Component } from 'react';
import { useState } from 'react';
import Sidebar from '../Components/sidebar';
import Ozelliklervesureler from '../Components/ayarlar/ozellikler';

import Kayitlikullanicilar from '../Components/ayarlar/kayitlikullanicilar';
import Kurallar from '../Components/ayarlar/kurallar';
import Agayarlar from '../Components/ayarlar/agayarlar';
import Bildirmayar from '../Components/ayarlar/bildirimayarlar';
import Translate from 'react-translate-component'


class Ayarlar extends Component {
    state = {
        reload: false,
        username: null,
        email: null,
        password: null,
        confirmpassword: null,
        name: null,
        family_name: null,
        address: null,
        phone_number: null,
        locale: null,
        picture: null,
        users: null,
        devices: null,
        userToregisterDevice: null,
        deviceToregister: null,
    };

    constructor(props) {
        super(props);
    }


    

    componentDidMount() {
        this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    }
    render() {
        

        return (

            <div id="page-wrapper" >
                <div class="container-fluid p-t-20" >
                    <div class="row" >
                        <div class="col-md-2 col-sm-12 col-xs-12" >
                            {this.props.userinfo !== undefined &&
                                this.props.userinfo.configs !== undefined &&
                                <Sidebar devlist={this.props.userinfo.configs.regdevices} kulsev={this.props.userinfo.authLevel} selecteddev={this.props.match.params.devindex} page={"ayarlar/"} />
                            }
                        </div>
                        <div class="col-md-10 col-sm-12 col-xs-12">
                            <div class="row">
                                {(this.props.match.params.devindex !== undefined) &&
                                    this.props.userinfo !== undefined &&
                                    <Ozelliklervesureler deviceid={this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid}  />
                                }
                                {
                                    this.props.match.params.devindex === undefined &&
                                    <div role="alert" style={{ "padding": "10px","border":"3px solid #138BAC","borderRadius":"10px","backgroundColor":"#fff" }}>
                                        <strong><Translate content="account_paragraph22"  /></strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                               }
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    {(this.props.match.params.devindex !== undefined) &&
                                        this.props.userinfo !== undefined &&
                                        <Bildirmayar userinfo={this.props.userinfo} deviceid={this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid} />
                                    }
                                   
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    {(this.props.match.params.devindex !== undefined) &&
                                        this.props.userinfo !== undefined &&
                                        <Kayitlikullanicilar userinfo={this.props.userinfo} deviceid={this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid} />
                                    }
                                   
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    {/* <Kurallar /> */}
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    {/* <Agayarlar /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default Ayarlar;
