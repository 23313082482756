import React, { Component } from 'react';
// import Validate from "./utility/FormValidation";
import Sidebar from '../sidebar';
import Raportable from './raportable';
import Select from "react-select";
// import { DateRangePicker } from 'rsuite';
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import DateTimePicker from 'react-datetime-picker';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import moment from "moment"
import Translate from 'react-translate-component'

class Rapor extends Component {
    state = {
        reload: false,
        username: null,
        email: null,
        password: null,
        confirmpassword: null,
        name: null,
        family_name: null,
        address: null,
        phone_number: null,
        locale: null,
        picture: null,
        users: null,
        devices: null,
        userToregisterDevice: null,
        deviceToregister: null,
        startDate: null,
        endDate: null,
        endDateFormatted: null,
        startDateFormatted: null,
    };

    constructor(props) {
        super(props);
        let start = moment(new Date());
        let end = moment(start)
            .add(5, "days")
            .subtract(1, "second");
        this.state = {
            start: start,
            end: end
        };

        this.onClick = this.onClick.bind(this);
        this.applyCallback = this.applyCallback.bind(this);
        this.setSensorList = this.setSensorList.bind(this);
    }


    hundleDateChange(startDate, endDate) {
        this.setState(() => ({
            endDate,
            startDate,
        }));
        if (startDate != null) {
            this.setState(() => ({
                startDateFormatted: startDate.format("D-MM-Y"),
            }));
        }
        if (endDate != null) {
            this.setState(() => ({
                endDateFormatted: endDate.format("D-MM-Y"),
            }));
        }
    }

    applyCallback(startDate, endDate) {
        // console.log("Apply Callback");
        // console.log(new Date(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime());
        // console.log(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS"));
        // console.log(endDate.getTime());
        this.setState({
            start: startDate,
            end: endDate,
            startDateFormated: startDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
            endDateFormatted: endDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
            startDateEpoch: new Date(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime(),
            endDateEpoch: new Date(endDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime(),
        });
    }

    rangeCallback(index, value) {
    }

    onClick() {

    }

    setSensorList(sensList) {
        this.setState({
            sensorList: sensList
        });

    }

    componentDidMount() {
        this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    }
    render() {

        const options1 = [
            { value: 'ALARM', label: 'ALARM' },
            { value: 'HATA', label: 'HATA' }
        ];
        let options2 = []

        options2 = this.sensorList;

        let value = `${this.state.start.format(
            "DD-MM-YYYY HH:mm"
        )} - ${this.state.end.format("DD-MM-YYYY HH:mm")}`;
        let disabled = false;
        let now = new Date();
        let start = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
        );
        let end = moment(start)
            .add(1, "days")
            .subtract(1, "seconds");
        let exactNow = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())
        );

        let ranges = {
            "Last Hour": [moment(exactNow).subtract(1, "hours"), moment(exactNow)],
            "Last  8 Hours": [moment(exactNow).subtract(8, "hours"), moment(exactNow)],
            "Today Only": [moment(start), moment(end)],
            "Yesterday Only": [
                moment(start).subtract(1, "days"),
                moment(end).subtract(1, "days")
            ],
            "3 Days": [moment(start).subtract(3, "days"), moment(end)],
            "5 Days": [moment(start).subtract(5, "days"), moment(end)],
            "1 Week": [moment(start).subtract(7, "days"), moment(end)],
            "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
            "1 Month": [moment(start).subtract(1, "months"), moment(end)],
        };
        let local = {
            format: "DD-MM-YYYY HH:mm",
            sundayFirst: false
        };
        let maxDate = moment(end).add(24, "hour");

        return (

            <div id="page-wrapper">
                <div class="container-fluid p-t-20">
                    <div class="row">
                        <div class="col-md-2 col-sm-12 col-xs-12">
                            {this.props.userinfo !== undefined &&
                                this.props.userinfo.configs !== undefined &&
                                <Sidebar devlist={this.props.userinfo.configs.regdevices} kulsev={this.props.userinfo.authLevel} selecteddev={this.props.match.params.devindex} page={"rapor/"} />
                            }
                        </div>
                        <div class="col-md-10 col-sm-12 col-xs-12">
                            <div class="row">
                                {
                                    this.props.match.params.devindex === undefined &&
                                    <div role="alert" style={{ "padding": "10px","border":"3px solid #138BAC","borderRadius":"10px","backgroundColor":"#fff" }}>
                                        <strong><Translate content="account_paragraph20"  /></strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                }
                                {(this.props.match.params.devindex !== undefined) &&
                                    this.props.userinfo !== undefined &&
                                    <Raportable userinfo={this.props.userinfo} deviceid={this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid} setSensorList={this.setSensorList} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default Rapor;
