import React, { Component } from 'react';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Raporliste from "./raporliste";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import DateTimePicker from 'react-datetime-picker';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import moment from "moment"
import ReactToExcel from 'react-html-table-to-excel'
import PdfExcelReport from './PdfExcelReport'
import ReactLoading from "react-loading";
import { Section, Title, Article, Prop, list, SectionAler } from "./../generik";
import Translate from 'react-translate-component'

class Raportable extends Component {
    constructor(props) {
        super(props);
        let now = new Date();
        let end = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds()));
        let start = moment(end).subtract(1, "hours");

        this.state = {
            kuleklealertShow: false,
            kuleklealertMessage: "",
            kuleklealertType: "",
            ustleveluserid: "",
            firma: "",
            adminusersSelectlist: [],
            kulsearch: [],
            start: start,
            end: end,
            fsensor: 0,
            flag:false,
        };




        this.onClick = this.onClick.bind(this);
        this.DateSelectapplyCallback = this.DateSelectapplyCallback.bind(this);
        this.setSensorList = this.setSensorList.bind(this);
    }

    setSensorList(sensList) {
        this.setState({
            sensorList: sensList
        });

    }

    DateSelectapplyCallback(startDate, endDate) {
        let startDateF = Math.floor(new Date(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime() / 1000);
        let endDateF = Math.floor(new Date(endDate.format("YYYY-MM-DDTHH:mm:ss.SSS")).getTime() / 1000);
        if (startDateF != this.state.startDateEpoch && endDateF != this.state.endDateEpoch) {
            this.setState({
                start: startDate,
                end: endDate,
                startDateFormated: startDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                endDateFormatted: endDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
                startDateEpoch: startDateF,
                endDateEpoch: endDateF,
            });
            
            setTimeout(() => {
                this.getDevEvents(this.props.deviceid, this.state.startDateEpoch, this.state.endDateEpoch);
              }, 1000
              );
        

        } else {
            console.log("no change in date time picker")
        }
        


    }

    rangeCallback(index, value) {
        
    }

    onClick() {

    }
    getDevEvents(devid, startTime, endTime) {
        // console.log(devid + " " + startTime + " " + endTime);
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }
            this.setState({flag:true})
            let apiLink = 'https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/' + devid + "/h";
            if (startTime != -1 && endTime != -1) {
                apiLink = 'https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/' + devid + "/timerange/" + startTime + "/" + endTime;
            }

            const sessionToken = session.getIdToken().jwtToken;
            Axios.get(apiLink, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                let sensorArray = [];
                console.log("get ok");
                // console.log(res.data);
                if (startTime != -1 && endTime != -1) {
                    for (var i = 0; i < res.data.Items[res.data.Items.length - 1].payload.sensorveri.length; i++) {
                        sensorArray.push({
                            value: i,
                            label: res.data.Items[res.data.Items.length - 1].payload.sensorveri[i].ad
                           
                        })
                    }
                    this.setState({ flag:false,thisDevSensorList: sensorArray, thisDevData: res.data.Items });
                } else {
                    for (var i = 0; i < res.data.Items[res.data.Items.length - 1].payload.sensorveri.length; i++) {
                        sensorArray.push({
                            value: i,
                            label: res.data.Items[res.data.Items.length - 1].payload.sensorveri[i].ad
                        })
                    }
                    this.setState({ flag:false,thisDevSensorList: sensorArray, thisDevData: res.data.Items });
                }

                

            }).catch((error) => {
                this.setState({flag:false})
                // console.log("error");
                console.log(error);
            });
        });
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.kuleklealertType} role="alert">
                <strong>{this.state.kuleklealertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            ustleveluserid: this.props.userinfo.userId,
            firma: this.props.userinfo.firm
        });

        this.getDevEvents(this.props.deviceid, -1, -1);


    }

    render() {


        const options1 = [
            { value: 'ALARM', label: 'ALARM' },
            { value: 'HATA', label: 'HATA' }
        ];
        

        let value = `${this.state.start.format(
            "DD-MM-YYYY HH:mm"
        )} - ${this.state.end.format("DD-MM-YYYY HH:mm")}`;
        let disabled = false;
        let now = new Date();
        let start = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
        );
        let end = moment(start)
            .add(1, "days")
            .subtract(1, "seconds");
        let exactNow = moment(
            new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())
        );
        
        let ranges = {
            "Son Saat": [moment(exactNow).subtract(1, "hours"), moment(exactNow)],
            "Son 8 Saat": [moment(exactNow).subtract(8, "hours"), moment(exactNow)],
            "Sadece Bugün": [moment(start), moment(end)],
            "Sadece Dün": [
                moment(start).subtract(1, "days"),
                moment(end).subtract(1, "days")
            ],
            "3 Gün": [moment(start).subtract(3, "days"), moment(end)],
            "5 Gün": [moment(start).subtract(5, "days"), moment(end)],
            "1 Hafta": [moment(start).subtract(7, "days"), moment(end)],
            "2 Hafta": [moment(start).subtract(14, "days"), moment(end)],
            "1 Ay": [moment(start).subtract(1, "months"), moment(end)],
        };
        let local = {
            format: "DD-MM-YYYY HH:mm",
            sundayFirst: false,
            days: ['Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt', 'Paz'],
            months: [
                'Ocak',
                'Şubat',
                'Mart',
                'Nisan',
                'Mayıs',
                'Haziran',
                'Temmuz',
                'Ağustos',
                'Eylül',
                'Ekim',
                'Kasım',
                'Aralık',],
            fromDate: 'Bu tarihten',
            toDate: 'Bu tarihe',
            selectingFrom: 'Bu tarihten seçme',
            selectingTo: 'Bu tarihe seçme',
            maxDate: 'maksimum tarih',
            close: 'Kapat',
            apply: 'Uygula',
            cancel: 'İptal'
        };
        let maxDate = moment(end).add(24, "hour");
        return (
            <div class="col-md-12 col-sm-12 col-xs-12" >
                <div class="col-md-3 col-sm-12 col-xs-12">
                    <h3 class="m-t-0 m-b-30 left-border">RAPORLAMA</h3>
                </div>
                <div class="col-md-9 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-md-3 col-sm-6 col-xs-12">
                            {this.state.thisDevSensorList != undefined &&
                                <Select
                                style={{width:"100px"}}
                                    options={this.state.thisDevSensorList}
                                    values={[]}
                                    onChange={(value) => this.setState({ fsensor: value.value })}
                                    placeholder={<Translate content="event_paragraph9"/>}
                                />
                                }
                        </div>
                        <div class="col-md-9 col-sm-6 col-xs-12" style={{ "float": "right" }} onClick={this.onClick}>
                            
                            <DateTimeRangeContainer
                                ranges={ranges}
                                start={this.state.start}
                                end={this.state.end}
                                local={local}
                                maxDate={maxDate}
                                applyCallback={this.DateSelectapplyCallback}
                                rangeCallback={this.rangeCallback}
                                smartMode
                            >
                                <FormControl
                                    id="formControlsTextB"
                                    type="text"
                                    label="Text"
                                    placeholder="Enter text"
                                    style={{ cursor: "pointer" }}
                                    disabled={disabled}
                                    value={value}
                                />
                            </DateTimeRangeContainer>
                        </div>
                    </div>
                </div>

                <template>
                            <table  id="table-to-xls"  >
                                         <tr>
                                         {this.state.thisDevData && 
                            <PdfExcelReport cihazid={this.props.deviceid} raporlist={this.state.thisDevData} kulsearch={this.state.kulsearch} filtersensor={this.state.fsensor} />
                                }
                                         </tr>
                                     
                                     </table>
                            </template>

                            <div>
                                {
                                    this.state.flag===true?
                                    <div style={{top:"50%",left:"50%", margin:"0 auto"}}>
                                        <Section style={{backgroundColor:'#fff'}} >
                                        
                                        <ReactLoading  type="spin" height="50px" width="50px"  color="#138BAC"/>
                                        <Title style={{color:'#138BAC'}}><Translate content="event_paragraph8"  /></Title>
                                        </Section>
                                    </div>:<div>

                                    <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="white-boxx" style={{ "margin-bottom": "100px"}}>

                        <div class="table-responsive">
                            <div className="col-md-12 pull-right" style={{ "margin-bottom": "20px", "padding-left": "0", "padding-right": "2px" }}>
                                <div className="col-md-8" style={{ "padding-left": "0" }}>
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" >Kopyala</button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" >Excel</button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "70px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" >Yazdır</button> */}
                                    {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" >PDF</button> */}
                                    <ReactToExcel className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} id="tableExcel" table="table-to-xls" filename={`${this.props&&this.props.deviceid}-DETAYLI_RAPOR`} sheet="Esense Report" buttonText="Excel" />
                                </div>
                                <div className="col-md-2">
                                </div>

                            </div>
                                {this.state.thisDevData && 
                            <Raporliste cihazid={this.props.deviceid} raporlist={this.state.thisDevData} kulsearch={this.state.kulsearch} filtersensor={this.state.fsensor} />
                                }
                        </div>
                    </div>

                </div>

                                    </div>
                                }
                            
                            </div>
               
            </div>

        );
    }
}

export default Raportable;