import React, { Component } from 'react';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";
import Cihazakayitliliste from "./cihazakayitliliste";
import Translate from 'react-translate-component';
import ReactToExcel from 'react-html-table-to-excel';
import ReactToPrint from 'react-to-print';

class kayitlikulllanicilar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            secilenkulseviye: "izle",
            cihazkullistediv: "block",
            yeniemaildiv: "none",
            yetkiseviyesidiv: "none",
            cihazasecilenemail: "",
            cihazasecilenuserid: "",
            yenikullanicichecked: false,
            yeniemail: "",
            kuleklealertShow: false,
            kuleklealertMessage: "",
            kuleklealertType: "",
            ustleveluserid: "",
            firma: "",
            adminusers: [],
            adminusersSelectlist: [],
            kulsearch: []
        };
        this.handleyenikulcheck = this.handleyenikulcheck.bind(this);
        this.yeniemailchange = this.yeniemailchange.bind(this);
        this.kuleklegonder = this.kuleklegonder.bind(this);

    }

    handleyenikulcheck(event) {
        if (event.target.checked) {
            this.setState({
                yenikullanicichecked: true,
                cihazkullistediv: "none",
                yeniemaildiv: "block",
                yetkiseviyesidiv: "block"
            });
        } else {
            this.setState({
                yenikullanicichecked: false,
                cihazkullistediv: "block",
                yeniemaildiv: "none",
                yetkiseviyesidiv: "none"
            });
        }


    }

    emailsendpost() {
        var CryptoJS = require("crypto-js");
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;

            let msg = {};
            if (this.state.yenikullanicichecked) {
                msg = {
                    "msgtype": "newusersendemail",
                    "email": this.state.yeniemail,
                    "authlevel": CryptoJS.AES.encrypt(this.state.secilenkulseviye, 'edsgizemi').toString().split('+').join('aRtI').split('/').join('tAkSiM').split('=').join('EsIt'),
                    "deviceid": CryptoJS.AES.encrypt(this.props.deviceid, 'edsgizemi').toString().split('+').join('aRtI').split('/').join('tAkSiM').split('=').join('EsIt'),
                    "firm": CryptoJS.AES.encrypt(this.props.userinfo.firm, 'edsgizemi').toString().split('+').join('aRtI').split('/').join('tAkSiM').split('=').join('EsIt'),
                    "topuserid": CryptoJS.AES.encrypt(this.props.userinfo.userId, 'edsgizemi').toString().split('+').join('aRtI').split('/').join('tAkSiM').split('=').join('EsIt')
                };
            } else {
                msg = {
                    "msgtype": "newusersendemail",
                    "userid": this.state.cihazasecilenuserid,
                    "deviceid": this.props.deviceid
                };
            }

            Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then(() => {
                this.setState({ kuleklealertType: "alert-success", kuleklealertMessage: <Translate content="summary_system_paragraph18" />, kuleklealertShow: true });

                setTimeout(() => {
                    this.setState({ kuleklealertShow: false });
                    document.getElementById("CloseButtonKulEkle").click();
                }, 2000
                );
                this.setState({
                    yeniemail: "",
                    cihazkullistediv: "block",
                    yeniemaildiv: "none"
                });
                document.getElementById("cihaza_yeni_kullanici").checked = false;
                document.getElementById("yeni_email").value = "";

            }).catch((error) => {
                
                this.setState({ kuleklealertType: "alert-warning", kuleklealertMessage: <Translate content="summary_system_paragraph19" /> + error, kuleklealertShow: true });
            });
        });
    }

    getThisAdminUsers(useridd) {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;
            Axios.get('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/adminusers/' + useridd, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                let arr = [];
                let arr2 = [];
                for (var i = 0; i < res.data.length; i++) {


                    var tkulsev;
                    switch (res.data[i].authLevel) {
                        case 1:
                            tkulsev = "sup";
                            break;
                        case 2:
                            tkulsev = "oper";
                            break;
                        case 3:
                            tkulsev = "izle";
                            break;
                        case 4:
                            tkulsev = "adm";
                            break;
                        default:
                            break;
                    }

                    let tsearchfield = res.data[i].userEmail + " " + res.data[i].configs.namesurename;
                    arr.push(
                        {
                            userid: res.data[i].userId,
                            usermail: res.data[i].userEmail,
                            yetkisev: tkulsev,
                            adsoyad: res.data[i].configs.namesurename,
                            regdevs: res.data[i].configs.regdevices,
                            usertel: res.data[i].configs.tel,
                            searchfield: tsearchfield
                        });

                    arr2.push(
                        {
                            value: res.data[i].userId,
                            label: res.data[i].userEmail
                        }
                    )

                }


                this.setState({
                    
                    adminusers: arr,
                    adminusersSelectlist: arr2.filter(function(el){
                        return el.label!=Auth.user.attributes.email})
                });


            }).catch((error) => {
                console.log("error");
                console.log(error);
            });
        });
    }




    kuleklegonder() {
        if (this.state.yeniemail!=Auth.user.attributes.email) {
        if (this.state.yenikullanicichecked) {
            // yeni kullaniciye kayit olmayan email adresine gonderilecek
            // check email format
            const expr = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (expr.test(String(this.state.yeniemail).toLowerCase())) {
                // do the sending procedure
                this.setState({ kuleklealertShow: false });
                this.emailsendpost();

            } else {
                // display an email error message
                this.setState({
                    kuleklealertShow: true,
                    kuleklealertType: "alert-danger",
                    kuleklealertMessage: "Lütfen email kontrol edin.."
                });

            }
        }
        else {
            // listeden bir kullanici secilecek
            this.emailsendpost();
        }
    }else{
        // display an email error message
        // console.log('You can\'t add yourself');
        this.setState({
            kuleklealertShow: true,
            kuleklealertType: "alert-danger",
            kuleklealertMessage: "You can\'t add yourself"
        });
    }

    }

    yeniemailchange(event) {
        if (event.target.value!=Auth.user.attributes.email) {
            this.setState({ yeniemail: event.target.value })
            console.log(event.target.id);
        }
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.kuleklealertType} role="alert">
                <strong>{this.state.kuleklealertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    componentDidMount() {
        this.setState({
            ustleveluserid: this.props.userinfo.userId,
            firma: this.props.userinfo.firm
        });

        if (this.props.userinfo.authLevel == 1) {
            this.getThisAdminUsers("all");
        } else if (this.props.userinfo.authLevel == 4) {
            this.getThisAdminUsers(this.props.userinfo.userId);
        }

    }

    render() {

        return (
            <div>
                {this.props.userinfo.authLevel==1 || this.props.userinfo.authLevel == 4?
            <div class="white-box" style={{ "margin-bottom": "100px"}} >
                <h3 class="m-t-0 m-b-30 left-border"><Translate content="registered_users_paragraph" /></h3>
                <div class="table-responsive">
                    <div className="col-md-12 pull-right" style={{ "margin-bottom": "20px", "padding-left": "0", "padding-right": "2px" }}>
                        <div className="col-md-8" style={{ "padding-left": "0" }}>
                            <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="registered_users_paragraph1" /></button>
                            {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="registered_users_paragraph11" /></button> */}
                            {/* <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "70px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="registered_users_paragraph12" /></button> */}
                            <ReactToPrint
                            trigger={() => {
                                 return <button className="btn btn-info waves-effect waves-light" style={{ "width": "80px", "margin-right": "5px", "border-radius": "4px" }} ><Translate content="registered_users_paragraph12" /></button>;
                            }}
                            content={() => this.componentRef}
                            />
                            <template>
                            <Cihazakayitliliste cihazid={this.props.deviceid} adminusers={this.state.adminusers}  kulsearch={this.state.kulsearch} ref={el => (this.componentRef = el)} />
                            </template>
                            <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} value="data-id" ><Translate content="registered_users_paragraph13" /></button>
                            <ReactToExcel type="button" id="tableExcel" className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "30px" }} table="table-to-xls" filename="excelFile" sheet="Esense Report" buttonText="Excel" />
                        </div>
                        <div className="col-md-2">
                            <div>
                                {(localStorage.getItem('lang')==='tr')?
                                <input data-target="#kulEkleModal" style={{'width':'160px'}} data-toggle="modal" type="button" id="openBtn1" data-id="1" className="btn btn-info waves-effect waves-light" value="Kullanıcı Ekle" />
                                :
                                <input data-target="#kulEkleModal" style={{'width':'160px'}} data-toggle="modal" type="button" id="openBtn1" data-id="1" className="btn btn-info waves-effect waves-light" value="Add User" />
                                }
                            </div>
                            {/* <Translate component="button"
                                data-toggle="modal"
                                className="btn btn-info waves-effect waves-light" 
                                data-target="#kulEkleModal"
                                attributes={{placeholder:'registered_users_paragraph16'}} 
                                data-id="1"
                                id="openBtn1"
                                    className="form-control"
                                     /> */}

                        </div>



                        <div className="col-md-2" style={{ "padding-right": "0" }}>
                            <form action="" method="GET" role="search" className="app-search m-b-5">
                                <button type="submit" style={{ "height": "27px", "position": "absolute", "top": "3px", "left": "2px", "background": "white","border": "0", "color": "#138BAC", "font-size": "19px" }}><i className="fa fa-search"></i></button>
                                {/* <input type="text" name="kullanici_search" className="form-control" placeholder="Kullanıcı ara" style={{ "padding-left": "40px" }} onChange={(e) => this.setState({kulsearch: e.target.value.split(' ')})} /> */}
                                <Translate component="input"
                                style={{ "padding-left": "40px" }} 
                                attributes={{placeholder:'registered_users_paragraph15'}} 
                                value={this.state.password}
                                placeholder="Kullanıcı ara"
                                type="text"
                                onChange={(e) => this.setState({kulsearch: e.target.value.split(' ')})} 
                                name="kullanici_search"
                                    className="form-control"
                                     />
                            </form>
                        </div>

                    </div>
                    <div className="modal fade" id="kulEkleModal" tabIndex="-1" role="dialog" aria-labelledby="kulEkleModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <button id={"CloseButtonKulEkle"} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <div id="wrapper">
                                        <div className="white-box m-b-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <h3 className="m-t-0 m-b-30 left-border"><Translate content="registered_users_paragraph14" /></h3>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <form action="" data-toggle="validator">

                                                        <div className="form-horizontal">
                                                            <div className="form-group">
                                                                <label className="col-xs-4 control-label"><Translate content="registered_users_paragraph17" /></label>
                                                                <div className="col-xs-8 pull-right">
                                                                    <div class="col-sm-8 checkbox-style" style={{ "padding-left": "0" }}>
                                                                        <div class="checkbox checkbox-info checkbox-circle">
                                                                            <input type="checkbox" id="cihaza_yeni_kullanici" name="cihaza_yeni_kullanici" /*checked={this.state.yenikullanicichecked} */ onChange={this.handleyenikulcheck} />
                                                                            <label for="cihaza_yeni_kullanici"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group" style={{ "display": this.state.yeniemaildiv }}>
                                                                <label className="col-xs-4 control-label"><Translate content="registered_users_paragraph18" /></label>
                                                                <div className="col-xs-8 pull-right">
                                                                    <input type="text" name="yeni_email" id="yeni_email" className="form-control" Value={this.state.yeniemail} required onChange={this.yeniemailchange} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group" style={{ "display": this.state.cihazkullistediv }}>
                                                                <label className="col-xs-4 control-label"><Translate content="registered_users_paragraph19" /></label>
                                                                <div className="col-xs-8 pull-right">
                                                                    <Select
                                                                        options={this.state.adminusersSelectlist}
                                                                        values={this.state.adminusersSelectlist.filter(user => user.userEmail === this.state.cihazasecilenemail)}
                                                                        onChange={(value) => this.setState({ cihazasecilenemail: value.label, cihazasecilenuserid: value.value })}
                                                                        placeholder={"SEÇ"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group" style={{ "display": this.state.yetkiseviyesidiv }}>
                                                                <label className="col-xs-4 control-label"><Translate content="registered_users_paragraph20" /></label>
                                                                <div className="col-xs-8 pull-right">                                                                    
                                                                    <Select
                                                                        defaultValue={{ value: 'isleyici', label: 'İzleyici' }}
                                                                        options={[
                                                                            { value: 'izle', label: 'İzleyici' },
                                                                            { value: 'oper', label: 'Operatör' }
                                                                        ]}
                                                                        values={[]}
                                                                        onChange={(value) => this.setState({ secilenkulseviye: value.value })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-xs-4 control-label hidden-xs"></label>
                                                                <div className="col-xs-8 pull-right">
                                                                    <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "200px", "visibility": "true" }} value="data-id" onClick={() => { this.kuleklegonder() }}><Translate content="registered_users_paragraph21" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                {this.state.kuleklealertShow && this.ShowMessage()}
                                                            </div>
                                                        </div>




                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <Cihazakayitliliste admininfo={this.props.userinfo} cihazid={this.props.deviceid} adminusers={this.state.adminusers} kulsearch={this.state.kulsearch}/>
                    <template>
                            <table  id="table-to-xls"  >
                                         <tr>
                                        
                                         <Cihazakayitliliste  cihazid={this.props.deviceid} adminusers={this.state.adminusers}  kulsearch={this.state.kulsearch}/>
                                
                                         </tr>
                                     
                                     </table>
                            </template> 
                </div>
                </div>:<div></div>
            }</div>

        );
    }
}

export default kayitlikulllanicilar;