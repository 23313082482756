import React, { Component } from 'react';
import { getAllByPlaceholderText } from '@testing-library/react';
import { Auth } from 'aws-amplify';
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
  } from "react-router-dom";

counterpart.registerTranslations('tr',tr);
counterpart.registerTranslations('en',en);
counterpart.setLocale('tr')

class Footer extends Component {

	state={
        lang:'tr'
    }
    onLangChange=(e)=>{
        this.setState({lang:e.target.value})
        counterpart.setLocale(e.target.value)
    }


    constructor(props) {
		super(props);
		this.state={Login:sessionStorage.getItem("Login")};
		//console.log("MyLogin Bilgi : "+this.props.auth.isAuthenticated);
	}
    render() {
        if(this.props.auth.isAuthenticated){
            return ( 
           <div>
		
<footer className="footer2">

		<div className="row visible-xs">
			<div className="col-xs-12 text-center m-b-10 store">
				<h5 className="m-b-15">UYGULAMAMIZI İNDİRİN</h5>
				<div className="col-sm-6 col-xs-6 text-right m-b-15">
					<a target="_blank" href="https://apps.apple.com/us/app/isense/id1535795397">
					<img src="plugins/images/app-store2.png" title="" alt="" />
					</a>
				</div>
				<div className="col-sm-6 col-xs-6 text-left m-b-15">
				<a target="_blank" href="https://play.google.com/store/apps/details?id=com.EMONesense&hl=en&gl=US">
					<img src="plugins/images/google-play2.png" title="" alt="" />
					</a>
				</div>
			</div>
			<div className="col-xs-12 text-center m-b-10">
				<h5>BİZİ TAKİP EDİN</h5>
				<div className="social-media-area">
					<ul className="social-media">
						<li><a  target="_blank"  href="https://www.facebook.com/eds.elektronik/?ref=hl"><i className="fa fa-facebook"></i></a></li>
						<li><a target="_blank"  href="https://twitter.com/edselektronik"><i className="fa fa-twitter"></i></a></li>
						<li><a target="_blank"  href="https://www.instagram.com/Eds.elektronik/"><i className="fa fa-instagram"></i></a></li>
						<li><a target="_blank"  href="https://www.linkedin.com/company/5394404"><i className="fa fa-linkedin"></i></a></li>
						<li><a target="_blank"  href="https://www.youtube.com/channel/UCBowWYlKE4AzVUyRjRvbUag"><i className="fa fa-youtube"></i></a></li>
					</ul>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-md-1 col-sm-1 text-left hidden-xs"><img src="plugins/images/eds-logo2.png" title="" alt="" /></div>
			<div className="col-md-4 col-sm-11 text-left hidden-xs">Copyright © {new Date().getFullYear()} - EDS ELEKTRONİK</div>
			<div className="col-md-7 col-sm-12 col-xs-12">
				<div className="footer-menu pull-right">
					<ul>
						{/* <li><a href="/SikcaSorulanSorular"><Translate content="footer_paragraph5"  /></a></li> */}
						<li><a href="/Hakkimizda"><Translate content="footer_paragraph"  /></a></li>
						<li><Link to="/TeknikDestek"><Translate content="footer_paragraph1"  /></Link></li>
						{/* <li><Link to="/Iletisim"><Translate content="footer_paragraph2"  /></Link></li> */}
						<li><a href="/Iletisim"><Translate content="footer_paragraph2"  /></a></li>
						<li><Link to="/SiteHaritasi"><Translate content="footer_paragraph3"  /></Link></li>
						<li><Link to="/GizlilikPolitikasi"><Translate content="footer_paragraph4"  /></Link></li>
						{/* <li><a href="/Hakkimizda"><Translate content="footer_paragraph"  /></a></li>
						<li><a href="/TeknikDestek"><Translate content="footer_paragraph1"  /></a></li>
						// <li><a href="/Iletisim"><Translate content="footer_paragraph2"  /></a></li>
						<li><a href="/SiteHaritasi"><Translate content="footer_paragraph3"  /></a></li>
						<li><a href="/GizlilikPolitikasi"><Translate content="footer_paragraph4"  /></a></li> */}
						
					</ul>
				</div>
			</div>
		</div>
		<div className="row visible-xs" style={{marginBottom:2}}>
			<div className="col-xs-12 text-center m-t-15 m-b-20"><img src="plugins/images/eds-logo2.png" title="" alt="" /></div>
			<div className="footer2-copyright-mobile">
				<div className="col-sm-8 col-xs-8 text-left" style={{marginLeft:25}}>
					Copyright © {new Date().getFullYear()} - EDS ELEKTRONİK
				</div>
				<div className="col-sm-4 col-xs-4 text-right">
					<a href="language.html"><i className="ti-world"></i> TR / Turkish</a>
				</div>
			</div>
		</div>
	</footer>               
               </div>
             );
             }
       else{
            return (
            
            <div>
                <footer className="footer">
	<div className="row" style={{textAlign:"center",left:"0",right:"0",marginRight:"auto",marginLeft:"auto",position:"absolute"}}>
		<div  className="col-md-6 col-sm-6 col-xs-12 text-left">Copyright © {new Date().getFullYear()} - EDS ELEKTRONİK</div>
		<div className="col-md-6 col-sm-6 col-xs-12 text-right"><a href="/GizlilikPolitikasi"><Translate content="footer_paragraph4"  /></a></div>
	</div>
</footer>
            </div>
        );
    }
}
}

export default Footer;