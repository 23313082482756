import React, { Component } from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component'
import GoogleMapReact from 'google-map-react';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import { Auth } from "aws-amplify";



const InfoWindow = props => (
    props.show ? (<div style={{width: 100, height: 100}}>Info window</div>) : null
  )
const Marker = props => {
    return <>
      <div className="pin"></div>
      <div className="pulse"></div>
      <InfoWindow></InfoWindow>
    </>
    
  }

class Iletisim extends Component {
    constructor(props){
        super(props)
        this.handleSubscribe=this.handleSubscribe.bind(this)
        this.recaptchaLoaded=this.recaptchaLoaded.bind(this)
        this.verifyCallback=this.verifyCallback.bind(this)
        this.state={
            flag:false,
            topic:'',
            name:'',
            surname:'',
            firmName:'',
            mobileNo:'',
            email:'',
            message:'',
            option:'Ürün Ve Hizmetler',
            verified:''

        }
        }
    static defaultProps = {
        center: {
          lat: 41.009623,
          lng: 29.188471
        },
        zoom: 15
      };
    state={
        lang:'en',

    }
    componentDidMount(){


    
        this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
         
  
      }

   

    handleSubmit = async event => {
        event.preventDefault();
    
        

            if (this.state.verified==='onaylandı') {
                await  Auth.user.getSession((err, session) => {
                    const accessTok=Auth.user.signInUserSession.accessToken.jwtToken;
                    const sessionToken = session.getIdToken().jwtToken;
                axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod',
            {
              
                "msgtype": "sendMessage",
                "option": this.state.option,
                "name": this.state.name,
                "surname":this.state.surname,
                "firm":this.state.firm,
                "mobileNo":this.state.mobileNo,
                "email":this.state.email,
                "message":this.state.message
              
              
              
              }, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionToken
                      },
                    }).then(response => {
                  console.log('response', response); //api error
                  console.log('response', response.status);
                  if (response.status=='200') {
                    alert('Mesajı başarıyla gönderdiniz')
                  }
                }).catch(error => {
                  console.log('api error ', +error); 
               });
            })
            }
            
      

      }

    
    onLangChange=(e)=>{
        this.setState({lang:e.target.value})
        counterpart.setLocale(e.target.value)
    }
    handleChange=(e)=>{
        this.setState({option:e.target.value})
    }

    onInputChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
        
      }

    
    handleSubscribe(){
        if(this.state.flag){
            localStorage.getItem('lang')==='en'?
            alert('You have Subscribed'):
            alert('İnsan olduğunuz onaylandı')
        }else{
            localStorage.getItem('lang')==='en'?
            alert("Please verify that you are a human"):
            alert("Lütfen bir insan olduğunuzu onaylayınız.")
        }
    }
    recaptchaLoaded(){
        console.log('captcha succesfully loadedd');
    }
    verifyCallback(response){
        if (response) {
            this.setState({
                flag:true
            })
            this.setState({
                verified:'onaylandı'
            })
        }
    }

    render() {
        console.log('state : '+this.state.firmName)
        console.log('state isim: '+this.state.option)
        return (
            
	<div id="page-wrapper">
    <div className="container-fluid p-t-20">
        <div className="row">
            <div className="col-md-12">
            <Translate content="iletisim" component="h3" className="m-t-0"/>
            </div>
            <div style={{ "margin-bottom": "100px"}}  className="col-md-6 col-sm-12 col-xs-12">
                <div className="white-box height3">
                    <form onSubmit={this.handleSubmit} id="contactForm" data-toggle="validator">
                        <div className="form-horizontal">
                            <div className="form-group">
                                <label className="col-sm-3 control-label p-t-0" for="yardim"><Translate content="iletisim_paragraph" component="p" /></label>
                                <div className="col-sm-6">
                                    
                                {
                                    localStorage.getItem('lang')==='tr'?
                                    <select onChange={this.handleChange} className="selectpicker" data-style="form-control" required >
                                        <option value="Ürün Ve Hizmetler" >Ürün Ve Hizmetler</option>
                                        <option value="Teknik Destek" >Teknik Destek</option>
                                        <option value="Geri Bildirim Ve Şikayetler"  >Geri Bildirim Ve Şikayetler</option>
                                        <option  value="Diğer" >Diğer</option>
                                    </select>
                                    :
                                    <select onChange={this.handleChange} className="selectpicker" data-style="form-control" required >
                                        <option value="Ürün Ve Hizmetler">Products and Services</option>
                                        <option value="Teknik Destek" >Technical Support</option>
                                        <option value="Geri Bildirim Ve Şikayetler" >Feedback and Complains</option>
                                        <option value="Diğer">Other</option>
                                    </select>
                                }

                                        {/* <Translate onChange={this.handleChange} component="select" name="yardim" id="yardim" className="selectpicker" data-style="form-control" required  >
                                        <Translate  component="option" value="Ürün Ve Hizmetler" content="iletisim_paragraph1" attributes={{placeholder:'iletisim_paragraph12'}} ></Translate>
                                        <Translate  component="option" value="Teknik Destek" content="iletisim_paragraph13" attributes={{placeholder:'iletisim_paragraph12'}} ></Translate>
                                        <Translate  component="option" value="Geri Bildirim Ve Şikayetler" content="iletisim_paragraph12" attributes={{placeholder:'iletisim_paragraph12'}} ></Translate>
                                        <Translate  component="option" value="Diğer" content="iletisim_paragraph14" attributes={{placeholder:'iletisim_paragraph12'}} ></Translate>
                                        
                                    </Translate> */}


                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="adi"><Translate content="iletisim_paragraph2" component="p" /></label>
                                <div className="col-sm-6"><input type="text" value={this.state.name} onChange={this.onInputChange}  name="adi" id="name" className="form-control" required /></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="soyadi"><Translate content="iletisim_paragraph3" component="p" /></label>
                                <div className="col-sm-6"><input type="text" value={this.state.surname} onChange={this.onInputChange}  name="soyadi" id="surname" className="form-control" required /></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="firma_adi"><Translate content="iletisim_paragraph4" component="p" /></label>
                                <div className="col-sm-6"><input type="text" value={this.state.firmName} onChange={this.onInputChange}  name="firma_adi" id="firmName" className="form-control" required /></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="mobil"><Translate content="iletisim_paragraph5" component="p" /></label>
                                <div className="col-sm-6"><input type="text" value={this.state.mobileNo} onChange={this.onInputChange}  name="mobil" id="mobileNo" className="form-control" required /></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="email"><Translate content="iletisim_paragraph6" component="p" /></label>
                                <div className="col-sm-6"><input type="text" value={this.state.email} onChange={this.onInputChange}  name="email" id="email" className="form-control" required /></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="mesaj"><Translate content="iletisim_paragraph7" component="p" /></label>
                                <div className="col-sm-6"><textarea type="text" value={this.state.message} onChange={this.onInputChange}  name="mesaj" id="message" className="form-control" rows="3" required></textarea></div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label" for="security2"><Translate content="iletisim_paragraph8" component="p" /></label>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6"><Recaptcha
                                    sitekey="6LekpM8ZAAAAAI3tgVBxJQK7JmUkVf53c0rwhPRM"
                                    render="explicit"
                                    required
                                    verifyCallback={this.verifyCallback}
                                    onloadCallback={this.recaptchaLoaded}
                                /></div>
                                    
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-sm-3 control-label hidden-xs"></label>
                                <div className="col-sm-6">
                                    <button type="submit" onClick={this.handleSubscribe} className="btn btn-info btn-block waves-effect waves-light"><Translate content="iletisim_paragraph9"   /></button>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="white-box">
                    <div className="text-center">
                        <h3>EDS Elektronik Destek Sanayi Ltd.</h3>
                        <p><strong><Translate content="iletisim_paragraph11" component="p" /></strong> Meclis Mahallesi Teraziler Caddesi Hayran Sokak No:4</p>
                        <p>34785 Sancaktepe / İstanbul / Türkiye</p>
                        <p><strong><Translate content="iletisim_paragraph10" component="p" /></strong> +90 216 528 45 00</p>
                        <p><strong>E-Mail:</strong> <a href="mailto:eds@eds.com.tr">eds@eds.com.tr</a></p>
                    </div>

                    
                    <div id="map_canvas" style={{width:"100%",height:"370px"}}>


                    {/* <script>{function initMap() {
                            map = new google.maps.Map(document.getElementById("map"), {
                                center: {
                                lat: -34.397,
                                lng: 150.644,
                                },
                                zoom: 8,
                            });
                            }}</script> */}
                           
                           <GoogleMapReact
                            bootstrapURLKeys={{ key:"AIzaSyC0CUoE8WfzxRNgO1aPHTT7yrNkxYEmyok"}}
                            
                            defaultCenter={this.props.center}
                            defaultZoom={this.props.zoom}
                            >
                                <Marker lat='41.009623' lng='29.188471' onClick={() => <InfoWindow
                                    key={`infowindow-Eds Elektronik`}
                                    
                                    visible={true}>
                                    <div>Merhaba</div>
                                </InfoWindow>} >

                                </Marker>
                            </GoogleMapReact>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <script defer
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC0CUoE8WfzxRNgO1aPHTT7yrNkxYEmyok&callback=initMap">
</script>
</div>


        );
    }
}

export default Iletisim;
