import React, { Component } from 'react';
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'
import {Accordion,Card,Button} from 'react-bootstrap'
import axios from 'axios'

counterpart.registerTranslations('tr',tr);
counterpart.registerTranslations('en',en);
  counterpart.setLocale('tr')

  const Link=(props)=>{
      return(
      <Translate content={props.content} component="a" href="//google.com" target="_blank" />
      )
  }

class Hakkimizda extends Component {
    
    constructor(props){
        super(props)
        this.state={
           posts:[]
        }
    }
    componentDidMount(){
        axios.get('https://jsonplaceholder.typicode.com/posts').then(response=>{
            console.log(response);
            this.setState({posts:response.data})
        }).catch(error=>{
            console.log(error);
        })
    }
    onLangChange=(e)=>{
        this.setState({lang:e.target.value})
        counterpart.setLocale(e.target.value)
    }
    
    render() {
        // console.log(this.props);
        // console.log(this.state);
        // this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
        var isEn=localStorage.getItem('lang');
        
        const {posts}=this.state
        

        return (
            
            <div id="page-wrapper" className="m-t-20 m-b-20">
           
                {/* <select value={this.state.lang} onChange={this.onLangChange}  >
                    <option value="tr" >TR</option>
                    <option value="en" >EN</option>
                </select> */}
                {/* <Translate content="hakkimizda" component="h1" className="m-t-0" /> */}

                {/* <Translate content="copy.p1" component="p" unsafe={true} /> */}

                {/* <Translate content="copy.p2" component="p" with={{link}} /> */}

                <Translate component="div" type="text" attributes={{}}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h3>Sıkça Sorulan Sorular</h3>
                        
                    </div>
                    
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="white-box">
                            

                            {<div>
                                {
                                    isEn=='en'?
                                    
                                    <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <h4>The device is not sending data</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                <li> Check the network connection. Do not use any cable other than the one supplied with the device for network connection. After plugging in the device, make sure that the IP address of the device appears on the screen and the network light next to the device is on</li>
                                                <li> Allow two-way communication with 'esense.com.tr' over port 8093 in the firewall in the network where the device is installed.</li>
                                            </ul>
                                    </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                        <h4>No alarm notifications</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>Find the relevant device from the list by clicking the 'Search' button in the 'Device Operations' tab. Click on the 'Edit' button, enter the contact information you want the notifications to go to in the 'Alert Recipients' section and add the alert recipient. Don't forget to choose the type of notification you want to receive.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2">
                                        <h4>Alarm notifications arrive too late</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>Find the relevant device from the list by clicking the 'Search' button in the 'Device Operations' tab. Press the 'Edit' button and set the 'Alarm Delay' duration as you wish.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <h4>The device gives a constant alarm</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>Select the company and device from the 'Sensor Operations' tab, adjust the lower limit and upper limit of your sensors according to the environment you use.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="4">
                                        <h4>Sensor value displays 0</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="4">
                                        <Card.Body>For wired sensors; Check the sensor connection, make sure the socket is properly inserted. For wireless sensors; Make sure there is no interference (wall, metal, etc.) between the sensor and the panel. Do not mount the panel and sensors on a metal surface.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                    
                                    :   
                                    <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <h4>Cihaz veri göndermiyor</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <ul>
                                                <li> Network bağlantısını kontrol edin. Network bağlantısı için cihazla gönderilen kablo dışında başka bir kablo kullanmayın. Cihazı fişe taktıktan sonra ekranda cihazın aldığı IP adresinin gözüktüğüne ve cihazın yanındaki network ışığının yandığına emin olun.</li>
                                                <li> Cihazın kurulu olduğu ağdaki firewallda ‘esense.com.tr’ ile 8093 portu üzerinden çift yönlü iletişime izin verin.</li>
                                            </ul>
                                    </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                        <h4>Alarm bildirimleri gelmiyor</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                        <Card.Body>‘Cihaz İşlemleri’ sekmesinde ‘Ara’ butonuna basarak ilgili cihazı listeden bulun. ‘Düzenle’ butonuna basıp ‘Uyarı Alıcıları’ kısmına bildirimleri gitmesini istediğiniz kişi bilgilerini girip uyarı alıcısını ekleyin. Almak istediğiniz bildirim tipini seçmeyi unutmayın.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="2">
                                        <h4>Alarm bildirimleri çok geç geliyor</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                        <Card.Body>‘Cihaz İşlemleri’ sekmesinde ‘Ara’ butonuna basarak ilgili cihazı listeden bulun. ‘Düzenle’ butonuna basıp ‘Alarm Gecikmesi’ süresini isteğinize göre ayarlayın.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="3">
                                        <h4>Cihaz sürekli alarm veriyor</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3">
                                        <Card.Body>‘Sensör İşlemleri’ sekmesinden firma ve cihaz seçin, kullandığınız ortama göre sensörlerinizin alt limit ve üst limit ayarlamalarını yapın.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="4">
                                        <h4>Sensör değeri 0 gözüküyor</h4>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="4">
                                        <Card.Body>Kablolu sensörler için; sensör bağlantısını kontrol edin, soketin düzgün takıldığından emin olun. Kablosuz sensörler için; sensör ile panel arasında sinyal kesici bir durum (duvar, metal vb.) olmamasına dikkat edin. Paneli ve sensörleri metal yüzey üzerine monte etmeyin.</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    </Accordion>
                                }
                            
                           
                                </div>}
                       
                                
                                {/* <div className="title">
                                     {
                                         posts.map((title,index)=>{
                                          return  <Accordion key={index} defaultActiveKey="0">
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                                <h3>Sikca Sorulan Sorular {title.id}</h3>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1">
                                                <Card.Body>{title.body}</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            </Accordion>
                                         })
                                       
                                    }
                                    </div> */}
        
                                
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Hakkimizda;

