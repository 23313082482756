import React, { Component } from 'react';
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'

counterpart.registerTranslations('tr',tr);
counterpart.registerTranslations('en',en);
  counterpart.setLocale('tr')

  const Link=(props)=>{
      return(
      <Translate content={props.content} component="a" href="//google.com" target="_blank" />
      )
  }

class Hakkimizda extends Component {
    state={
        lang:'tr'
    }
    onLangChange=(e)=>{
        this.setState({lang:e.target.value})
        counterpart.setLocale(e.target.value)
    }

    render() {
        // console.log(this.props);
        // console.log(this.state);
        // this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');

        const link=<Link content="link" />

        const placeholder=counterpart.translate('place')

        return (
            
            <div id="page-wrapper" className="m-t-20 m-b-20">

                {/* <select value={this.state.lang} onChange={this.onLangChange}  >
                    <option value="tr" >TR</option>
                    <option value="en" >EN</option>
                </select> */}
                {/* <Translate content="hakkimizda" component="h1" className="m-t-0" /> */}

                {/* <Translate content="copy.p1" component="p" unsafe={true} /> */}

                {/* <Translate content="copy.p2" component="p" with={{link}} /> */}

                <Translate component="div" type="text" attributes={{}}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Translate content="hakkimizda" component="h3" className="m-t-0"/>
                    </div>
                    
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="white-box">
                            <p content="hakkimizda">

                            <Translate content="hakkimizda_paragraph" component="p" />
                                <br /><br />
        
                                <div className="about-us">
                                    <div id="owl-demo" className="owl-carousel owl-theme">
                                        <div className="item active">
                                    <img src="./plugins/images/hakkimizda_1.jpg" alt=""/></div>
                                        <div className="item"><img src="plugins/images/hakkimizda_2.jpg" alt=""/></div>
                                        <div className="item"><img src="plugins/images/hakkimizda_3.jpg" alt=""/></div>
                                        <div className="item"><img src="plugins/images/hakkimizda_4.jpg" alt=""/></div>
                                        <div className="item"><img src="plugins/images/hakkimizda_5.jpg" alt=""/></div>
                                    </div>
                                </div>
        
                                <Translate content="hakkimizda_paragraph2" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph3" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph4" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph5" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph6" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph7" component="p"  /><br /><br />
        
                                <Translate content="hakkimizda_paragraph8" component="p"  />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Hakkimizda;