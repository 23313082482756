import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';

class ForgotPasswordVerification extends Component {
  state = {
    verificationcode: "",
    email: "",
    AlertShow: true,
    AlertError:"",
    AlertSuccess:"",
    newpassword: "",
    SaveButtonHide: "hidden",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  ShowMessage() {

    return (
        <div class={"alert " + this.state.AlertType} role="alert">
            {<div>
                {this.setState.AlertEmail?
                "Email'iniz aktif değil"
                :
                
                <b style={{ color: "white" }}> {<div>
                  {this.state.AlertError==='Username cannot be empty'?this.state.AlertError='Kullanıcı adı boş bırakılamaz'
                  :
                <div>{this.state.AlertError==='Password cannot be empty'?this.state.AlertError='Şifre Boş bırakılamaz'
                :
                <div>{this.state.AlertError==='1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6'?this.state.AlertError='Şifre 6 karaktere eşit yada uzun olmalı'
                :
                <div>{this.state.AlertError==='Invalid verification code provided, please try again.'?this.state.AlertError='Kod yanlış girildi daha sonra tekrar deneyiniz'
                :
                <div>{this.state.AlertError==='Attempt limit exceeded, please try after some time.'?this.state.AlertError='Deneme sınırını aştınız , bir süre sonra tekrar deneyiniz'
                :
                <div>{this.state.AlertError==='Password does not conform to policy: Password must have lowercase characters'?this.state.AlertError='Şifre küçük harf içermelidir'
                :
                <div>{this.state.AlertError==='Password must have uppercase characters'?this.state.AlertError='Şifre büyük harf içermelidir'
                :
                <div>{this.state.AlertError==='Password does not conform to policy: Password must have lowercase characters'?this.state.AlertError='Şifre küçük harf içermelidir'
                :
                <div>{this.state.AlertError==='Password does not conform to policy: Password must have uppercase characters'?this.state.AlertError='Şifre büyük harf içermelidir'
                :
                <div>{this.state.AlertError==='Password not long enough'?this.state.AlertError='Şifre yeterince uzun değil'
                :
                <div>{this.state.AlertError==='Password does not conform to policy: Password not long enough'?this.state.AlertError='Şifre yeterince uzun değil'
                :
                <div>{this.state.AlertError==='Password must have symbol characters'?this.state.AlertError='Şifre sembol karakter içermelidir.'
                :
                <div>{this.state.AlertError==='Password does not conform to policy: Password must have symbol characters'?this.state.AlertError='Şifre sembol karakter içermelidir.'
                :
                <div>{this.state.AlertError==='Invalid code provided, please request a code again.'?this.state.AlertError='Kod yanlış girildi yeni bir kod isteyiniz'
                :
                <div>{this.state.AlertError==="1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6"?this.state.AlertError='Şifre uzunluğu 6 karakterden daha fazla olmalı'
                :
                <div>{this.state.AlertError==="Member must have length greater than or equal to 6"?this.state.AlertError='Şifre uzunluğu 6 karakterden daha fazla olmalı'
                :
                <div>{this.state.AlertError==="Password does not conform to policy: Password must have numeric characters"?this.state.AlertError='Şifre sayı  içermelidir.'
                :
                <div>{this.state.AlertError==='Confirmation code cannot be empty'?this.state.AlertError='Onay kodu boş bırakılamaz'
                :<div>{this.state.AlertError==='Unrecognizable lambda output'?this.state.AlertError='Şifreniz başarıyla değiştirildi'
                :this.state.AlertError
                }</div>
              }</div>
              }</div>
                }</div>
              }</div>
                }</div>
              }</div>
              }</div>
                }</div>
              }</div>
                }</div>
                }</div>
                }</div>
                }</div>
                }</div>
                }</div>
                }</div>
                }</div>
                  }
                  </div>}</b>}
                </div>}
            {/* <b style={{ color: "white" }}> {this.state.AlertMessage}</b> */}

        </div>
    );
}

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  passwordVerificationHandler = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({ SaveButtonHide: "visible", AlertShow: true, AlertMessage: "Kullanici Bilgileriniz Hatalıdır", AlertType: "alert-danger", shh: "65px" });
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.verificationcode,
        this.state.newpassword
      );
      alert("Şifreniz Değiştirildi");
      this.props.history.push("/login");
    }catch(error) {
      console.log(error.message);
      // alert("Şifreniz Değiştirildi");
      if (error.message==='Unrecognizable lambda output') {
        this.setState({SaveButtonHide: "visible",AlertShow:true})
        window.location.href = "https://www.isense.com.tr/Login";
        return false;
      }
      
      // this.setState({ SaveButtonHide: "visible", AlertShow: true, AlertMessage: "Kullanici Bilgileriniz Hatalıdır", AlertType: "alert-danger", shh: "65px" });
      this.setState({AlertError:error.message,AlertShow:true,SaveButtonHide: "visible"})
      this.setState({
        errors:error
      });
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  render() {
    return (
      <div style={{paddingTop:"80px"}}>
      
   
   
      <FormErrors formerrors={this.state.errors} />
   <section class="new-login-register">
<div class="new-login-box">
 <div class="new-login-box-header"></div>
 <div class="white-box">

  <form id="loginform" class="form-horizontal new-lg-form" onSubmit={this.passwordVerificationHandler}>
            <div className="form-group">
              
                <input
                  type="text"
                  className="form-control"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                />
              
            </div>
            <div className="form-group">
             
                <input 
                  className="form-control" 
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
               
            </div>
            <div className="form-group">
             
                <input
                  type="password"
                  className="form-control"
                  id="newpassword"
                  placeholder="New password"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                />
               
            </div>
            <div className="form-group">
              
                <button className="btn btn-info btn-lg btn-block waves-effect waves-light">
                  Onayla
                </button>
              
            </div>
            <div style={{ "visibility": this.state.SaveButtonHide, "height": this.state.shh }}>

              {this.state.AlertShow ? this.ShowMessage() : null}

              </div>
          </form>
     
 </div>
</div>
</section>
</div>
   
   );
  }
}

export default ForgotPasswordVerification;