import React, { Component } from 'react';
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'


class TeknikDestek extends Component {
	
	state={
        lang:'tr'
	}
	addFromLS=(e)=>{
		sessionStorage.setItem('lang',e.target.value)
		console.log(213);
	}
    onLangChange=(e)=>{
		
		this.setState({lang:e.target.value})
        counterpart.setLocale(e.target.value)
	}
	
    render() {
        return (
           
                <div id="page-wrapper" className="m-t-20 m-b-20">
	<div className="container-fluid p-t-20">
		<div className="row help">
			<div className="col-md-12 text-center">
				
				
				<Translate content="teknikdestek_paragraph" component="h3" className="m-t-30 m-b-10" />
				<Translate content="teknikdestek_paragraph1" component="p"  />
				<div className="search m-t-30">
					<form action="" method="GET" role="search">
						<input type="text" name="search" className="form-control" /> <button type="submit"><i className="fa fa-search"></i></button>
					</form>
				</div>
			</div>
			<div className="col-md-12 col-md-offset-2 col-sm-12 col-xs-12 m-t-40">
				<div className="row">
					<div className="col-md-4 col-sm-6 col-xs-12 text-center m-b-40">
						<a href="https://www.eds.com.tr/">
							<div className="img"><img src="plugins/images/pdf.png" title="" alt="" /></div>
							<Translate content="teknikdestek_paragraph2" component="h4"  />
							<Translate content="teknikdestek_paragraph3" component="p" className="text-dark"  />
							<Translate content="teknikdestek_paragraph4" component="p" className="text-dark"  />
						</a>
					</div>
					<div className="col-md-4 col-sm-6 col-xs-12 text-center m-b-40">
						<a href="SikcaSorulanSorular">
							<div className="img"><img src="plugins/images/sss.png" title="" alt="" /></div>
							<Translate content="teknikdestek_paragraph5" component="h4"  />
							<Translate content="teknikdestek_paragraph6" component="p" className="text-dark"  />
							<Translate content="teknikdestek_paragraph7" component="p" className="text-dark"  />
						</a>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-sm-6 col-xs-12 text-center m-b-40">
						<a target="_blank" href="https://www.youtube.com/channel/UCBowWYlKE4AzVUyRjRvbUag" rel="noopener noreferrer">
							<div className="img"><img src="plugins/images/youtube.png" title="" alt="" /></div>
							<h4>Youtube</h4>
							<Translate content="teknikdestek_paragraph8" component="p" className="text-dark"  />
							<Translate content="teknikdestek_paragraph9" component="p" className="text-dark"  />
						</a>
					</div>
					<div className="col-md-4 col-sm-6 col-xs-12 text-center m-b-40">
						<a href="Iletisim1">
							<div className="img"><img src="plugins/images/iletisim.png" title="" alt="" /></div>
							<Translate content="teknikdestek_paragraph10" component="h4" className="text-dark"  />
							<Translate content="teknikdestek_paragraph11" component="p" className="text-dark"  />
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
           

        );
    }
}

export default TeknikDestek;