import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import Translate from 'react-translate-component'
import GoogleMapReact from 'google-map-react'
import Axios from 'axios'

const InfoWindow = props => (
  props.show ? (<div style={{width: 100, height: 100}}>Info window</div>) : null
)
const Marker = props => {
  return <>
    <div className="pin"></div>
    <div className="pulse"></div>
    <InfoWindow></InfoWindow>
  </>
  
}

class Harita extends Component {
  
  state = {
    reload: false,
    name: "",
    family_name: "",
    address: "",
    phone_number: "",
    postakodu: "",
    country: "",
    picture: "",
    il: "", 
    ilce: "",
    ulke: "",
    posts:[],
    adresses:[],
          adressess:[],
          adressLength:[],
          latt:[],
          lngg:[],
          arr1:[],
          arr3:[],
          arr4:[],
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    },
    latt:[],
    lngg:[]
    // defaultProps : {
    //   center: {
    //     lat: [41.009623],
    //     lng: [29.188471]
    //   },
    //   zoom: 15
    // }
  }
  
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmitEdit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    

    // AWS Cognito integration here
    const { name, family_name, address, phone_number, locale, picture } = this.state;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const signUpResponse = await Auth.updateUserAttributes(user, {
        name: name,
        family_name: family_name,
        address: address,
        phone_number: phone_number,
        locale: locale,
        picture: picture

      });
      this.props.history.push("/hesap");
      console.log(signUpResponse);
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }
 


  getuserInfos = async event => {

    try {
      const currentUserInfo = await Auth.currentUserInfo();
      if (!this.state.reload) {
        this.setState({
          reload: true,
          name: currentUserInfo.attributes['name'],
          family_name: currentUserInfo.attributes['family_name'],
          email: currentUserInfo.attributes['email'],
          address: currentUserInfo.attributes['address'],
          phone_number: currentUserInfo.attributes['phone_number'],
          postakodu: currentUserInfo.attributes['custom:postakodu'],
          picture: currentUserInfo.attributes['picture'],
          il: currentUserInfo.attributes['custom:il'],
          ilce: currentUserInfo.attributes['custom:ilce'],
          country: currentUserInfo.attributes['custom:ulke'],
        });
      }

    } catch (err) {
      console.log('error fetching user info: ', err);
    }


  };
  
  componentDidMount() {
    this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    this.getuserInfos();

    
    // axios.post('https://maps.googleapis.com/maps/api/geocode/json?address='+this.state.address+'&key=AIzaSyCMf-KvwxEtBJaE2FLPuKN6GVqWFLV6rWw').then(response=>{
    //     console.log('here is gmap response'+response);
    //     this.setState({posts:response.data})
    // }).catch(error=>{
    //     console.log(error);
    // })
    Auth.user.getSession((err, session) => {
        if (err) {
            throw new Error(err);
        }

        const sessionToken = session.getIdToken().jwtToken;
        const postMessage = {
            "msgtype": "getdevs",
            "devId": this.props.deviceid
        };
        Axios.post("https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod", postMessage, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionToken
            },
        }).then((res) => {
            // console.log('gelen data'+JSON.stringify(res.data));
            var arr4=[]
            // console.log('gelen data'+JSON.stringify(res.data.map(x=>x.devConfigs)));
            this.state.arr1=res.data.map(x=>x.devConfigs)
            
            console.log('45',this.state.arr1);
            console.log('gelen data 1'+JSON.stringify(this.state.arr1.map(x=>x.address)));

            this.state.arr3=this.state.arr1.filter(x=>x.address)
            console.log('arr3:'+JSON.stringify(this.state.arr3));
            arr4=this.state.arr3.filter(x=>x.address)
            // console.log('suzulmus :'+JSON.stringify(arr4));
            console.log('arr5'+arr4);

            var newValuess= this.state.arr3.reduce((acc, cur) => {
              return acc.concat([cur.address]);
                // or acc.push([cur*cur , cur*cur*cur, cur+1]); return acc;
            }, []);
            console.log(newValuess);
         

            console.log(res.data[0].devConfigs.address);
            console.log(res.data[1].devConfigs.address);
            
            var devArr =[]
            this.setState({adresses:res.data})
            // localStorage.setItem('adress',JSON.stringify(res.data))
            
            for(let i=0;i<=res.data.length;i++){
                
                console.log('adres 1 : '+res.data[i].devConfigs.address);
                this.state.adressess.push(res.data[i].devConfigs.address)
                
            }
            console.log('device Array'+this.state.adressess.map(z=>z));
           
            

            // this.setState({adressess:newValuess})
            // this.setState({adressLength:newValuess.length})
            return Promise.all(this.state.adressess)
        }).then()
            .catch(error => {
                console.log("Error : " + error);
            });
    });

    

  }
  componentDidUpdate(){
    
    Auth.user.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }

      const sessionToken = session.getIdToken().jwtToken;
      Axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='+this.state.address+'&key=AIzaSyBiHW3QWp6UXFPq80zXfu-n1B3n9DluiEg', {
        method: 'GET',
      })
        .then(res => {
          console.log(res.data);
          console.log(res.data.results[0].geometry.location.lat);
          console.log(res.data.results[0].geometry.location.lng);
          // console.log(res.data[0]);

          this.setState({latt:res.data.results[0].geometry.location.lat})
          this.setState({lngg:res.data.results[0].geometry.location.lng})

          
        })
        .catch(error => {
          console.log("Hatavar : " + error);
        });
    });
    
  }
  // static defaultProps = {
   
  //   center: {
  //     lat: 39.298156,
  //     lng: 35
  //   },
  //   zoom: 5.7
  // };
  static defaultProps = {
   
    center: {
      lat: 39.298156,
      lng: 35
    },
    zoom: 5.7
  };
  

 
 
  // AIzaSyDr7jBmEKsRDyF0n4MkCKPP_PRSuzuZ1Po api key
  render() {

    return (
      

      <div>
       
        <div id="page-wrapper">
          
            <div class="row">

              <div class="col-md-6 col-sm-12 col-xs-12">
              
                  
                
                
                  <div id="map_canvas" style={{width:"1550px",height:"670px"}}>

       
       <GoogleMapReact
        bootstrapURLKeys={{ key:"AIzaSyBiHW3QWp6UXFPq80zXfu-n1B3n9DluiEg"}}
        
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        
        >
          {console.log('is here '+ this.state.latt[0])}
          
          
            <Marker lat={this.state.latt} lng={this.state.lngg}  onClick={() => <InfoWindow
                key={`infowindow-Eds Elektronik`}
                
                visible={true}>
                <div>Merhaba</div>
            </InfoWindow>} >

            </Marker>
        </GoogleMapReact>

        <div class="content-loading">
							<div class="content-loading-content">
								<img src="plugins/images/map.png" title="" alt="" />
								<p>Harita için adres mevcut değil.</p>
								<p>Lütfen ayarlar sayfasından cihazı seçip adres giriniz.</p>
                                      {/* {this.state.adressess} */}
							</div>
						</div>
            <div id="page-wrapper" >
            <div class="container-fluid p-t-20">
              
							
              <h3>Liste</h3>
              <div>
                    {
                        (this.state.adresses).map((title,index)=>{
                          
                          return  <div class="col-md-5 col-sm-8 " key={index} style={{ marginBottom:"40px"}}>
                            <div id="page-wrapper" >
                              <div >
                            <div class="container-fluid p-t-20">
                            
                            <div class="white-box p-10" style={{ marginBottom:"40px"}}>
                            <h5><div class="left-border"> EDS Elektronik</div></h5>
                            <h5><div class="left-border left-border2"> {title.devConfigs.address}</div></h5>
                            <h5><div class="left-border left-border2"> Sancaktepe / İstanbul 34100</div></h5>
                            <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="column">CİHAZ SAYISI<br />10</div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="column">SENSÖR SAYISI<br />10</div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="column">ALARM SAYISI<br />5</div>
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                              <div class="column">HATA SAYISI<br />1</div>
                            </div>
                          </div>
                            </div>
                            </div>
                            </div>
                            </div>
                           </div>
                        })
                    }
                   

							
						
						</div>
            </div>
					</div>
          </div>
                    </div>
                </div>
                
            </div>
        </div>
        );
    }
}

export default Harita;