import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


class PdfExcelReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: [],
        };

    }



    bucihazaraportable() {
        let raplist = [];
        if (this.props.raporlist) {
            for (var i = 0; i < this.props.raporlist.length; i++) {
                raplist.push(this.props.raporlist[i]);

            }
        }


        return raplist;
    }


    tableraw(list, fsensorindex) {

        let raplist1 = list.map((raprow, index) => {
            return (
                <tr key={index}>
                    <td>{raprow.payload.z.replace("T", "/").slice(0, -7)}</td>
                    <td>{raprow.payload.sensorveri[fsensorindex].a}</td>
                    <td>{raprow.payload.sensorveri[fsensorindex].d}</td>
                    <td>{raprow.payload.sensorveri[fsensorindex].u}</td>
                    <td>
                        {(raprow.payload.sensorveri[fsensorindex].drm == "0") && "NORMAL"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "1") && "ALARM"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "2") && "ALARM"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "4") && "HATA"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "5") && "ALARM HATA"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "6") && "ALARM HATA"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "8") && "HATA"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "9") && "ALARM HATA"}
                        {(raprow.payload.sensorveri[fsensorindex].drm == "10") && "ALARM HATA"}
                    </td>
                </tr>



            )
        });
        let raplist2 = [];
        let durum = "NORMAL";
        for (var i = 0; i < list.length; i++) {

            switch (list[i].payload.sensorveri[fsensorindex].drm) {
                case "0":
                    durum = "NORMAL";
                    break;
                case "1":
                    durum = "ALARM";
                    break;
                case "2":
                    durum = "ALARM";
                    break;
                case "4":
                    durum = "HATA";
                    break;
                case "5":
                    durum = "ALARM HATA";
                    break;
                case "6":
                    durum = "ALARM HATA";
                    break;
                case "8":
                    durum = "HATA";
                    break;
                case "9":
                    durum = "ALARM HATA";
                    break;
                case "10":
                    durum = "ALARM HATA";
                    break;
            }
            raplist2.push({
                date: list[i].payload.z.replace("T", "/").slice(0, -7),
                altlimit: list[i].payload.sensorveri[fsensorindex].a,
                deger: list[i].payload.sensorveri[fsensorindex].d.includes(".")?list[i].payload.sensorveri[fsensorindex].d.substr(0,2)+','+list[i].payload.sensorveri[fsensorindex].d.substr(3,5):list[i].payload.sensorveri[fsensorindex].d,
                ustlimit: list[i].payload.sensorveri[fsensorindex].u,
                drm: durum
            });
        }


        return raplist2.reverse();
    }

    render() {


        let options;
        if (this.props.kulsearch.length) {
            const searchPattern = new RegExp(this.props.kulsearch.map(term => `(?=.*${term})`).join(''), 'i');
            options = this.bucihazaraportable().filter(option =>
                option.searchfield.match(searchPattern)
            );
        } else {
            options = this.bucihazaraportable();
        }
        const columns = [{
            dataField: 'date',
            text: 'TARİH/SAAT'
        }, {
            dataField: 'altlimit',
            text: 'ALT LİMİT'
        }, {
            dataField: 'deger',
            text: 'DEĞER'
        }, {
            dataField: 'ustlimit',
            text: 'ÜST LİMİT'
        }, {
            dataField: 'drm',
            text: 'DURUM'
        }];
        return (
            <div>
                <BootstrapTable keyField='id' data={this.tableraw(options, this.props.filtersensor)} columns={columns} />

            </div>
        );
    }
}

export default PdfExcelReport;