import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import Axios from "axios";
import IonRangeSlider from 'react-ion-slider';
import Switch from '../utility/Switch';
import Sureler from './sureler';
import Translate from 'react-translate-component'

class ozelliklervesureler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            buzzercheckbox: false,
            SaveButtonHide: "hidden",
            ozelliklerikaydetalertType: "",
            ozelliklerikaydetalertMessage: "",
            ozelliklerikaydetalertShow: "",
            kaydetbuttondisplay: "none"
        };
        this.handleChangeGS = this.handleChangeGS.bind(this);
        this.handleChangeAS = this.handleChangeAS.bind(this);
        this.handleChangeCS = this.handleChangeCS.bind(this);
        this.handlebuzchange = this.handlebuzchange.bind(this);
        this.handletetikChange = this.handletetikChange.bind(this);

    }

    handleChangeGS = (event) => {
        console.log(event.from);
        this.setState({
            gonderimsuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handleChangeAS = (event) => {
        console.log(event.from);
        this.setState({
            alarmsuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handleChangeCS = (event) => {
        this.setState({
            onlinofflinesuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handlebuzchange() {
        this.setState({ buzzercheckbox: !this.state.buzzercheckbox });
    };

    handletetikChange() {
        this.setState({ cikistetik: !this.state.cikistetik });
    }

    getOneDevData() {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;
            const postMessage = {
                "msgtype": "getdevs",
                "devId": this.props.deviceid
            };
            Axios.post("https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod", postMessage, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                console.log('ozellikler firma :'+JSON.stringify(res.data.Items[0].devConfigs.firm));
                this.setState({
                    devinfo: res.data.Items[0],
                    adres: res.data.Items[0].devConfigs.address,
                    firma: res.data.Items[0].devConfigs.firm,
                    posta_kodu: res.data.Items[0].devConfigs.pcode,
                    sehir: res.data.Items[0].devConfigs.city,
                    cadi: res.data.Items[0].devConfigs.devN,
                    battery:parseFloat(res.data.Items[0].payload.bat/1000).toFixed(1)+'V'
                });

            })
                .catch(error => {
                    console.log("Error : " + error);
                });
        });
    }

    activateduzenle() {
        document.getElementById("cadi_hide").style = "display: none";
        document.getElementById("cadi_edit").style = "display: block";
        document.getElementById("firma_hide").style = "display: none";
        document.getElementById("firma_edit").style = "display: block";
        document.getElementById("adres_hide").style = "display: none";
        document.getElementById("adres_edit").style = "display: block";
        document.getElementById("sehir_hide").style = "display: none";
        document.getElementById("sehir_edit").style = "display: block";
        document.getElementById("posta_kodu_hide").style = "display: none";
        document.getElementById("posta_kodu_edit").style = "display: block";
        document.getElementById("duzenle_button").style = "display: none";
    }

    deactivateduzenle() {
        document.getElementById("cadi_hide").style = "display: block";
        document.getElementById("cadi_edit").style = "display: none";
        document.getElementById("firma_hide").style = "display: block";
        document.getElementById("firma_edit").style = "display: none";
        document.getElementById("adres_hide").style = "display: block";
        document.getElementById("adres_edit").style = "display: none";
        document.getElementById("sehir_hide").style = "display: block";
        document.getElementById("sehir_edit").style = "display: none";
        document.getElementById("posta_kodu_hide").style = "display: block";
        document.getElementById("posta_kodu_edit").style = "display: none";
        document.getElementById("duzenle_button").style = "display: block";
        this.setState({
            kaydetbuttondisplay: "none"
        });

    }

    onInputChange = event => {
        this.setState({ kaydetbuttondisplay: "block" });
        this.setState({
            [event.target.id]: event.target.value
        });
        document.getElementById(event.target.id).classList.remove("is-danger");
        if (event.target.files != null) {
            this.setState({ selectedFile: event.target.files[0] });
        }
    }

    ozelliklerikaydet() {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }
            const sessionToken = session.getIdToken().jwtToken;

            var changedozellikler = {
                "adres": this.state.adres,
                "firma": this.state.firma,
                "posta_kodu": this.state.posta_kodu,
                "sehir": this.state.sehir,
                "cadi": this.state.cadi
            };
            var msg = {
                "msgtype": "devconfigupdate",
                "devid": this.state.devinfo.deviceId,
                "configSettings": changedozellikler
            };

            Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                this.setState({ ozelliklerikaydetalertType: "alert-success", ozelliklerikaydetalertMessage: "İşleminiz gerçekleşti!", ozelliklerikaydetalertShow: true });

                setTimeout(() => {
                    this.setState({ ozelliklerikaydetalertShow: false });
                }, 4000
                );
                this.getOneDevData();
                this.deactivateduzenle();

            }).catch((error) => {
                this.setState({ ozelliklerikaydetalertType: "alert-warning", ozelliklerikaydetalertMessage: "bildirim ayarı günceleme post ta hata: " + error, ozelliklerikaydetalertShow: true });
            });
        });
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.ozelliklerikaydetalertType} role="alert">
                <strong>{this.state.ozelliklerikaydetalertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    componentDidMount() {
        this.getOneDevData(this.props.deviceid);
    }
    render() {
        // console.log('props : '+)
        return (
            <div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="white-box height1" >
                        <h3 class="m-t-0 left-border m-b-30"><Translate content="properties_paragraph" /></h3>
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="properties_paragraph1" /></label>
                                <div class="col-sm-9">{this.state.devinfo !== undefined && this.state.devinfo.deviceId}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="properties_paragraph2" /></label>
                                <div class="col-sm-9">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.T}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="properties_paragraph3" /></label>
                                <div class="col-sm-9">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.softV}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="event_paragraph10" /></label>
                                <div class="col-sm-9">{this.state.battery !== undefined && this.state.battery}</div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0" for="firma"><Translate content="properties_paragraph4" /></label>
                                <div class="col-sm-9">
                                    <div id="firma_hide">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.firm}</div>
                                    <div id="firma_edit">
                                        <input type="text" name="firma" id="firma" value={this.state.devinfo !== undefined && this.state.firma} class="form-control" onChange={this.onInputChange}  />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="properties_paragraph5" /></label>
                                <div class="col-sm-9">
                                    <div id="cadi_hide">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.devN}</div>
                                    <div id="cadi_edit">
                                        <input type="text" name="cadi" id="cadi" value={this.state.cadi !== undefined && this.state.cadi} class="form-control" onChange={this.onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0" for="adres"><Translate content="properties_paragraph6" /></label>
                                <div class="col-sm-9">
                                    <div id="adres_hide">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.address}</div>
                                    <div id="adres_edit">
                                        <input type="text" name="adres" id="adres" value={this.state.adres !== undefined && this.state.adres} class="form-control" onChange={this.onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0" for="sehir"><Translate content="properties_paragraph7" /></label>
                                <div class="col-sm-9">
                                    <div id="sehir_hide">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.city}</div>
                                    <div id="sehir_edit">
                                        <input type="text" name="sehir" id="sehir" value={this.state.sehir !== undefined && this.state.sehir} class="form-control" onChange={this.onInputChange} />
                                    </div>
                                </div>
                            </div> 
                            <div class="form-group">
                                <label class="col-sm-3 control-label p-t-0" for="posta_kodu"><Translate content="properties_paragraph8" /></label>
                                <div class="col-sm-9">
                                    <div id="posta_kodu_hide">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.pcode}</div>
                                    <div id="posta_kodu_edit">
                                        <input type="text" name="posta_kodu" id="posta_kodu" value={this.state.posta_kodu !== undefined && this.state.posta_kodu} class="form-control" onChange={this.onInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group m-b-10">
                                <label class="col-sm-3 control-label p-t-0"><Translate content="properties_paragraph9" /></label>
                                <div class="col-sm-9">{this.state.devinfo !== undefined && this.state.devinfo.devConfigs.sensNo}</div>
                            </div>
                            <div class="form-group firma_button">
                                <label class="col-sm-3 control-label"></label>
                                <div class="col-sm-9 m-t-10">
                                    <button type="button" class="btn btn-info" style={{'width':'200px'}} id="duzenle_button" onClick={() => { this.activateduzenle() }}><span><Translate content="properties_paragraph10" /></span></button>
                                    <button type="button" class="btn btn-info"  id="kaydet_button" style={{ "display": this.state.kaydetbuttondisplay }} onClick={() => { this.ozelliklerikaydet() }}><span><Translate content="properties_paragraph11" /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.props.deviceid !== undefined) &&
                    (this.state.devinfo !== undefined) &&
                    <Sureler devinfo={this.state.devinfo} />
                }
                {
                    this.props.deviceid === undefined &&
                    <div><Translate content="properties_paragraph12" /></div>
                }
            </div>
        );
    }
}

export default ozelliklervesureler;