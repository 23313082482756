
export default {
    hakkimizda:'HAKKIMIZDA',
    hakkimizda_paragraph:'EDS ELEKTRONİK, Şubat 1990 yılında elektronik konusunda firmalara üretim ve mühendislik hizmeti vermek üzere kurulmuştur. Daha sonra faaliyet alanlarına CCTV Sistemleri, Güvenlik Sistemleri, Yangın Algılama Sistemleri, Access Kontrol Sistemleri, Çevre Güvenlik Sistemleri, Otomasyon Sistemleri, Ortam Denetleme Sistemleri ve Güç Elektroniği ürünlerini eklemiştir. Bu faaliyet alanlarında da Arge, Üretim, İthalat, İhracat, Dağıtım, Satış, Pazarlama, Satış sonrası Hizmet ve Servis konularında faaliyet göstermektedir.',
    hakkimizda_paragraph2:'Yüksek kalite standartlarında üretim yapmakta olan EDS, 1997 yılı itibari ile ISO 9001 kalite belgesi almış ve 2003 yılında ISO 9001:2000 kalite yönetim sistemine geçmiştir. Güvenlik konusunda Türkiye\'de üretim yapan en büyük ve ISO 9001 standartlarına sahip ilk firmadır.',
    hakkimizda_paragraph3:'Üretimine SMPS (Anahtarlamalı Güç Kaynakları) ile başlayan EDS, dedektörler, telefon arayıcı, harici ve dahili sirenler, yangın panelleri, yangın butonları, gaz ve nem dedektörleri, ortam denetleme sistem ürünleri ile yelpazesini genişletmiştir. Yangın dedektörlerimizde EN 54 onayları mevcuttur.',
    hakkimizda_paragraph4:'İklimlendirme cihazı, SMD dizgi makinası, baskı makinası, lehim makinası, tampon baskı makinası ve makina parkuruyla son derece gelişmiş üretim alt yapısına sahiptir.',
    hakkimizda_paragraph5:'Tüm Türkiye ve 35\' i aşkın ülkede ürünlerimiz kullanılmakta ve birçok projede yer almaktadır. Banka, havalimanı, askeriye, savunma sanayi, özel kurum/kuruluşlar, stadyum, mağaza zincirleri, telekomünikasyon, ulaşım, medikal gibi birçok alanda EDS ürünleri yer almıştır ve almaya devam etmektedir.',
    hakkimizda_paragraph6:'Üretimi dışında ithalatta da kaliteyi hedefleyen EDS, konusunda lider dünya devi firmaların Türkiye temsilciliğini yapmakta (Hanwha Techwin, FLIR, Kilsen, UTC, Supra, Texecom, Klaxon, EL, Aleph, CQR, GJD, Paxton, Noclimb, Vicor, Phihong) ve Türkiye çapında servis ağı ile dağıtımını gerçekleştirmektedir.',
    hakkimizda_paragraph7:'Bugün EDS, 5000m² üzerine kurulu fabrikasında; Sancaktepe ve Ankara\'da 60\'ın üzerinde çalışanı ve 500.000TL sermayesiyle hizmet vermektedir.',
    hakkimizda_paragraph8:'EDS, Sanayi odası, Tüyak ve Gesider üyesidir.',
    hakkimizda_paragraph9:'',
    hakkimizda_paragraph10:'',
    iletisim:'İLETİŞİM',
    iletisim_paragraph:'Size nasıl yardımcı olabiliriz?',
    iletisim_paragraph1:'Ürün ve Hizmetler',
    iletisim_paragraph2:'Adı:',
    iletisim_paragraph3:'Soyadı:',
    iletisim_paragraph4:'Firma Adı:',
    iletisim_paragraph5:'Mobil:',
    iletisim_paragraph6:'E-Mail:',
    iletisim_paragraph7:'Mesajınız:',
    iletisim_paragraph8:'Güvenlik Kodu:',
    iletisim_paragraph9:'Mesaj Gönder',
    iletisim_paragraph10:'Telefon:',
    iletisim_paragraph11:'Adres:',
    iletisim_paragraph12:'Geri Bildirim Ve Şikayetler',
    iletisim_paragraph13:'Teknik Destek',
    iletisim_paragraph14:'Diğer',
    teknikdestek_paragraph:'Size Nasıl Yardımcı Olabiliriz?',
    teknikdestek_paragraph1:'Ürün ile ilgili sorularınızda aşağıdaki bilgilerden faydalanabilir veya bizimle iletişime geçebilirsiniz.',
    teknikdestek_paragraph2:'Dökümanlar',
    teknikdestek_paragraph3:'Kullanım kılavuzu ve',
    teknikdestek_paragraph4:'broşürlerden destek alabilirsiniz.',
    teknikdestek_paragraph5:'Sıkça Sorulan Sorular',
    teknikdestek_paragraph6:'Sorular hakkında bilgi ve',
    teknikdestek_paragraph7:'cevaplar bulabilirsiniz.',
    teknikdestek_paragraph8:'Bilgi ve teknik ürün videolarını',
    teknikdestek_paragraph9:'youtube kanalımızda bulabilirsiniz.',
    teknikdestek_paragraph10:'İletişim',
    teknikdestek_paragraph11:'Bizimle iletişime geçebilirsiniz',
    teknikdestek_paragraph12:'',
    teknikdestek_paragraph13:'',
    teknikdestek_paragraph14:'',
    login_paragraph:'Kullanıcı Adı',
    login_paragraph1:'GİRİŞ',
    login_paragraph2:'Şifremi Unuttum',
    login_paragraph3:'Hesabın yok mu?',
    login_paragraph4:'Kayıt ol',
    login_paragraph5:'Uygulamayı İndir',
    login_paragraph6:'',
    header_paragraph:'HAKKIMIZDA',
    header_paragraph1:'SATIN AL',
    header_paragraph2:'TEKNİK DESTEK',
    header_paragraph3:'İLETİŞİM',
    header_paragraph4:'CİHAZLAR',
    header_paragraph5:'LOKASYONLAR',
    header_paragraph6:'Harita',
    header_paragraph7:'Kat Planı',
    header_paragraph8:'İZLEME',
    header_paragraph9:'GEÇMİŞ VERİLER',
    header_paragraph10:'GEÇMİŞ OLAYLAR',
    header_paragraph11:'DETAYLI RAPOR',
    header_paragraph12:'AYARLAR',
    header_paragraph13:'Genel Ayarlar',
    header_paragraph14:'Gelişmiş Ayarlar',
    header_paragraph15:'TESTLER',
    header_paragraph16:'Hesap',
    header_paragraph17:'Hesabı düzenle',
    header_paragraph18:'Oturumu Kapat',
    header_paragraph19:'',
    header_paragraph20:'',
    header_paragraph21:'',
    header_paragraph22:'',
    footer_paragraph:'HAKKIMIZDA',
    footer_paragraph1:'TEKNİK DESTEK',
    footer_paragraph2:'İLETİŞİM',
    footer_paragraph3:'SİTE HARİTASI',
    footer_paragraph4:'GİZLİLİK POLİTİKASI',
    footer_paragraph5:'S.S.S',
    footer_paragraph6:'',
    footer_paragraph7:'',
    footer_paragraph8:'',
    footer_paragraph9:'',
    forgot_pass_paragraph:'Devam etmek için, e-mail adresinizi yazınız.',
    forgot_pass_paragraph1:'GÖNDER',
    forgot_pass_paragraph2:'',
    change_pass_paragraph:'',
    change_pass_paragraph1:'',
    change_pass_paragraph2:'',
    summary_system_paragraph:'Sensör:',
    summary_system_paragraph1:'Üst Limit:',
    summary_system_paragraph2:'Alt Limit:',
    summary_system_paragraph3:'Ölçüm Değeri:',
    summary_system_paragraph4:'Veriler İşleniyor',
    summary_system_paragraph5:'Grafik Hazırlanıyor',
    summary_system_paragraph6:'Rapor Al',
    summary_system_paragraph7:'Sıcaklık Sensörü',
    summary_system_paragraph8:'MAC Adresi:',
    summary_system_paragraph9:'Sensör Adı:',
    summary_system_paragraph10:'Üst Limit:',
    summary_system_paragraph11:'Alt Limit:',
    summary_system_paragraph12:'Ölçüm Değeri:',
    summary_system_paragraph13:'Düzenle',
    summary_system_paragraph14:'Verileri görmek için bir cihaza tıkla',
    summary_system_paragraph15:'FİRMA',
    summary_system_paragraph16:'DURUM',
    summary_system_paragraph17:'SENSÖR',
    summary_system_paragraph18:'İşleminiz gerçekleşti!',
    summary_system_paragraph19:'İşlem sırasında hata',
    edit_account_paragraph:'Adı:',
    edit_account_paragraph1:'Soyadı:',
    edit_account_paragraph2:'Mobil:',
    edit_account_paragraph3:'E-Mail:',
    edit_account_paragraph4:'Yetki Seviyesi:',
    edit_account_paragraph5:'Kayıtlı Firmalar:',
    edit_account_paragraph6:'Ülke:',
    edit_account_paragraph7:'İl:',
    edit_account_paragraph8:'İlçe:',
    edit_account_paragraph9:'Adres:',
    edit_account_paragraph10:'Posta Kodu:',
    edit_account_paragraph11:'Profili Düzenle',
    edit_account_paragraph12:'ESENSE ÜCRETSİZ PAKET',
    edit_account_paragraph13:'eSense ücretsiz paketi kullanıyorsun.',
    edit_account_paragraph14:'Pakete 1 aylık veri tutma, anlık bildirimler dahildir.',
    edit_account_paragraph15:'1 yıllık e-mail paketi, SMS veya anlık arama almak istiyorsan paketleri hesabına ekleyebilirsin.',
    edit_account_paragraph16:'1 yıllık e-mail paketi al',
    edit_account_paragraph17:'SMS paketi al',
    edit_account_paragraph18:'Telefonla arama paketi al',
    edit_account_paragraph19:'',
    register_account_paragraph:'Adınız',
    register_account_paragraph1:'Soyadınız',
    register_account_paragraph2:'Firma',
    register_account_paragraph3:'Adres',
    register_account_paragraph4:'Posta kodu',
    register_account_paragraph5:'Eposta',
    register_account_paragraph6:'Tekrar eposta',
    register_account_paragraph7:'Şifre',
    register_account_paragraph8:'Tekrar şifre',
    register_account_paragraph9:'KAYIT OL',
    register_account_paragraph10:'Firma Adı',
    register_account_paragraph11:'Cihaz ID',
    
    time_paragraph:'SÜRELER',
    time_paragraph1:'Cihazın Veri Gönderim Süresi:',
    time_paragraph2:'Alarm Alma Süresi:',
    time_paragraph3:'Çevrimiçi/Çevrimdışı Olma Süresi:',
    time_paragraph4:'Buzzer:',
    time_paragraph5:'Çıkış Tetikleme:',
    time_paragraph6:'Kaydet',
    time_paragraph7:'',
    time_paragraph8:'',
    properties_paragraph:'ÖZELLİKLER',
    properties_paragraph1:'MAC Adresi:',
    properties_paragraph2:'Tipi:',
    properties_paragraph3:'Versiyon:',
    properties_paragraph4:'Firma:',
    properties_paragraph5:'Cihaz adı:',
    properties_paragraph6:'Adres:',
    properties_paragraph7:'Şehir:',
    properties_paragraph8:'Posta Kodu:',
    properties_paragraph9:'Sensör Sayısı:',
    properties_paragraph10:'Düzenle',
    properties_paragraph11:'Kaydet',
    properties_paragraph12:'cihaz ayarları göstermek için bir cihaza tıkla',
    notification_paragraph:'Bildirim Ayarları',
    notification_paragraph1:'Bildirim Seçenekleri',
    notification_paragraph2:'Her Gün',
    notification_paragraph3:'Pazar',
    notification_paragraph4:'Pazartesi',
    notification_paragraph5:'Salı',
    notification_paragraph6:'Çarşamba',
    notification_paragraph7:'Perşembe',
    notification_paragraph8:'Cuma',
    notification_paragraph9:'Cumartesi',
    notification_paragraph10:'E-Mail',
    notification_paragraph11:'Alarm',
    notification_paragraph12:'Alt Limit Aşımı',
    notification_paragraph13:'Üst Limit Aşımı',
    notification_paragraph14:'Hata',
    notification_paragraph15:'Düşük Pil',
    notification_paragraph16:'Haberleşme',
    notification_paragraph17:'Normal durum',
    notification_paragraph18:'Kaydet',
    notification_paragraph19:'Cihaz geçmiş olayları göstermek için bir cihaza tıkla',
    registered_users_paragraph:'KAYITLI KULLANICILAR',
    registered_users_paragraph1:'Kopyala',
    registered_users_paragraph2:'Print',
    registered_users_paragraph3:'Add User',
    registered_users_paragraph4:'New User',
    registered_users_paragraph5:'Email address:',
    registered_users_paragraph6:'User:',
    registered_users_paragraph7:'Yetki seviyes:',
    registered_users_paragraph8:'Gönder',
    registered_users_paragraph9:'KAYITLI KULLANICILAR',
    registered_users_paragraph10:'Kopyala',
    registered_users_paragraph11:'Excel',
    registered_users_paragraph12:'Yazdır',
    registered_users_paragraph13:'PDF',
    registered_users_paragraph14:'Kullanıcı Ekle',
    registered_users_paragraph15:'Kullanıcı ara',
    registered_users_paragraph16:'Kullanıcı Ekle',
    registered_users_paragraph17:'Yeni Kullanıcı',
    registered_users_paragraph18:'Email Adresi:',
    registered_users_paragraph19:'Kullanıcı:',
    registered_users_paragraph20:'Yetki seviyesi:',
    registered_users_paragraph21:'Gönder',
    registered_users_paragraph22:'',
    registered_users_paragraph23:'',
    registered_users_paragraph24:'',
    registered_users_paragraph25:'',
    registered_users_paragraph26:'',
    registered_users_paragraph27:'',
    account_paragraph:'Adı:',
    account_paragraph1:'Soyadı:',
    account_paragraph2:'Mobil:',
    account_paragraph3:'E-Mail:',
    account_paragraph4:'Yetki Seviyesi:',
    account_paragraph5:'Kayıtlı Firma:',
    account_paragraph6:'Ülke:',
    account_paragraph7:'İl:',
    account_paragraph8:'İlçe:',
    account_paragraph9:'Adres:',
    account_paragraph10:'Posta Kodu:',
    account_paragraph11:'Resim:',
    account_paragraph12:'Profili Düzenle',
    account_paragraph13:'ESENSE ÜCRETSİZ PAKET',
    account_paragraph14:'eSense ücretsiz paketi kullanıyorsun.',
    account_paragraph15:'Pakete 1 aylık veri tutma, anlık bildirimler dahildir.',
    account_paragraph16:'1 yıllık e-mail paketi, SMS veya anlık arama almak istiyorsan paketleri hesabına ekleyebilirsin.',
    account_paragraph17:'1 yıllık e-mail paketi al',
    account_paragraph18:'SMS paketi al',
    account_paragraph19:'Telefonla arama paketi al',
    account_paragraph20:'cihaz raporları göstermek için bir cihaza tıkla',
    account_paragraph21:'Bu cihazda sensor bulunmuyor',
    account_paragraph22:'Cihaz ayarları göstermek için bir cihaza tıkla',
    account_paragraph23:'',
    account_paragraph24:'',
    ozet_grafik:'Üst Limit -99\' dan küçük olamaz!',
    ozet_grafik1:'Üst Limit 99\' dan büyük olamaz!',
    ozet_grafik2:'Alt Limit -99\' dan küçük olamaz!',
    ozet_grafik3:'Alt Limit 99\' dan büyük olamaz!',
    ozet_grafik4:'Alt Limit Üst Limitten Büyük yada eşit olamaz!',
    ozet_grafik5:'Üst Limit Alt Limitten Küçük yada eşit olamaz!',
    ozet_grafik6:'Üst Limit -1\' dan küçük olamaz!',
    ozet_grafik7:'Üst Limit 99\' dan büyük olamaz!',
    ozet_grafik8:'Alt Limit -1\' dan küçük olamaz!',
    ozet_grafik9:'Alt Limit 99\' dan büyük olamaz!',
    ozet_grafik10:'Alt Limit Üst Limitten Büyük yada eşit olamaz!',
    ozet_grafik11:'Üst Limit Alt Limitten Küçük yada eşit olamaz!',
    ozet_grafik12:'Sıcaklık Sensörü',
    ozet_grafik13:'Nem Sensörü',
    ozet_grafik14:'Basınç Sensörü',
    ozet_grafik15:'Frekans Sensörü',
    ozet_grafik16:'Voltaj Sensörü',
    ozet_grafik17:'Toprak Kaçak Gerilimi Sensörü',
    ozet_grafik18:'Sayısal Giriş Sensörü',
    ozet_grafik19:'Metan Sensörü',
    ozet_grafik20:'Karbonmonoksit Sensörü',
    ozet_grafik21:'mA Giriş Sensörü',
    ozet_grafik22:'Gürültü Sensörü',
    ozet_grafik23:'İşleminiz gerçekleşti!',
    ozet_grafik24:'İşlem sırasında hata',
    ozet_grafik25:'Limitlerinizde problem var...',
    ozet_grafik26:'1s',
    ozet_grafik27:'8s',
    ozet_grafik28:'24s',
    ozet_grafik29:'1h',
    ozet_grafik30:'cihaz internet bağlantısını kontrol ediniz. ',
    ozet_grafik31:'Üst Limit 300\' dan büyük olamaz!',
    ozet_grafik32:'Üst Limit -300\' dan küçük olamaz!',
    ozet_grafik33:'Üst Limit -300\' dan küçük olamaz!',
    ozet_grafik35:'Alt Limit 300\' dan büyük olamaz!',
    ozet_grafik36:'Alt Limit -300\' dan küçük olamaz!',
    ozet_grafik37:'',
    ozet_grafik38:'',
    event_paragraph:'GEÇMİŞ OLAYLAR',
    event_paragraph1:'Kopyala',
    event_paragraph2:'Excel',
    event_paragraph3:'Yazdır',
    event_paragraph4:'PDF',
    event_paragraph5:'DURUM SEÇ',
    event_paragraph6:'CİHAZLAR',
    event_paragraph7:'Cihaz Ara',
    event_paragraph8:'Veriler Yükleniyor',
    event_paragraph9:'SENSOR SEÇ',
    event_paragraph10:'Batarya',
    privacy_paragraph1:'iSense Websitesi ve uygulamasını ve EDS Elektronik (EDS\'yi) tercih ettiğiniz için teşekkür ederiz. Kişisel verilerinize ve gizliliğinizi korumaya önem veriyoruz. Bu konu ile ilgili herhangi bir bilgiye ihtiyacınız olursa eds@eds.com.tr adresinden bizimle iletişime geçebilirsiniz. Mobil uygulamamızı indirdiğinizde kişisel verilerinizi bizimle paylaşmış olursunuz. Bu gizlilik sözleşmesinde hangi verilerinizi işlediğimiz hakkında detaylı bilgilere ulaşabilirsiniz. Lütfen bu gizlilik sözleşmesini okuyunuz',
    privacy_paragraph2:'Gizlilik Sözleşmesinin Amacı',
    privacy_paragraph3:'Bu politika, sizden topladığımız veya bize sağladığınız tüm kişisel verilerin bizim tarafımızdan işleneceğinin temelini belirler. Kişisel verilerinizle ilgili görüş ve uygulamalarımızı anlamak için lütfen aşağıdakileri dikkatlice okuyun ve Aşağıdaki web sitelerini ve uygulamaları ziyaret ederek, bu politikada açıklanan uygulamaları kabul edip onayladığınızı görüyorsunuz. 1998 tarihli Veri Koruma Yasası (Yasa) amacıyla, veri denetleyicisi EDS Elektronik Destek San. Tic. Şti. www.isense.com.tr ve iSense ile ilgili mobil uygulamalar',
    privacy_paragraph4:'Sizden topladığımız bilgiler',
    privacy_paragraph5:'Sizinle ilgili şu verileri toplayacağız ve işleyeceğiz: Hakkınızda topladığımız bilgiler uygulama ve sitemize yaptığınız ziyaretlerin her biri ile ilgili olarak aşağıdaki bilgileri otomatik olarak toplayacağız: İnternet protokolü (IP) dahil teknik bilgiler bilgisayarınızı İnternet\'e bağlamak için kullanılan adres, oturum açma bilgileriniz, tarayıcı türü ve sürümü, saat dilimi ayarı, tarayıcı eklentisi türleri ve sürümleri, işletim sistemi ve platform, URL dahil ziyaretinizle ilgili bilgiler, sitemize ve sitemizden (tarih ve saat dahil) gelen tıklama akışı, görüntülediğiniz veya aradığınız ürünler, sayfa yanıt süreleri, indirme hataları, belirli sayfalara ziyaretlerin uzunluğu, sayfa etkileşimi bilgileri (kaydırma, tıklamalar), sayfaya göz atmak için kullanılan yöntemler ve müşteri hizmetleri numaramızı aramak için kullanılan herhangi bir telefon numarası, diğer kaynaklardan aldığımız bilgiler: işlettiğimiz diğer web siteleri veya sunduğumuz diğer hizmetler. Bu durumda, bu verileri dahili olarak paylaşmak ve bu sitede toplanan verilerle birleştirmek istiyorsak, bu verileri topladığımızda sizi bilgilendirmiş olacağız. Ayrıca verilerinizi hangi amaçla paylaşacağımızı ve eşleştireceğimizi de size söyleyeceğiz. Üçüncü taraflarla (örneğin, iş ortakları, teknik, ödeme ve dağıtım hizmetlerindeki alt yükleniciler, reklam ağları, analiz sağlayıcıları, arama bilgi sağlayıcıları, kredi referans ajansları dahil) yakın bir şekilde çalışıyoruz. Onlardan hakkınızda bilgi aldığımızda ve bu bilgileri hangi amaçla kullanmayı amaçladığımız konusunda sizi bilgilendireceğiz.',
    privacy_paragraph6:'Çerezler',
    privacy_paragraph7:'Web sitelerimiz ve mobil uygulamalarımız, sizi web sitemizin diğer kullanıcılarından ayırmak için çerezler kullanır.Bu, web sitemize ve mobil uygulamamıza göz attığınızda size iyi bir deneyim sunmamıza yardımcı olur ve ayrıca sitemizi geliştirmemize olanak tanır. hakkınızda tutulan bilgileri aşağıdaki şekillerde kullanın: Bize verdiğiniz bilgiler Bu bilgileri kullanacağız: sizinle aramızda yapılan sözleşmelerden doğan yükümlülüklerimizi yerine getirmek ve size verdiğiniz bilgi, ürün ve hizmetleri sunmak bizden talep; size daha önce satın almış veya sorgulamış olduğunuz ürünlere benzer diğer mal ve hizmetler hakkında bilgi vermek; hizmetimizdeki değişiklikler hakkında sizi bilgilendirmek; sitemizdeki içeriğin sizin için ve bilgisayarınız için en etkili şekilde Sizin hakkınızda topladığımız bilgiler Bu bilgileri kullanacağız: sitemizi yönetmek ve sorun giderme, veri analizi dahil dahili işlemler için ysis, test, araştırma, istatistik ve anket amaçları; içeriğin sizin ve bilgisayarınız için en etkili şekilde sunulmasını sağlamak üzere sitemizi geliştirmek; seçtiğinizde hizmetimizin interaktif özelliklerine katılmanıza izin vermek; sitemizi güvende tutma çabalarımızın bir parçası olarak; size ve başkalarına sunduğumuz reklamların etkinliğini ölçmek veya anlamak ve size ilgili reklamları sunmak; sizi ve sitemizin diğer kullanıcılarını ilginizi çekebilecek ürünler veya hizmetler hakkında önerilerde bulunmak ve önerilerde bulunmak. Diğer kaynaklardan aldığımız bilgiler. Bu bilgileri bize verdiğiniz bilgiler ve sizinle ilgili topladığımız bilgilerle birleştireceğiz. Bu bilgileri ve birleştirilmiş bilgileri yukarıda belirtilen amaçlar için kullanacağız.',
    privacy_paragraph8:'Bilgilerinizin Paylaşımı',
    privacy_paragraph9:'Kişisel bilgilerinizi şu kişilerle paylaşma hakkına sahip olduğumuzu kabul edersiniz: Grubumuzun herhangi bir üyesi, yani iştiraklerimiz, nihai holding şirketimiz ve bayi firmalar. Sizinle yaptığımız herhangi bir sözleşmenin yerine getirilmesi için yükleniciler.',
    privacy_paragraph10:'Kişisel bilgilerinizi nerede sakladığımız',
    privacy_paragraph11:'Sizden topladığımız veriler, Türkiye ve Avrupa Ekonomik Alanında bulunan bir hedefe aktarılabilir ve burada saklanabilir. Bu, diğer verilerin yanı sıra, siparişinizin yerine getirilmesini, ödeme ayrıntılarınızın işlenmesini ve destek hizmetlerinin verilmesini yapan personeli içerir. Kişisel verilerinizi göndererek, bu aktarımı, depolamayı veya Verilerinizin güvenli bir şekilde ve bu gizlilik politikasına uygun şekilde ele alınmasını sağlamak için makul olarak gerekli tüm adımları atacağız. Bize sağladığınız tüm bilgiler güvenli sunucularda saklanır. Size verilen uygulamanın belirli bölümlerine erişmenizi sağlayan şifreyi gizli tutmak sizin sorumluluğunuzdadır. Sizden kimseyle bu şifreyi paylaşmamanızı rica ediyoruz. İnternet üzerinden bilgi aktarımı tamamen değil güvenli olmayabilir. Kişisel verilerinizi korumak için elimizden geleni yapsak da, uygulamamızca iletilen verilerinizin iletiminin riski size aittir. Bilgilerinizi aldıktan sonra, yetkisiz erişimi önlemek için katı prosedürler ve güvenlik önlemleri kullanacağız.',
    privacy_paragraph12:'Haklarınız',
    privacy_paragraph13:'Kişisel verilerinizi pazarlama amacıyla işlemememizi isteme hakkına sahipsiniz. Verilerinizi bu tür amaçlarla kullanmayı amaçlıyorsak veya ifşa etmemeyi planlıyorsak, genellikle (verilerinizi toplamadan önce) Verilerinizi toplamak için kullandığımız formlardaki belirli kutuları işaretleyerek bu tür işlemeyi engelleme hakkınızı kullanabilirsiniz. Ayrıca eds@eds.com adresinden bizimle iletişime geçerek de istediğiniz zaman hakkını kullanabilirsiniz. Sitemiz zaman zaman iş ortağı ağlarımızın, reklamverenlerin ve bağlı kuruluşlarımızın mobil uygulamasına ve uygulamasından bağlantılar içerebilir.Bu web sitelerinin ve mobil uygulamaların herhangi birine bir bağlantıyı izlerseniz, lütfen bu web sitelerinin ve mobil uygulamaların kendi gizlilik politikaları vardır ve bu politikalar için herhangi bir sorumluluk veya yükümlülük kabul etmiyoruz. Bu web sitelerine ve mobil uygulamalarına herhangi bir kişisel veri göndermeden önce lütfen bu politikaları kontrol edin.',
    privacy_paragraph14:'Bilgiye erişim',
    privacy_paragraph15:'GDPR, hakkınızda tutulan bilgilere erişim hakkı verir. Erişim hakkınız GDPR\'ye uygun olarak kullanılabilir. Lütfen eds@eds.com.tr ile iletişime geçin',
    privacy_paragraph16:'Gizlilik politikamızdaki değişiklikler',
    privacy_paragraph17:'Bu gizlilik politikasına ilişkin sorular, yorumlar ve talepler memnuniyetle karşılanmaktadır ve eds@eds.com.tr adresine iletilmelidir',
    privacy_paragraph18:'İletişim',
    privacy_paragraph19:'Gelecekte gizlilik politikamızda yapacağımız değişiklikler bu sayfada yayınlanacaktır. Gizlilik politikamızdaki herhangi bir güncelleme veya değişikliği görmek için lütfen sık sık tekrar kontrol edin',
    privacy_paragraph20:'Gizlilik Sözleşmesi',
    privacy_paragraph21:'',
    privacy_paragraph22:'',
    site_map:'Sıkça Sorulan Sorular',
    site_map1:'İletişim',
    site_map2:'Hakkımızda',
    site_map3:'Satın Al',
    site_map4:'Teknik Destek',
    site_map5:'Gizlilik Politikası',
    site_map6:'Hesap',
    site_map7:'Ayarlar',
    site_map8:'Kamera',
    site_map9:'Geçmiş Olaylar',
    site_map10:'Detaylı Rapor',
    site_map11:'Cihazlar',
    site_map12:'Site Haritası',

    
    copy:{
        p1:'Paragraph with <b>html</b> inside',
        p2:'Paragraph with %(link)s'    
    },
    link:'Link'
}