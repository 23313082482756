import React, { Component } from 'react';
import Translate from 'react-translate-component'
import counterpart, { translate } from 'counterpart'





class GizlilikPolitikasi extends Component {
   

    render() {
        // console.log(this.props);
        // console.log(this.state);
        // this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');

        

        const placeholder=counterpart.translate('place')

        return (
            
            <div id="page-wrapper" className="m-t-20 m-b-20">

                {/* <select value={this.state.lang} onChange={this.onLangChange}  >
                    <option value="tr" >TR</option>
                    <option value="en" >EN</option>
                </select> */}
                {/* <Translate content="hakkimizda" component="h1" className="m-t-0" /> */}

                {/* <Translate content="copy.p1" component="p" unsafe={true} /> */}

                {/* <Translate content="copy.p2" component="p" with={{link}} /> */}

                <Translate component="div" type="text" attributes={{}}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2><Translate content="privacy_paragraph20" /></h2>
                        {/* <Translate content="hakkimizda" component="h3" className="m-t-0"/> */}
                    </div>
                    
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="white-box" style={{ "margin-bottom": "100px"}} >
                            <p content="hakkimizda">

                            <Translate content="privacy_paragraph1" component="p" />
                                


                              
                              
        
                                <Translate content="privacy_paragraph2" component="h3"  />
        
                                <Translate content="privacy_paragraph3" component="p"  />
        
                                <Translate content="privacy_paragraph4" component="h3"  />
        
                                <Translate content="privacy_paragraph5" component="p"  />
        
                                <Translate content="privacy_paragraph6" component="h3"  />
        
                                <Translate content="privacy_paragraph7" component="p"  />

                                <Translate content="privacy_paragraph8" component="h3"  />

                                <Translate content="privacy_paragraph9" component="p"  />

                                <Translate content="privacy_paragraph10" component="h3"  />

                                <Translate content="privacy_paragraph11" component="p"  />

                                <Translate content="privacy_paragraph12" component="h3"  />

                                <Translate content="privacy_paragraph13" component="p"  />

                                <Translate content="privacy_paragraph14" component="h3"  />

                                <Translate content="privacy_paragraph15" component="p"  />

                                <Translate content="privacy_paragraph16" component="h3"  />

                                <Translate content="privacy_paragraph17" component="p"  />

                                <Translate content="privacy_paragraph18" component="h3"  />

                                <Translate content="privacy_paragraph19" component="p"  />


                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default GizlilikPolitikasi;