import React, { Component } from 'react';

import Validate from "./utility/FormValidation";
import { Auth } from 'aws-amplify';
import Translate from 'react-translate-component'
import FormErrors from './FormErrors';

class ForgotPassword extends Component {
  constructor(props) {
		super(props);
  this.state = {
    email: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };
  this.forgotPasswordHandler = this.forgotPasswordHandler.bind(this);
}

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler(event) {
    // console.log(Auth);
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    // try {
    //   // console.log("okok");
    //   Auth.forgotPassword(this.state.email);
    //   this.props.history.push('/forgotpasswordverification');
    // }catch(error) {
    //   console.log(error);
    // }

    Auth.forgotPassword(this.state.email).then(data => {
      console.log("sent");
      console.log(data);
      this.props.history.push('/forgotpasswordverification');
    }).catch(err => {
      console.log("error");
      console.log(err);
    });
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    //document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    console.log(this.state);
    return (
      <div style={{paddingTop:"80px"}}>
   
      <section class="new-login-register">
	<div class="new-login-box">
		<div class="new-login-box-header"></div>
		<div class="white-box">
			<form id="loginform" onSubmit={this.forgotPasswordHandler} class="form-horizontal new-lg-form" data-toggle="validator">
				<div class="form-group">
					<div class="col-xs-12 text-center login-register-result m-b-20">
          <Translate content="forgot_pass_paragraph"  />
					</div>
					<div class="col-xs-12">
						<input onChange={this.onInputChange} type="email" name="email" id="email" class="form-control" placeholder="E-Mail"  />
					</div>
				</div>
				<div class="form-group text-center m-b-0">
					<div class="col-xs-12">
						<button type="submit" class="btn btn-info btn-lg btn-block waves-effect waves-light"><Translate content="forgot_pass_paragraph1"  /></button>
					</div>
				</div>
        &nbsp;
        <div style={{ color: "white", background: "red" }}>
                            <FormErrors formerrors={this.state.errors} />
                          </div>
			</form>
		</div>
	</div>
</section>
   </div>
    );
  }
}

export default ForgotPassword;