import React, { Component } from 'react';
import Axios from "axios";
import { Auth } from "aws-amplify";
import Translate from 'react-translate-component'

class bildirimayar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			daysmail: 127,
			dayssms: 127,
			daysmobil: 127,
			allalarm: false,
			altalarm: false,
			ustalarm: false,
			allhata: true,
			dusukpilhata: false,
			haberlesmehata: false,
			online: false,
			offline: false,
			normal: false,
			bildirimkaydetalertShow: false, 
			bildirimkaydetalertMessage: "",
			bildirimkaydetalertType: "",
		};
		this.handledayscheckbutton = this.handledayscheckbutton.bind(this);
		this.bildirimolaycheckbutton = this.bildirimolaycheckbutton.bind(this);
	}



	handledayscheckbutton(event) {
		var cked = event.target.checked;
		var index = parseInt(event.target.id.charAt(event.target.id.length - 1));
		var biltype = event.target.id.slice(0, -1);
		if (index == 1) {
			if (cked) {
				if (biltype == "email") {
					this.setState({
						daysmail: 127
					});
				} else if (biltype == "sms") {
					this.setState({
						dayssms: 127
					});
				} else if (biltype == "mobil") {
					this.setState({
						daysmobil: 127
					});
				}


				for (var i = 2; i < 9; i++) {
					document.getElementById(biltype + i).checked = true;
					document.getElementById(biltype + i).disabled = true;
				}
			} else {

				if (biltype == "email") {
					this.setState({
						daysmail: 0
					});
				} else if (biltype == "sms") {
					this.setState({
						dayssms: 0
					});
				} else if (biltype == "mobil") {
					this.setState({
						daysmobil: 0
					});
				}
				for (var i = 2; i < 9; i++) {
					document.getElementById(biltype + i).checked = false;
					document.getElementById(biltype + i).disabled = false;
				}
			}
		} else {
			if (cked) {
				if (biltype == "email") {
					this.setState({
						daysmail: (this.state.daysmail | Math.pow(2, index - 2))
					});
				} else if (biltype == "sms") {
					this.setState({
						dayssms: (this.state.dayssms | Math.pow(2, index - 2))
					});
				} else if (biltype == "mobil") {
					this.setState({
						daysmobil: (this.state.daysmobil | Math.pow(2, index - 2))
					});
				}
			} else {

				if (biltype == "email") {
					this.setState({
						daysmail: (this.state.daysmail & (127 - Math.pow(2, index - 2)))
					});
				} else if (biltype == "sms") {
					this.setState({
						dayssms: (this.state.dayssms & (127 - Math.pow(2, index - 2)))
					});
				} else if (biltype == "mobil") {
					this.setState({
						daysmobil: (this.state.daysmobil & (127 - Math.pow(2, index - 2)))
					});
				}
			}
		}


	}

	handlecheckbutton11(event) {
		this.handledayscheckbutton(event.target.checked, parseInt(event.target.id.charAt(event.target.id.length - 1)), event.target.id.slice(0, -1));
	}

	bildirimolaycheckbutton(event) {
		switch (event.target.id) {
			case "alarm":
				this.setState({
					allalarm: event.target.checked,
					altalarm: event.target.checked,
					ustalarm: event.target.checked,
				});
				document.getElementById("alt_limit").disabled = !event.target.checked;
				document.getElementById("ust_limit").disabled = !event.target.checked;
				document.getElementById("alt_limit").checked = event.target.checked;
				document.getElementById("ust_limit").checked = event.target.checked;
				break;
			case "alt_limit":
				this.setState({
					altalarm: event.target.checked,
				});

				break;
			case "ust_limit":
				this.setState({
					ustalarm: event.target.checked,
				});
				break;
			case "hata":
				this.setState({
					allhata: event.target.checked,
					dusukpilhata: event.target.checked,
					haberlesmehata: event.target.checked,
				});
				document.getElementById("dusuk_pil").disabled = !event.target.checked;
				document.getElementById("haberlesme").disabled = !event.target.checked;
				document.getElementById("dusuk_pil").checked = event.target.checked;
				document.getElementById("haberlesme").checked = event.target.checked;
				break;
			case "dusuk_pil":
				this.setState({
					dusukpilhata: event.target.checked,
				});
				break;
			case "haberlesme":
				this.setState({
					haberlesmehata: event.target.checked,
				});
				break;
			case "online":
				this.setState({
					online: event.target.checked,
				});
				break;
			case "ofline":
				this.setState({
					offline: event.target.checked,
				});
				break;
			case "normal":
				this.setState({
					normal: event.target.checked,
				});
				break;
			default:
				break;
		}
	}

	getuserInfos = async event => {
        try {
            const currentUserInfo = await Auth.currentUserInfo();
            if (!this.state.reload) {
                this.setState({ 
                    reload: true,
                    phone_number: currentUserInfo.attributes['phone_number'],
                 });
            }

        } catch (err) {
            console.log('error fetching user info: ', err);
        }


    };

	bildirimkaydet() {
		Auth.user.getSession((err, session) => {
			if (err) {
				throw new Error(err);
			}
			console.log("before");
			const sessionToken = session.getIdToken().jwtToken;

			var notifSettings = {
				"allalarm": this.state.allalarm,
				"allhata": this.state.allhata,
				"altalarm": this.state.altalarm,
				"daysmail": this.state.daysmail,
				"daysmobil": this.state.daysmobil,
				"dayssms": this.state.dayssms,
				"dusukpilhata": this.state.dusukpilhata,
				"haberlesmehata": this.state.haberlesmehata,
				"normal": this.state.normal,
				"offline": this.state.offline,
				"online": this.state.online,
				"ustalarm": this.state.ustalarm
			  };
			var	msg = {
					"msgtype": "bildirimupdate",
					"userid": this.props.userinfo.userId,
					"bildirimSettings": notifSettings
				};
			

			Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': sessionToken
				},
			}).then((res) => {
				{(localStorage.getItem('lang')==='tr')?
                this.setState({ bildirimkaydetalertType: "alert-success", bildirimkaydetalertMessage: "İşleminiz gerçekleşti!", bildirimkaydetalertShow: true })
                :
                this.setState({ bildirimkaydetalertType: "alert-success", bildirimkaydetalertMessage: "Your transaction has been completed!", bildirimkaydetalertShow: true });
                }
				setTimeout(() => {
					this.setState({ kuleklealertShow: false });
				}, 2000
				);


			}).catch((error) => {
				this.setState({ kuleklealertType: "alert-warning", kuleklealertMessage: "bildirim ayarı günceleme post ta hata: " + error, kuleklealertShow: true });
			});
		});
	}


	ShowMessage() {

        return (
            <div className={"alert " + this.state.bildirimkaydetalertType} role="alert">
                <strong>{this.state.bildirimkaydetalertMessage}</strong>
                <button  type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }


	componentDidMount() {

		if (this.props.userinfo.ConfigNotificat.notifSettings.daysmail == 127) {
			document.getElementById("email1").checked = true;
			for (var i = 2; i < 9; i++) {
				document.getElementById("email" + i).checked = true;
			}
		} else {
			document.getElementById("email1").checked = false;
			for (var i = 2; i < 9; i++) {
				document.getElementById("email" + i).disabled = false;
				if (this.props.userinfo.ConfigNotificat.notifSettings.daysmail & Math.pow(2, (i - 2))) {
					document.getElementById("email" + i).checked = true;
				} else {
					document.getElementById("email" + i).checked = false;
				}
			}
		}
		if (this.props.userinfo.ConfigNotificat.notifSettings.dayssms == 127) {
			document.getElementById("sms1").checked = true;
			for (var i = 2; i < 9; i++) {
				document.getElementById("sms" + i).checked = true;
			}
		} else {
			document.getElementById("sms1").checked = false;
			for (var i = 2; i < 9; i++) {
				document.getElementById("sms" + i).disabled = false;
				if (this.props.userinfo.ConfigNotificat.notifSettings.dayssms & Math.pow(2, (i - 2))) {
					document.getElementById("sms" + i).checked = true;
				} else {
					document.getElementById("sms" + i).checked = false;
				}
			}
		}
		if (this.props.userinfo.ConfigNotificat.notifSettings.daysmobil == 127) {
			document.getElementById("mobil1").checked = true;
			for (var i = 2; i < 9; i++) {
				document.getElementById("mobil" + i).checked = true;
			}
		} else {
			document.getElementById("mobil1").checked = false;
			for (var i = 2; i < 9; i++) {
				document.getElementById("mobil" + i).disabled = false;
				if (this.props.userinfo.ConfigNotificat.notifSettings.daysmobil & Math.pow(2, (i - 2))) {
					document.getElementById("mobil" + i).checked = true;
				} else {
					document.getElementById("mobil" + i).checked = false;
				}
			} 
		}
		
		document.getElementById("alarm").checked = this.props.userinfo.ConfigNotificat.notifSettings.allalarm;
		document.getElementById("alt_limit").checked = this.props.userinfo.ConfigNotificat.notifSettings.altalarm;
		document.getElementById("ust_limit").checked = this.props.userinfo.ConfigNotificat.notifSettings.ustalarm;
		document.getElementById("hata").checked = this.props.userinfo.ConfigNotificat.notifSettings.allhata;
		document.getElementById("dusuk_pil").checked = this.props.userinfo.ConfigNotificat.notifSettings.dusukpilhata;
		document.getElementById("haberlesme").checked = this.props.userinfo.ConfigNotificat.notifSettings.haberlesmehata;
		document.getElementById("online").checked = this.props.userinfo.ConfigNotificat.notifSettings.online;
		document.getElementById("ofline").checked = this.props.userinfo.ConfigNotificat.notifSettings.offline;
		document.getElementById("normal").checked = this.props.userinfo.ConfigNotificat.notifSettings.normal;
		this.setState({
			daysmail: this.props.userinfo.ConfigNotificat.notifSettings.daysmail,
			dayssms: this.props.userinfo.ConfigNotificat.notifSettings.dayssms,
			daysmobil: this.props.userinfo.ConfigNotificat.notifSettings.daysmobil,
			allalarm: this.props.userinfo.ConfigNotificat.notifSettings.allalarm,
			altalarm: this.props.userinfo.ConfigNotificat.notifSettings.altalarm,
			ustalarm: this.props.userinfo.ConfigNotificat.notifSettings.ustalarm,
			allhata: this.props.userinfo.ConfigNotificat.notifSettings.allhata,
			dusukpilhata: this.props.userinfo.ConfigNotificat.notifSettings.dusukpilhata,
			haberlesmehata: this.props.userinfo.ConfigNotificat.notifSettings.haberlesmehata,
			online: this.props.userinfo.ConfigNotificat.notifSettings.online,
			offline: this.props.userinfo.ConfigNotificat.notifSettings.offline,
			normal: this.props.userinfo.ConfigNotificat.notifSettings.normal
		});

	}

	render() {
		this.getuserInfos();
		return (
			<div class="white-box">
				<form action="" method="POST" data-toggle="validator">
					<div class="row m-b-20">
						<div class="col-md-12 col-sm-12 col-xs-12">
							<h3 class="m-t-0 m-b-30 left-border"><Translate content="notification_paragraph"  /></h3>
							<h5>{'Email: '+Auth.user.attributes.email}</h5>
							<h5>{'Telefon: '+this.state.phone_number}</h5>
						</div>
					</div>
					<div class="rwd-table checkbox-style m-b-20">
						<table class="table color-table muted-table table-hover text-center m-b-0">
							<thead>
								<tr>
									<th scope="col" class="text-left text-dark"><Translate content="notification_paragraph1"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph2"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph3"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph4"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph5"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph6"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph7"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph8"  /></th>
									<th scope="col" class="text-center text-dark"><Translate content="notification_paragraph9"  /></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td data-label="Bildirim Seçenekleri" class="text-left"><Translate content="notification_paragraph10"  /></td>
									<td data-label="Her Gün">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email1" name="email1" onChange={this.handledayscheckbutton} />
											<label for="email1"></label>
										</div>
									</td>
									<td data-label="Pazartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email2" name="email2" disabled onChange={this.handledayscheckbutton} />
											<label for="email2"></label>
										</div>
									</td>
									<td data-label="Salı">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email3" name="email3" disabled onChange={this.handledayscheckbutton} />
											<label for="email3"></label>
										</div>
									</td>
									<td data-label="Çarşamba">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email4" name="email4" disabled onChange={this.handledayscheckbutton} />
											<label for="email4"></label>
										</div>
									</td>
									<td data-label="Perşembe">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email5" name="email5" disabled onChange={this.handledayscheckbutton} />
											<label for="email5"></label>
										</div>
									</td>
									<td data-label="Cuma">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email6" name="email6" disabled onChange={this.handledayscheckbutton} />
											<label for="email6"></label>
										</div>
									</td>
									<td data-label="Cumartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email7" name="email7" disabled onChange={this.handledayscheckbutton} />
											<label for="email7"></label>
										</div>
									</td>
									<td data-label="Pazar">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="email8" name="email8" disabled onChange={this.handledayscheckbutton} />
											<label for="email8"></label>
										</div>
									</td>
								</tr>
								<tr>
									<td data-label="Bildirim Seçenekleri" class="text-left">SMS</td>
									<td data-label="Her Gün">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms1" name="sms1" onChange={this.handledayscheckbutton} />
											<label for="sms1"></label>
										</div>
									</td>
									<td data-label="Pazartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms2" name="sms2" disabled onChange={this.handledayscheckbutton} />
											<label for="sms2"></label>
										</div>
									</td>
									<td data-label="Salı">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms3" name="sms3" disabled onChange={this.handledayscheckbutton} />
											<label for="sms3"></label>
										</div>
									</td>
									<td data-label="Çarşamba">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms4" name="sms4" disabled onChange={this.handledayscheckbutton} />
											<label for="sms4"></label>
										</div>
									</td>
									<td data-label="Perşembe">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms5" name="sms5" disabled onChange={this.handledayscheckbutton} />
											<label for="sms5"></label>
										</div>
									</td>
									<td data-label="Cuma">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms6" name="sms6" disabled onChange={this.handledayscheckbutton} />
											<label for="sms6"></label>
										</div>
									</td>
									<td data-label="Cumartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms7" name="sms7" disabled onChange={this.handledayscheckbutton} />
											<label for="sms7"></label>
										</div>
									</td>
									<td data-label="Pazar">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="sms8" name="sms8" disabled onChange={this.handledayscheckbutton} />
											<label for="sms8"></label>
										</div>
									</td>
								</tr>
								<tr>
									<td data-label="Bildirim Seçenekleri" class="text-left">Mobil</td>
									<td data-label="Her Gün">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil1" name="mobil1" onChange={this.handledayscheckbutton} />
											<label for="mobil1"></label>
										</div>
									</td>
									<td data-label="Pazartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil2" name="mobil2" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil2"></label>
										</div>
									</td>
									<td data-label="Salı">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil3" name="mobil3" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil3"></label>
										</div>
									</td>
									<td data-label="Çarşamba">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil4" name="mobil4" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil4"></label>
										</div>
									</td>
									<td data-label="Perşembe">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil5" name="mobil5" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil5"></label>
										</div>
									</td>
									<td data-label="Cuma">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil6" name="mobil6" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil6"></label>
										</div>
									</td>
									<td data-label="Cumartesi">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil7" name="mobil7" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil7"></label>
										</div>
									</td>
									<td data-label="Pazar">
										<div class="checkbox checkbox-info checkbox-circle">
											<input type="checkbox" id="mobil8" name="mobil8" disabled onChange={this.handledayscheckbutton} />
											<label for="mobil8"></label>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="row checkbox-style m-b-0">
						<div class="col-md-4 col-sm-6 col-xs-12 m-b-20">
							<table class="table color-table muted-table table-hover m-b-0">
								<tbody>
									<tr>
										<td width="70%"><Translate content="notification_paragraph11"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="alarm" name="alarm" onChange={this.bildirimolaycheckbutton} />
												<label for="alarm"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%"><Translate content="notification_paragraph12"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="alt_limit" name="alt_limit" onChange={this.bildirimolaycheckbutton} />
												<label for="alt_limit"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%"><Translate content="notification_paragraph13"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="ust_limit" name="ust_limit" onChange={this.bildirimolaycheckbutton} />
												<label for="ust_limit"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-12 m-b-20">
							<table class="table color-table muted-table table-hover m-b-0">
								<tbody>
									<tr>
										<td width="70%"><Translate content="notification_paragraph14"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="hata" name="hata" onChange={this.bildirimolaycheckbutton} />
												<label for="hata"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%"><Translate content="notification_paragraph15"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="dusuk_pil" name="dusuk_pil" onChange={this.bildirimolaycheckbutton} />
												<label for="dusuk_pil"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%"><Translate content="notification_paragraph16"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="haberlesme" name="haberlesme" onChange={this.bildirimolaycheckbutton} />
												<label for="haberlesme"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="col-md-4 col-sm-6 col-xs-12 m-b-20">
							<table class="table color-table muted-table table-hover m-b-0">
								<tbody>
									<tr>
										<td width="70%">Online</td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="online" name="online" onChange={this.bildirimolaycheckbutton} />
												<label for="online"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%">Offline</td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="ofline" name="ofline" onChange={this.bildirimolaycheckbutton} />
												<label for="ofline"></label>
											</div>
										</td>
									</tr>
									<tr>
										<td width="70%"><Translate content="notification_paragraph17"  /></td>
										<td class="text-center">
											<div class="checkbox checkbox-info checkbox-circle">
												<input type="checkbox" id="normal" name="normal" onChange={this.bildirimolaycheckbutton} />
												<label for="normal"></label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div className="form-group">
						{this.state.bildirimkaydetalertShow && this.ShowMessage()}
					</div>
					<button type="button" class="btn btn-info" style={{ "width": "200px" }} onClick={() => { this.bildirimkaydet() }}><Translate content="notification_paragraph18" /></button>
				</form>
			</div>
		);
	}
}

export default bildirimayar;