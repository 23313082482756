import React, { Component } from 'react';
import IonRangeSlider from 'react-ion-slider';
import Switch from '../utility/Switch';
import { Auth } from "aws-amplify";
import Axios from "axios";
import Translate from 'react-translate-component'

class sureler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            SaveButtonHide: "hidden",
            surekaydetalertType: "",
            surekaydetalertMessage: "",
            surekaydetalertShow: false
        };
        this.handleChangeGS = this.handleChangeGS.bind(this);
        this.handleChangeAS = this.handleChangeAS.bind(this);
        this.handleChangeCS = this.handleChangeCS.bind(this);
        this.handlebuzchange = this.handlebuzchange.bind(this);
        this.handletetikChange = this.handletetikChange.bind(this);
    }
    handleChangeGS = (event) => {
        console.log(event.from);
        this.setState({
            gonderimsuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handleChangeAS = (event) => {
        console.log(event.from);
        this.setState({
            alarmsuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handleChangeCS = (event) => {
        this.setState({
            onlinofflinesuresi: event.from
        })
        this.setState({ SaveButtonHide: "visible" });
    }

    handlebuzchange() {
        this.setState({ SaveButtonHide: "visible" });
        this.setState({ buzzercheckbox: !this.state.buzzercheckbox });
    };

    handletetikChange() {
        this.setState({ SaveButtonHide: "visible" });
        this.setState({ cikistetik: !this.state.cikistetik });
    }

    surelerkaydet() {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }
            const sessionToken = session.getIdToken().jwtToken;

            var surelerSettings = {
                "alarmsuresi": this.state.alarmsuresi,
                "buzzercheckbox": this.state.buzzercheckbox,
                "cikistetik": this.state.cikistetik,
                "gonderimsuresi": this.state.gonderimsuresi,
                "onlinofflinesuresi": this.state.onlinofflinesuresi
            };

            var msg = {
                "msgtype": "surelerupdate",
                "devid": this.props.devinfo.deviceId,
                "surelerSettings": surelerSettings
            };

            Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then((res) => {
                {(localStorage.getItem('lang')==='tr')?
                this.setState({ surekaydetalertType: "alert-success", surekaydetalertMessage: "İşleminiz gerçekleşti!", surekaydetalertShow: true })
                :
                this.setState({ surekaydetalertType: "alert-success", surekaydetalertMessage: "Your transaction has been completed!", surekaydetalertShow: true })
                }
                setTimeout(() => {
                    this.setState({ surekaydetalertShow: false });
                }, 4000
                );
                this.setState({ SaveButtonHide: "hidden" });


            }).catch((error) => {
                this.setState({ surekaydetalertType: "alert-warning", surekaydetalertMessage: "bildirim ayarı günceleme post ta hata: " + error, surekaydetalertShow: true });
            });
        });
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.surekaydetalertType} role="alert">
                <strong>{this.state.surekaydetalertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    componentDidMount() {

        this.setState(Object.assign({}, this.state, {}), () => {
            this.ionSliderGS &&
                this.ionSliderGS.update({ from: this.props.devinfo.devConfigs.sendPer })
        });
        this.setState(Object.assign({}, this.state, {}), () => {
            this.ionSliderAS &&
                this.ionSliderAS.update({ from: this.props.devinfo.devConfigs.alrmPer })
        });
        this.setState(Object.assign({}, this.state, {}), () => {
            this.ionSliderCS &&
                this.ionSliderCS.update({ from: this.props.devinfo.devConfigs.onlineOfflineDelay })
        });

        this.setState({ buzzercheckbox: this.props.devinfo.devConfigs.buzOnOff == 0 ? false : true });


        document.getElementById("cikis_tetikleme").checked = this.props.devinfo.devConfigs.relayOnOff == 0 ? false : true;

    }

    render() {

        return (
            <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="white-box height7" >
                    <h3 class="m-t-0 left-border m-b-30"><Translate content="time_paragraph" /></h3>
                    <div class="form-horizontal">
                        <div class="form-group">
                            <label class="col-sm-4 control-label"><Translate content="time_paragraph1" /></label>
                            <div class="col-sm-8">
                                <IonRangeSlider ref={r => this.ionSliderGS = r} skin={'round'} type={'single'} min={1} max={60} from={3} to={'max'} max_postfix={' dakika'} onFinish={this.handleChangeGS} />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label"><Translate content="time_paragraph2" /></label>
                            <div class="col-sm-8">
                                <IonRangeSlider ref={r => this.ionSliderAS = r} skin={'round'} type={'single'} min={0} max={360} from={3} to={'max'} max_postfix={' dakika'} onFinish={this.handleChangeAS} />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label"><Translate content="time_paragraph3" /></label>
                            <div class="col-sm-8">
                                <IonRangeSlider ref={r => this.ionSliderCS = r} skin={'round'} type={'single'} min={0} max={360} from={3} to={'max'} max_postfix={' dakika'} onFinish={this.handleChangeCS} />
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-4 control-label"><Translate content="time_paragraph4" /></label>
                            <div class="col-sm-8">
                                <Switch
                                    isOn={this.state.buzzercheckbox}
                                    handleToggle={this.handlebuzchange}
                                    chid="check-buzzercheckbox"
                                    ref={r => this.buzswitch = r}
                                />
                            </div>
                        </div>
                        <div class="form-group cikis-tetikleme">
                            <label class="col-sm-4 control-label"><Translate content="time_paragraph5" /></label>
                            <div class="col-sm-8 checkbox-style">
                                <div class="checkbox checkbox-info checkbox-circle">
                                    <input type="checkbox" id="cikis_tetikleme" name="cikis_tetikleme" onChange={this.handletetikChange} />
                                    <label for="cikis_tetikleme"></label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <label class="col-sm-4 control-label"></label>
                            <div class="col-sm-8">
                                {this.state.surekaydetalertShow && this.ShowMessage()}
                                <button type="button" class="btn btn-info waves-effect waves-light" id="sa-success" style={{ "width": "120px", "visibility": this.state.SaveButtonHide }} onClick={() => { this.surelerkaydet() }}><Translate content="time_paragraph6" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default sureler;