import React, { Component } from 'react';
import Sidebar from '../sidebar';
import Olaytable from './olaytable';
import Select from "react-select";
import "react-dates/initialize"; 
import { DateRangePicker } from "react-dates"; 
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';
import Translate from 'react-translate-component'

class Events extends Component {
    state = {
        reload: false,
        username: null,
        email: null,
        password: null,
        confirmpassword: null,
        name: null,
        family_name: null,
        address: null,
        phone_number: null,
        locale: null,
        picture: null,
        users: null,
        devices: null,
        userToregisterDevice: null,
        deviceToregister: null,
        startDate: null,
        endDate: null,
        endDateFormatted: null,
        startDateFormatted: null,
    };

    constructor(props) {
        super(props);
    }


    hundleDateChange(startDate, endDate) {
        this.setState(() => ({
            endDate,
            startDate,
        }));
        if (startDate != null) {
            this.setState(() => ({
                startDateFormatted: startDate.format("D-MM-Y"),
            }));
        }
        if (endDate != null) {
            this.setState(() => ({
                endDateFormatted: endDate.format("D-MM-Y"),
            }));
        }
    }


    componentDidMount() {
        this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    }
    render() {

        const options1 = [
            { value: 'ALARM', label: 'ALARM' },
            { value: 'HATA', label: 'HATA' } 
        ];
        const options2 = [
            { value: 'SENSOR1', label: 'SENSOR1' },
            { value: 'SENSOR2', label: 'SENSOR2' } 
        ];
        moment.locale('pl');
        return (

            <div id="page-wrapper">
                <div class="container-fluid p-t-20">
                    <div class="row">
                        <div class="col-md-2 col-sm-12 col-xs-12">
                            {this.props.userinfo !== undefined &&
                                this.props.userinfo.configs !== undefined &&
                                <Sidebar devlist={this.props.userinfo.configs.regdevices} kulsev={this.props.userinfo.authLevel} selecteddev={this.props.match.params.devindex} page={"olaylar/"} />
                            }
                        </div>
                        <div class="col-md-10 col-sm-12 col-xs-12">
                            <div class="row">
                                {
                                    this.props.match.params.devindex === undefined &&
                                    <div  role="alert" style={{ "padding": "10px","border":"3px solid #138BAC","borderRadius":"10px","backgroundColor":"#fff" }}>
                                        <strong><Translate content="notification_paragraph19"  /></strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            {/* <span aria-hidden="true">&times;</span> */}
                                        </button>
                                    </div>
                                }
                                        {(this.props.match.params.devindex !== undefined) &&
                                            this.props.userinfo !== undefined &&
                                            <Olaytable userinfo={this.props.userinfo} deviceid={this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1]&&this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid} />
                                        }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default Events;
