import React from 'react';
import { Redirect } from 'react-router-dom';
// import FormErrors from "./FormErrors";
// import Validate from "./utility/FormValidation";
import Amplify, { Auth } from "aws-amplify";
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import Recaptcha from 'react-recaptcha'

if (localStorage.getItem('prevent'!==1)||!localStorage.getItem('prevent')) {
    localStorage.setItem('prevent',1)
}
var attempts=0
class Login extends React.Component {

    state={
        // lang:'tr'
        lang:this.setState({lang:'en'})
    }
    onLangChange=(e)=>{
        if (localStorage.getItem('lang')) {
             localStorage.removeItem('lang')
             localStorage.setItem('lang',e.target.value)
             this.setState({lang:e.target.value})
             counterpart.setLocale(e.target.value)
        }else{
             this.setState({lang:e.target.value})
             localStorage.setItem('lang',e.target.value)
             counterpart.setLocale(e.target.value)
        }
         // this.setState({lang:e.target.value})
         // counterpart.setLocale(e.target.value)
     }

    constructor(props) {
        super(props);
        this.handleSubscribe=this.handleSubscribe.bind(this)
        this.recaptchaLoaded=this.recaptchaLoaded.bind(this)
        this.verifyCallback=this.verifyCallback.bind(this)
        this.state = {
            Login: this.props.Login,
            username: "",
            password: "",
            SaveButtonHide: "hidden",
            AlertShow: true,
            AlertEmail:"Emailiniz onaylanmadı",
            AlertMessage: "Kullanici Bilgileriniz Hatalıdır",
            AlertType: "alert-warning",
            shh: "0",
            errors: {
                cognito: null,
                blankfield: false
            },
            Auth:{
                storage:window.sessionStorage
            },
            isChecked:true,
            flag:false
        };
        this.LoginGirisYap = this.LoginGirisYap.bind(this);
    }

    
    //Cognito Command
    handleSubscribe(){
        if(this.state.flag){
            // window.alert('You have Subscribed')
            
            // (async()=> await this.LoginGirisYap([key]))()
            (async () => {
                try {


                    // this.setState({SaveButtonHide:"hidden"});
        
                    const user = await Auth.signIn(document.getElementById('kullanici_adi').value, document.getElementById('sifre').value);
                    this.props.auth.setAuthStatus(true);
                    this.props.auth.setUser(user);
                    //sessionStorage.setItem("Login","1");
                    
                    this.props.history.push("./Dashboard");
                    
                    attempts=attempts-3;
                    
                } catch (e) {
                    console.log("Hata " + e.message);
                    attempts++;
                    this.setState({ SaveButtonHide: "visible", AlertShow: true, AlertMessage: "Kullanici Bilgileriniz Hatalıdır", AlertType: "alert-danger", shh: "65px" });
                    
        
                }
            })() //So we defined and immediately called this async function.
        //    console.log('Attempts: '+ attempts)

        }else{
            alert('Please verify that you are a human')
        }

    }
    recaptchaLoaded(){
        console.log('captcha succesfully loadedd');
    }
    verifyCallback(response){
        if (response) {
            this.setState({
                flag:true
            })
        }
    }

    clearErrorState = () => {
        this.setState({
            errors: {
                cognito: null,
                blankfield: false
            }
        });
    };

    toggleChange = () => {
        this.setState({
          isChecked: !this.state.isChecked,
        });
      }

    ShowMessage() {

        return (
            <div class={"alert " + this.state.AlertType} role="alert">
            {<div>
                {this.setState.AlertEmail?
                "Email'iniz aktif değil"
                :
                
                <b style={{ color: "white" }}> {this.state.AlertMessage}</b>}
                </div>}
            {/* <b style={{ color: "white" }}> {this.state.AlertMessage}</b> */}

        </div>
        );
    }

    //Cognito Command
    async LoginGirisYap() {

        //this.props.Method(1);

        try {
            // this.setState({SaveButtonHide:"hidden"});
            const user = await Auth.signIn(document.getElementById('kullanici_adi').value, document.getElementById('sifre').value);
            this.props.auth.setAuthStatus(true);
            this.props.auth.setUser(user);
            //sessionStorage.setItem("Login","1");
            
            this.props.history.push("./Dashboard");
            
            
            
        } catch (e) {
            if (e.message==='User is not confirmed.') {
                // toast.info('Email\'iniz onaylanmadı ',{
                //     className:css({
                //       background:"#EE0022 !important",
                //       borderRadius:'8px'
                      
                //     })
                //   })
                
                this.setState.AlertEmail=true
            }
            console.log("Hata " + e.message);
            attempts++;
            if (attempts>5) {

                

                attempts=1
            }
            this.setState({ SaveButtonHide: "visible", AlertShow: true, AlertMessage: "Kullanici Bilgileriniz Hatalıdır", AlertType: "alert-danger", shh: "65px" });


        }
        // console.log(attempts);
    }
    
    onKeyDown = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          this.LoginGirisYap();
        }
      }




    render() {
        Auth.currentAuthenticatedUser({
        }).then(user => {
          user.getCachedDeviceKeyAndPassword(); // without this line, the deviceKey is null
        //   console.log(user.deviceKey);
        });
    // {console.log(Auth)}
        // {console.log(Auth.currentUserInfo())}
        if (this.props.auth.isAuthenticated) {
            return (<Redirect to='/Dashboard/' />);
        }
        else {
            return (

                <div style={{ paddingTop: "20px" }}>
                    <section className="new-login-register">
                        <div className="new-login-box">
                            <div className="new-login-box-header"></div>
                            <div className="white-box">
                                <div style={{ "visibility": this.state.SaveButtonHide, "height": this.state.shh }}>

                                    {this.state.AlertShow ? this.ShowMessage() : null}

                                </div>
                                <form className="form-horizontal new-lg-form" data-toggle="validator">

                                    <div className="form-group">
                                        <div className="col-xs-12 username">
                                        <Translate component="input" attributes={{placeholder:'login_paragraph'}} id="kullanici_adi" className="form-control text-center" onKeyDown={this.onKeyDown} required/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12 password">
                                            <input type="password" name="sifre" id="sifre" className="form-control text-center" placeholder="**************" onChange={() => { this.setState({ shh: "0", SaveButtonHide: "hidden" }) }} onKeyDown={this.onKeyDown} required />
                                        </div>
                                    </div>

                                    <div className="form-group text-center m-t-20">
                                        <div className="col-xs-12">
                                            {
                                                attempts===3?
                                                <button type="button" onClick={this.handleSubscribe}  className="btn btn-info btn-lg btn-block waves-effect waves-light"><Translate content="login_paragraph1" component="p"  /></button>:
                                                <button type="button" onClick={this.LoginGirisYap}  className="btn btn-info btn-lg btn-block waves-effect waves-light"><Translate content="login_paragraph1" component="p"  /></button>
                                            }
                                            
                                        </div>
                                    </div>

                                    {  
                                        attempts===3?

                                        <div className="form-group">
                                        <div className="form-group text-center m-t-20"><Recaptcha
                                        sitekey="6LekpM8ZAAAAAI3tgVBxJQK7JmUkVf53c0rwhPRM"
                                        render="explicit"
                                        verifyCallback={this.verifyCallback}
                                        onloadCallback={this.recaptchaLoaded}
                                        /></div>
                                        </div>:""
                                    }
                                    
                                    


                                    <div className="form-group">
                                        <div className="col-md-12">
                                            <div className="checkbox checkbox-circle pull-left p-t-0">
                                                <input type="checkbox" id="benihatirla" name="benihatirla" 
                                                checked={this.state.isChecked}
                                                onChange={this.toggleChange}
                                                onClick={()=>{
                                                    
                                                    if (localStorage.getItem('remem')) {
                                                        localStorage.removeItem('remem')
                                                    }else{
                                                        localStorage.setItem('remem',true)
                                                    }
                                                //    Amplify.Auth.rememberDevice();
                                                        

                                                }
                                            }
                                                />
                                                <label for="benihatirla"> Beni Hatırla</label>
                                            </div>
                                             <a href="Kayipsifre" className="pull-right"><Translate content="login_paragraph2" component="p"  /></a>
                                        </div>
                                    </div>
                                    <div className="form-group m-b-0">
                                        <div className="col-sm-12 text-center">
                                            <p><Translate content="login_paragraph3" component="p"  /><a href="yenikayit" className="text-dark m-l-5"><b><Translate content="login_paragraph4" component="p"  /></b></a></p>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="text-center font-bold m-b-15"><Translate content="login_paragraph5" component="p"  /></div>
                            <div className="row">
                                <div style={{zIndex:"9999"}} className="col-md-6 col-sm-6 col-xs-6 text-right"><a target="_blank" href="https://apps.apple.com/us/app/isense/id1535795397"><img src="plugins/images/app-store.png" title="" alt="" /></a></div>
                                <div style={{zIndex:"9999"}} className="col-md-6 col-sm-6 col-xs-6 text-left"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.EMONesense&hl=en&gl=US"><img src="plugins/images/google-play.png" title="" alt="" /></a></div>
                            </div>

                        </div>

                    </section>

                </div>

            );
        }
    }
}

export default Login;
