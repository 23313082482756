import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import Axios from "axios";
import Translate from 'react-translate-component'

function ListItem(props) {
    if (props.devindex == props.selecteddev)
        return <li className="ashactive">
            
            <span className={props.status}></span>
            {props.batStatu=='NORMAL'?
                <div className="battery">
                <div className="battery-level " style={{width:"100%"}}></div>  
                 </div>
                 
                 :
                 props.batStatu=='LOW'?
                 <div className="battery">
                 <div className="battery-level warn" style={{width:'50%'}}></div>  
                  </div>
                  
                  :props.batStatu==='NOBATT'?
                 <div className="battery">
                 <div className="battery-level alertt" style={{width:'0%'}}><div className="battery-lighting"></div></div>  
                  </div>:''
                //   :
                //   <div className="battery">
                //   <div className="battery-level "  ><div className="battery-lighting"></div></div>  
                //    </div>

        } 
        
        <a href={(props.page? props.page:"Dashboard/") + props.devindex}>{props.devName}
        
        </a>
        
        </li>;
    else
        return <li><span className={props.status}></span>
        
        {props.batStatu=='NORMAL'?
                <div className="battery">
                <div className="battery-level " style={{width:"100%"}}></div>  
                 </div>
                 
                 :
                 props.batStatu=='LOW'?
                 <div className="battery">
                 <div className="battery-level warn" style={{width:'50%'}}></div>  
                  </div>
                  
                  :props.batStatu==='NOBATT'?
                 <div className="battery">
                 <div className="battery-level alertt" style={{width:'0%'}}><div className="battery-lighting"></div></div>  
                  </div>:''
                //   :
                //   <div className="battery">
                //   <div className="battery-level "  ><div className="battery-lighting"></div></div>  
                //    </div>

        }
        
         <a href={(props.page? props.page:"Dashboard/") + props.devindex}>{props.devName}
         
        </a>
        
        
         
        </li>;
}

function Getdevsinfo(props) {
    const devs = props.list;
    const listItems = devs.map((dev, index) =>
        <ListItem key={index}  batStatu={dev.batStatus} devName={dev.ad} status={dev.status} active={dev.active} devindex={dev.devindex} selecteddev={props.selecteddev} page={props.page}/>
    );
    //  console.log(listItems);
    return (
        <ul class="list-icons">
            {listItems}
        </ul>
    );
}

let liste = [];//[ "Server Odası", "Aşı Dolabı", "Eczane", "Mutfak", "Aşı Dolabı", "Eczane"];
let cihazlist = [];

class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cihazlist: [
                {
                    ad: "Server Odası",
                    status: "icon green"
                },
                {
                    ad: "Aşı Dolabı",
                    status: "icon green",
                    active: true
                },
                {
                    ad: "Eczane",
                    status: "icon green"
                },
                {
                    ad: "Mutfak",
                    status: "icon red"
                },
                {
                    ad: "Eczane2",
                    status: "icon green"
                }
            ],
            cihazlist2: [],
            displayedcihazlist: [],
            search:[]
            
        };

    }

    getOneDevData() {
        // event.preventDefault();
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;
            // console.log(this.props.devlist);
            const postMessage = {
                "msgtype": ((this.props.kulsev === 1) ? "getalldevs": "getdevs"),
                "devIds": this.props.devlist
            };
            Axios.post("https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod", postMessage, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
                // body: ''
            }).then((res) => {
                // console.log('res sidebar :'+JSON.stringify(res.data[0].devConfigs.firm));
                if (this.state.cihazlist2.length < 1) {
                    // console.log(res.data);
                    for (var i = 0; i < res.data.length; i++) {
                        if (res.data[i].devConfigs !== null && res.data[i].devConfigs !== undefined) {
                            // if (i === 0) {
                                // if (res.data[i].onlineOffline) {
                                //     this.state.cihazlist2.push({
                                //         "ad": res.data[i].devConfigs.devN,
                                //         "status": "icon green",
                                //         "active": true,
                                //         "devindex": i + 1
                                //     });
                                // } else {
                                //     this.state.cihazlist2.push({
                                //         "ad": res.data[i].devConfigs.devN,
                                //         "status": "icon red",
                                //         "active": true,
                                //         "devindex": i + 1
                                //     });
                                // }
                            // } else {
                                // var seconddd=(res.data[i].payload.batCheck)
                                // console.log(seconddd)
                                // let ilk=parseFloat((res.data[i].payload.bat)/8900)
                                // var second=Math.floor(ilk*100)
                                // var secondd=`${second}%`
                                var secondd=(res.data[i].payload.batCheck)
                            if (res.data[i].onlineOffline) {
                                this.state.cihazlist2.push({
                                    "ad": res.data[i].devConfigs.firm + " - " + res.data[i].devConfigs.devN,
                                    "status": "icon green",
                                    "devindex": i + 1,
                                    "batStatus":secondd
                                });
                            } else {

                                this.state.cihazlist2.push({ 
                                    "ad": res.data[i].devConfigs.firm + " - " + res.data[i].devConfigs.devN,
                                    "status": "icon red",
                                    "devindex": i + 1,
                                    // "batStatus":secondd
                                });
                            }
                        // }
                        } else {
                            if (res.data[i].onlineOffline) {
                                this.state.cihazlist2.push({
                                    "ad": res.data[i].devConfigs.firm + " - " + "TANIMSIZ",
                                    "status": "icon green",
                                    "devindex": i + 1,
                                    // "batStatus":secondd
                                });
                            } else {

                                this.state.cihazlist2.push({
                                    "ad": res.data[i].devConfigs.firm + " - " + "TANIMSIZ",
                                    "status": "icon red",
                                    "devindex": i + 1,
                                    "batStatus":secondd
                                });
                            }
                        }
                            
                    }
                    
                    this.setState({
                    //     cihazlist1: res.data,
                    //     //SensorType
                    });
                    
                } //else {
                //     const templist = this.state.cihazlist1;
                //     templist.push(res.data.Items);
                //     this.setState({
                //         cihazlist1: templist,
                //     })
                // }
                // console.log(res.data);
                // console.log("dev list post is success");
                cihazlist = this.state.cihazlist2;
                // console.log(cihazlist);
            })
                .catch(error => {
                    console.log("Error : " + error);
                });
        });
    }

    getdevsdata(devlist) {
        // console.log("devlist came");
        if (devlist !== false) {
            for (var i = 0; i < 2; i++) {
                this.getOneDevData(devlist[i].deviceid);
                // console.log(res[0]);
                // var joined = this.state.cihazlist2.concat(this.state.cihazlist1[0]);
                // this.setState({cihazlist2: joined});
                // console.log(devlist[i].deviceid);
            }
        }
    }

    cihazsearchhander(event) {
        // let searcjQery = event.target.value.toLowerCase();
        // let displayedcihazlist = cihazlist.filter((el) => {
        //         let searchValue = el.ad.toLowerCase();
        //         return searchValue.indexOf(searcjQery) !== -1;
        //     });
        // this.setState({
        //     displayedcihazlist: displayedcihazlist
        // });
    }


    componentDidMount() {
        this.getOneDevData();
        // this.getdevsdata(this.props.devlist);
        liste = [ "Server Odası", "Aşı Dolabı", "Eczane", "Mutfak", "Aşı Dolabı", "Eczane"];
       cihazlist = this.state.cihazlist2;
    //    console.log(cihazlist);

    }

    render() {
        // console.log(this.props);
        // this.getdevsdata(this.props.devlist);
        // this.getOneDevData("2222222222");
        // console.log(this.state);
        //const cihazlist = ["Server Odası", "Aşı Dolabı", "Eczane", "Mutfak", "Eczane2"];
        // const listItems = cihazlist.map((cihaz) =>
        //     <li>{cihaz}</li>);
        // let cihazlistesi = this.state.cihazlist2;

        let options;
        if (this.state.search.length) {
            const searchPattern = new RegExp(this.state.search.map(term => `(?=.*${term})`).join(''), 'i');
            options = cihazlist.filter(option =>
                option.ad.match(searchPattern)
            );
        } else {
            options = cihazlist;
        }
        return (

            <div className="white-boxx p-10 left-menu">
                {/* <Getdevsinfo numbers={cihazlist}/> */}
                {/* <ul>{listItems}</ul> */}
                {/* <form action="" method="GET" role="search" className="app-search m-b-5">
                    <input type="text" name="cihaz_search" className="form-control" placeholder="Cihaz Ara" onChange={this.cihazsearchhander} /> <button type="submit"><i className="fa fa-search"></i></button>
                </form> */}
                
                {/* <ul className="list-icons">
                    <li className="mainnav"><a href="favoriler.html">FAVORİLER</a></li>
                </ul> */}
                {/* <ul className="list-icons">
                    <li className="mainnav"><a href="favoriler.html">CİHAZLAR</a></li>
                </ul> */}
                {/* <Getdevsinfo list={cihazlistesi} selecteddev={this.props.selecteddev} /> */}

                <ul className="list-icons">
                <li className="mainnav"><a href="/Dashboard"><Translate content="event_paragraph6" /></a></li>
                    <form action="" method="GET" role="search" className="app-search m-b-5">
                    <Translate type="text" component="input" className="form-control" attributes={{placeholder:'event_paragraph7'}} onChange={(e) => this.setState({search: e.target.value.split(' ')})}/><button type="submit"><i className="fa fa-search"></i></button>
                        {/* <input type="text" name="cihaz_search" className="form-control" placeholder="Cihaz Ara" onChange={(e) => this.setState({search: e.target.value.split(' ')})}/> <button type="submit"><i className="fa fa-search"></i></button> */}
                    </form>
                    {/* <li className="active"><span className="icon green"></span> Server Odası</li>
                    <li><span className="icon red"></span> <a href="/#">Aşı Dolabı</a></li>
                    <li><span className="icon green"></span> <a href="/#">Eczane</a></li>
                    <li><span className="icon green"></span> <a href="/#">Mutfak</a></li>
                    <li><span className="icon green"></span> <a href="/#">Aşı Dolabı</a></li>
                    <li><span className="icon green"></span> <a href="/#">Eczane</a></li> */}
                    {/* {options.map((option, i) =>
                        <li key={option.ad + i}><span className="icon green"></span>{option.ad}</li>
                    )} */}
                    
                </ul>
                <Getdevsinfo list={options} selecteddev={this.props.selecteddev} page={this.props.page}/>
            </div>


        );
    }
}

export default Sidebar;