import React, { Component } from 'react';
// import Grafik from './OzetGrafik';
import Axios from "axios";


export default class Dashboard extends Component {
   constructor(props){
super(props);


this.state={
	datalar: [],

};
   }

//    getUserInfo = (posturl) => {
//     //console.log("start of getuserinfo");
//     Axios.post(posturl,{
//       "msgtype": "userchkcmd",
//       "useremail": "ali.burhan@eds.com.tr"
//       }).then((response)=>{
//         // this.props.userid = response.data.userId;
//         // document.getElementById('CloseButton').click();
//         // alert("ok that is it " + response.data.configs.regdevices.length);
//         console.log("user info ret " + response.data.userId);
//       }).catch((error)=>{
//         alert("Post sırasında hata : "+error);
//       });
//   }
componentDidMount() {
   
   

    let ServiceAdress =
      "https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/2222222222/h/2";
      
	Axios.get(ServiceAdress)
	.then(res => {
	  const Cihazlar = res.data.Items;
	  this.setState({
		datalar: Cihazlar,
		//SensorType
	  });

	

	})
	.catch(error => {
	  console.log("Hatavar : " + error);
	});
   // console.clear();
  }



  render() {
	//   console.log("state");
	//   console.log(this.state);
	//   console.log("props");
	//   console.log(this.props.auth["user"]["storage"]["CognitoIdentityServiceProvider." + this.props.auth.user.pool.clientId + "." + this.props.auth.user.username + ".idToken"]);
	//   console.log(this.props.auth);
	//   console.log(this.props.auth.user);
	//   console.log(this.props.auth.user.pool.clientId);
	//   console.log("end");
	// this.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
	// this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
	console.log(this.state.datalar[0]);
        return (
  
			
<div id="page-wrapper">
		<div className="container-fluid p-t-20">
			<div className="row">
				<div className="col-md-2 col-sm-12 col-xs-12">
					<div className="white-box p-10 left-menu">
						<ul className="list-icons">
							<form action="" method="GET" role="search" className="app-search m-b-5">
								<input type="text" name="cihaz_search" className="form-control" placeholder="Cihaz Ara" /> <button type="submit"><i className="fa fa-search"></i></button>
							</form>
							<li className="mainnav"><a href="favoriler.html">FAVORİLER</a></li>
							<li className="mainnav"><a href="dashboard.html">CİHAZLAR</a></li>
							<li className="active"><span className="icon green"></span> Server Odası </li>
							<li><span className="icon red"></span> <a href="/#">Aşı Dolabı</a></li>
							<li><span className="icon green"></span> <a href="/#">Eczane</a></li>
							<li><span className="icon green"></span> <a href="/#">Mutfak</a></li>
							<li><span className="icon green"></span> <a href="/#">Aşı Dolabı</a></li>
							<li><span className="icon green"></span> <a href="/#">Eczane</a></li>
						</ul>
					</div>
				</div>
				<div className="col-md-10 col-sm-12 col-xs-12">
					<div className="row">
						<div className="col-md-6 col-sm-12 col-xs-12">
							<h3 className="m-t-0">Server Odası - EDS Elektronik</h3>
						</div>
						<div className="col-md-6 col-sm-12 col-xs-12">
							<div className="row">
								<div className="col-md-4 col-sm-6 col-xs-12">
									<select name="firma" className="m-b-20 selectpicker" data-style="form-control">
										<option value="" data-hidden="true">FİRMA</option>
										<option value="firma1">FİRMA 1</option>
										<option value="firma2">FİRMA 2</option>
									</select>
								</div>
								<div className="col-md-4 col-sm-6 col-xs-12">
									<div className="selectdiv">
										<select name="durum" className="m-b-20 selectpicker" data-style="form-control">
											<option value="" data-hidden="true">DURUM</option>
											<option value="alarm">ALARM</option>
											<option value="hata">HATA</option>
										</select>
									</div>
								</div>
								<div className="col-md-4 col-sm-6 col-xs-12">
									<div className="selectdiv">
										<select name="sensor" className="m-b-20 selectpicker" data-style="form-control">
											<option value="" data-hidden="true">SENSÖR</option>
											<option value="sensor1">SENSÖR 1</option>
											<option value="sensor2">SENSÖR 2</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
{
// this.state.datalar[0]

}
				
</div>
</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
	
        );
    }
}

//export default Dashboard;