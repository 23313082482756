import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import countryList from 'react-select-country-list';
import Select from 'react-select';
import axios from "axios";
import Translate from 'react-translate-component'


class Hesapduzenle extends Component {

  state = {
    reload: false,
    name: "",
    family_name: "",
    address: "",
    phone_number: "",
    postakodu: "",
    country: "",
    picture: "",
    il: "", 
    ilce: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    },
    options: countryList().getData(),
    selectedFile: null
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmitEdit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    if (this.state.selectedFile !== null) {
      this.onFileUpload();
      console.log("fileupload");
    }
        

    // AWS Cognito integration here
    const { name, family_name, address, phone_number, postakodu, picture, il, ilce, country } = this.state;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const signUpResponse = await Auth.updateUserAttributes(user, {
        name: name,
        family_name: family_name,
        address: address,
        phone_number: phone_number,
        'custom:postakodu': postakodu,
        // picture: picture,
        'custom:il': il, 
        'custom:ilce': ilce,
        'custom:ulke': country
      });
      this.props.history.push("/hesap");
      console.log(signUpResponse);
      Auth.user.getSession((err, session) => {
        const accessTok=Auth.user.signInUserSession.accessToken.jwtToken;
        const sessionToken = session.getIdToken().jwtToken;
        axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', {
          "msgtype": "userchk",
          "useremail": Auth.user.attributes.email,
          "userId": this.props.userinfo.userId,
          "tel":phone_number,
          "usertoken":Auth.user.signInUserSession.accessToken.jwtToken
          
          }, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionToken
                  },
                }).then(response => {
              console.log('response', +response); //api error
            }).catch(error => {
              console.log('api error', +error.response); //api error
           });
        })
    } catch (error) {
      let err = null;
      localStorage.getItem('lang')==='tr'&&error.message==='Attribute value for custom:ilce must not be null'?err={"message":"İlce verisi Bos bırakılamaz"}:
      (localStorage.getItem('lang')==='en'||!localStorage.getItem('lang'))&&error.message==='Attribute value for custom:ilce must not be null'?err={"message":"District can't be empty"}:

      localStorage.getItem('lang')==='tr'&&error.message==='Attribute value for address must not be null'?err={"message":"Adres kısmı boş bırakılamaz"}:
      (localStorage.getItem('lang')==='en'||!localStorage.getItem('lang'))&&error.message==='Attribute value for address must not be null'?err={"message":"Address can't be empty"}:

      localStorage.getItem('lang')==='tr'&&error.message==='Attribute value for custom:postakodu must not be null'?err={"message":"Postakodu kısmı boş bırakılamaz"}:
      (localStorage.getItem('lang')==='en'||!localStorage.getItem('lang'))&&error.message==='Attribute value for custom:postakodu must not be null'?err={"message":"Post code can't be empty"}:

      localStorage.getItem('lang')==='tr'&&error.message==='Attribute value for custom:il must not be null'?err={"message":"İl kısmı boş bırakılamaz"}:
      (localStorage.getItem('lang')==='en'||!localStorage.getItem('lang'))&&error.message==='Attribute value for custom:il must not be null'?err={"message":"Province  can't be empty"}:
      
      localStorage.getItem('lang')==='tr'&&error.message==='Attribute value for custom:ulke must not be null'?err={"message":"Ülke kısmı boş bırakılamaz"}:
      (localStorage.getItem('lang')==='en'||!localStorage.getItem('lang'))&&error.message==='Attribute value for custom:ulke must not be null'?err={"message":"Country  can't be empty"}:

      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
    if (event.target.files != null) {
          this.setState({ selectedFile: event.target.files[0] });
    }


  }



  getuserInfos = async event => {

    try {
      const currentUserInfo = await Auth.currentUserInfo();
      if (!this.state.reload) {
        this.setState({
          reload: true,
          name: currentUserInfo.attributes['name'],
          family_name: currentUserInfo.attributes['family_name'],
          email: currentUserInfo.attributes['email'],
          address: currentUserInfo.attributes['address'],
          phone_number: currentUserInfo.attributes['phone_number'],
          postakodu: currentUserInfo.attributes['custom:postakodu'],
          // picture: currentUserInfo.attributes['picture'],
          il: currentUserInfo.attributes['custom:il'],
          ilce: currentUserInfo.attributes['custom:ilce'],
          country: currentUserInfo.attributes['custom:ulke'],
        });
      }

    } catch (err) {
      console.log('error fetching user info: ', err);
    }


  };

  selectCountry (val) {
    this.setState({ country: val.label });
  }

onFileUpload = () => { 
     
  const formData = new FormData(); 
 
  formData.append( 
    "myFile", 
    this.state.selectedFile, 
    this.state.selectedFile.name 
  ); 
 
  console.log(this.state.selectedFile); 
 

}; 


  componentDidMount() {
    this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    this.getuserInfos();
  }
  render() {
   
    return (


      <div>
        <div id="page-wrapper">
          <div class="container-fluid p-t-20">
            <div class="row">

              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="white-box">
                  <form onSubmit={this.handleSubmitEdit}>
                    <div class="form-horizontal">
                      <div class="form-group">
                      <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph"  /></label>
                        <div class="col-sm-6">
                          <input 
                          type="text" 
                          name="adi"
                          id="name"
                          class="form-control" 
                          value={this.state.name}
                          onChange={this.onInputChange}
                          required />
                          </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph1"  /></label>
                        <div class="col-sm-6"><input type="text" name="soyadi" id="family_name" class="form-control" value={this.state.family_name} onChange={this.onInputChange} required /></div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph2"  /></label>
                        <div class="col-sm-6"><input type="text" name="mobil" id="phone_number" class="form-control" value={this.state.phone_number} onChange={this.onInputChange} required /></div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph3"  /></label>
                        <div class="col-sm-9">{this.state.email}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph4"  /></label>
                        <div class="col-sm-9">
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 1) && "Süper Admin"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 2) && "Admin"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 3) && "Bakım"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 4) && "Kullanıcı"}
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph5"  /></label>
                        <div class="col-sm-9">{this.props.userinfo !== undefined && this.props.userinfo.firm}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph6"  /></label>
                        <div class="col-sm-6">
                          <Select
                            options={this.state.options}
                            value={this.state.options.filter(option => option.label === this.state.country)}
                            onChange={(val) => this.selectCountry(val)}
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph7"  /></label>
                        <div class="col-sm-6"><input type="text" name="il" id="il" class="form-control" value={this.state.il} onChange={this.onInputChange} /></div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph8"  /></label>
                        <div class="col-sm-6"><input type="text" name="ilce" id="ilce" class="form-control" value={this.state.ilce} onChange={this.onInputChange} /></div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph9"  /></label>
                        <div class="col-sm-6"><textarea type="text" name="adres" id="address" class="form-control" rows="3" value={this.state.address} onChange={this.onInputChange}></textarea></div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="edit_account_paragraph10"  /></label>
                        <div class="col-sm-6"><input type="text" name="posta_kodu" id="postakodu" class="form-control" value={this.state.postakodu} onChange={this.onInputChange} /></div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label hidden-xs"></label>
                        <div class="col-sm-6">
                          <div style={{ color: "white", background: "red" }}>
                            <FormErrors formerrors={this.state.errors} />
                          </div>
                          <button type="submit" class="btn btn-info btn-block waves-effect waves-light"><Translate content="edit_account_paragraph11"  /></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="white-box text-center height4">
                  <h3><Translate content="edit_account_paragraph12"  /></h3>
                  <p><Translate content="edit_account_paragraph13"  /></p>
                  <p><Translate content="edit_account_paragraph14"  /></p>
                  <p><Translate content="edit_account_paragraph15"  /></p>
                  <div class="row m-t-40">
                    <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="edit_account_paragraph16"  /></a>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="edit_account_paragraph17"  /></a>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="edit_account_paragraph18"  /></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    );
  }
}

export default Hesapduzenle;