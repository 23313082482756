import React, { Component } from 'react';
// import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import Translate from 'react-translate-component'

class Hesapduzenle extends Component {
  state = {
    reload: false,
    name: "",
    family_name: "",
    address: "",
    phone_number: "",
    postakodu: "",
    country: "",
    picture: "",
    il: "", 
    ilce: "",
    ulke: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmitEdit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { name, family_name, address, phone_number, locale, picture } = this.state;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const signUpResponse = await Auth.updateUserAttributes(user, {
        name: name,
        family_name: family_name,
        address: address,
        phone_number: phone_number,
        locale: locale,
        picture: picture

      });
      this.props.history.push("/hesap");
      console.log(signUpResponse);
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }



  getuserInfos = async event => {

    try {
      const currentUserInfo = await Auth.currentUserInfo();
      if (!this.state.reload) {
        this.setState({
          reload: true,
          name: currentUserInfo.attributes['name'],
          family_name: currentUserInfo.attributes['family_name'],
          email: currentUserInfo.attributes['email'],
          address: currentUserInfo.attributes['address'],
          phone_number: currentUserInfo.attributes['phone_number'],
          postakodu: currentUserInfo.attributes['custom:postakodu'],
          picture: currentUserInfo.attributes['picture'],
          il: currentUserInfo.attributes['custom:il'],
          ilce: currentUserInfo.attributes['custom:ilce'],
          country: currentUserInfo.attributes['custom:ulke'],
        });
      }

    } catch (err) {
      console.log('error fetching user info: ', err);
    }


  };

  componentDidMount() {
    this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
    this.getuserInfos();
  }

  render() {
    return (


      <div>
        <div id="page-wrapper">
          <div class="container-fluid p-t-20">
            <div class="row">

              <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="white-box">
                  <form onSubmit={this.handleSubmit}>
                    <div class="form-horizontal">
                      <div class="form-group">
                      <label class="col-sm-3 control-label"><Translate content="account_paragraph"  /></label>
                        <div class="col-sm-9">{this.state.name}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph1"  /></label>
                        <div class="col-sm-9">{this.state.family_name}</div>
                      </div>

                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph2"  /></label>
                        <div class="col-sm-9">{this.state.phone_number}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph3"  /></label>
                        <div class="col-sm-9">{this.state.email}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph4"  /></label>
                        <div class="col-sm-9">
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 1) && "Süper Admin"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 2) && "Admin"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 3) && "Bakım"}
                          {(this.props.userinfo !== undefined) &&
                            (this.props.userinfo.authLevel == 4) && "Kullanıcı"}
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph5"  /></label>
                        <div class="col-sm-9">{this.props.userinfo !== undefined && this.props.userinfo.firm}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph6"  /></label>
                        <div class="col-sm-9">{this.state.country}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph7"  /></label>
                        <div class="col-sm-9">{this.state.il}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph8"  /></label>
                        <div class="col-sm-9">{this.state.ilce}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph9"  /></label>
                        <div class="col-sm-9">{this.state.address}</div>
                      </div>
                      <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph10"  /></label>
                        <div class="col-sm-9">{this.state.postakodu}</div>
                      </div>
                      {/* <div class="form-group">
                        <label class="col-sm-3 control-label"><Translate content="account_paragraph11"  /></label>
                        <div class="col-sm-9"><img src="images/eds-logo-small-nav.png" title="" alt="" /></div>
                      </div> */}
                      <div class="form-group">
                        <label class="col-sm-3 control-label hidden-xs"></label>
                        <div class="col-sm-9">
                          <a href="/hesapduzenle" class="btn btn-info waves-effect waves-light"><Translate content="account_paragraph12"  /></a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="white-box text-center height4" style={{ "margin-bottom": "100px"}}>
                  <h3><Translate content="account_paragraph13"  /></h3>
                  <p><Translate content="account_paragraph14"  /></p>
                  <p><Translate content="account_paragraph15"  /></p>
                  <p><Translate content="account_paragraph16"  /></p>
                  <div class="row m-t-40">
                  <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="account_paragraph17"  /></a>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="account_paragraph18"  /></a>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12 m-b-15">
                      <a href="https://www.eds.com.tr/" class="btn btn-info btn-block waves-effect waves-light"><Translate content="account_paragraph19"  /></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    );
  }
}

export default Hesapduzenle;