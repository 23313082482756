export default {
    hakkimizda:'ABOUT US',
    hakkimizda_paragraph:'EDS ELEKTRONİK, was established in February 1990 to provide manufacturing and engineering services to companies in the field of electronics. Later, it added CCTV Systems, Security Systems, Fire Detection Systems, Access Control Systems, Perimeter Security Systems, Automation Systems, Environment Control Systems and Power Electronics to its fields of activity. In these fields of activity, it operates in the fields of R&D, Production, Import, Export, Distribution, Sales, Marketing, After-Sales Service and Service.',
    hakkimizda_paragraph2:'EDS, which manufactures with high quality standards, has obtained the ISO 9001 quality certificate as of 1997 and switched to the ISO 9001: 2000 quality management system in 2003. Turkey on Safety \'also has the largest is the first company engaged in the production and ISO 9001 standards.',
    hakkimizda_paragraph3:'EDS, which started its production with SMPS (Switched Power Supplies), has expanded its range with detectors, telephone dialer, external and internal sirens, fire panels, fire buttons, gas and moisture detectors, environment control system products. Our fire detectors have EN 54 approvals.',
    hakkimizda_paragraph4:'It has a highly developed production infrastructure with air conditioning device, SMD assembly machine, printing machine, soldering machine, pad printing machine and machine park.',
    hakkimizda_paragraph5:'Turkey and all our products are used in over 35 countries and is involved in many projects. EDS products have been used in many fields such as banks, airports, military, defense industry, private institutions / organizations, stadiums, store chains, telecommunications, transportation, and medical.',
    hakkimizda_paragraph6:'Aiming at the quality of imports other than the production of EDS on leading world giant companies in Turkey, the representative (Hanwha Techwin, FL, Kilsan, UTC, Supra, I Texecom, Klaxon, EL, Aleph, CQR, GJD, Paxton, Noclimb, Vicar, PHIHONG) and Turkey It distributes with a service network throughout.',
    hakkimizda_paragraph7:'Today, EDS, in its factory built on 5000m²; It provides services in Sancaktepe and Ankara with over 60 employees and a capital of 500,000 TL.',
    hakkimizda_paragraph8:'EDS is a member of, Chamber of Industry, Tüyak and Gesider.',
    hakkimizda_paragraph9:'',
    hakkimizda_paragraph10:'',
    iletisim:'CONTACT',
    iletisim_paragraph:'How can we help you?',
    iletisim_paragraph1:'Products and Services',
    iletisim_paragraph2:'Name:',
    iletisim_paragraph3:'Surname:',
    iletisim_paragraph4:'Company Name:',
    iletisim_paragraph5:'Mobile Number:',
    iletisim_paragraph6:'E-Mail:',
    iletisim_paragraph7:'Message:',
    iletisim_paragraph8:'Security Code:',
    iletisim_paragraph9:'Send Message',
    iletisim_paragraph10:'Telephone:',
    iletisim_paragraph11:'Address:',
    iletisim_paragraph12:'Feedback and complains',
    iletisim_paragraph13:'Technical Support',
    iletisim_paragraph14:'Other',
    teknikdestek_paragraph:'How Can We Help You?',
    teknikdestek_paragraph1:'You can use the information below or contact us for your questions about the product.',
    teknikdestek_paragraph2:'Documents',
    teknikdestek_paragraph3:'You can get support from',
    teknikdestek_paragraph4:'user manuals and brochures.',
    teknikdestek_paragraph5:'Frequently Asked Questions',
    teknikdestek_paragraph6:'You can find information ',
    teknikdestek_paragraph7:'and answers on questions.',
    teknikdestek_paragraph8:'You can find information and technical product',
    teknikdestek_paragraph9:'videos on our youtube channel.',
    teknikdestek_paragraph10:'Contact',
    teknikdestek_paragraph11:'You can contact us',
    teknikdestek_paragraph12:'',
    teknikdestek_paragraph13:'',
    teknikdestek_paragraph14:'',
    login_paragraph:'Username',
    login_paragraph1:'LOGIN',
    login_paragraph2:'Forgot my password',
    login_paragraph3:'Don\'t have an account ?',
    login_paragraph4:'Register',
    login_paragraph5:'Download the App',
    login_paragraph6:'',
    header_paragraph:'ABOUT US',
    header_paragraph1:'BUY',
    header_paragraph2:'TECHNICAL SUPPORT',
    header_paragraph3:'CONTACT',
    header_paragraph4:'DEVICES',
    header_paragraph5:'LOCATIONS',
    header_paragraph6:'Map',
    header_paragraph7:'Floor plan',
    header_paragraph8:'SURVEILLANCE',
    header_paragraph9:'HISTORY DATA',
    header_paragraph10:'PAST EVENTS',
    header_paragraph11:'DETAILED REPORT',
    header_paragraph12:'SETTINGS',
    header_paragraph13:'General Settings',
    header_paragraph14:'Advanced Settings',
    header_paragraph15:'TESTS',
    header_paragraph16:'Account',
    header_paragraph17:'Edit account',
    header_paragraph18:'Sign out',
    header_paragraph19:'',
    header_paragraph20:'',
    header_paragraph21:'',
    header_paragraph22:'',
    footer_paragraph:'ABOUT US',
    footer_paragraph1:'TECHNICAL SUPPORT',
    footer_paragraph2:'CONTACT',
    footer_paragraph3:'SITEMAP',
    footer_paragraph4:'PRIVACY POLICY',
    footer_paragraph5:'F.A.Q',
    footer_paragraph6:'',
    footer_paragraph7:'',
    footer_paragraph8:'',
    footer_paragraph9:'',
    forgot_pass_paragraph:'Enter your e-mail address to continue.',
    forgot_pass_paragraph1:'SEND',
    forgot_pass_paragraph2:'',
    change_pass_paragraph:'',
    change_pass_paragraph1:'',
    change_pass_paragraph2:'',
    summary_system_paragraph:'Sensor:',
    summary_system_paragraph1:'Upper Limit:',
    summary_system_paragraph2:'Lower Limit:',
    summary_system_paragraph3:'Measurement Value:',
    summary_system_paragraph4:'Processing Data',
    summary_system_paragraph5:'Preparing Graphics',
    summary_system_paragraph6:'Get Report',
    summary_system_paragraph7:'Temperature Sensor',
    summary_system_paragraph8:'MAC Address:',
    summary_system_paragraph9:'Sensor Name:',
    summary_system_paragraph10:'Upper limit:',
    summary_system_paragraph11:'Lower Limit:',
    summary_system_paragraph12:'Measurement Value:',
    summary_system_paragraph13:'Edit',
    summary_system_paragraph14:'Click on a device to see data\'s',
    summary_system_paragraph15:'COMPANY',
    summary_system_paragraph16:'STATUS',
    summary_system_paragraph17:'SENSOR',
    summary_system_paragraph18:'Your transaction has been completed!',
    summary_system_paragraph19:'Error during operation',
    edit_account_paragraph:'Name:',
    edit_account_paragraph1:'Surname:',
    edit_account_paragraph2:'Mobile:',
    edit_account_paragraph3:'E-Mail:',
    edit_account_paragraph4:'Authority Level:',
    edit_account_paragraph5:'Registered Firms:',
    edit_account_paragraph6:'Country',
    edit_account_paragraph7:'Province:',
    edit_account_paragraph8:'District:',
    edit_account_paragraph9:'Address:',
    edit_account_paragraph10:'Post code:',
    edit_account_paragraph11:'Edit Profile',
    edit_account_paragraph12:'ESENSE FREE PACKAGE',
    edit_account_paragraph13:'You are using the eSense free package.',
    edit_account_paragraph14:'1-month data retention, instant notifications are included in the package.',
    edit_account_paragraph15:'If you want to receive a 1-year e-mail package, SMS or instant calls, you can add the packages to your account.',
    edit_account_paragraph16:'Get a 1-year email package',
    edit_account_paragraph17:'Get an SMS package',
    edit_account_paragraph18:'Get a phone call package',
    edit_account_paragraph19:'',
    register_account_paragraph:'Name',
    register_account_paragraph1:'Surname',
    register_account_paragraph2:'Company',
    register_account_paragraph3:'Address',
    register_account_paragraph4:'Post Code',
    register_account_paragraph5:'E-mail',
    register_account_paragraph6:'Confirm E-mail',
    register_account_paragraph7:'Password',
    register_account_paragraph8:'Confirm Password',
    register_account_paragraph9:'REGISTER',
    register_account_paragraph10:'Company Name',
    register_account_paragraph11:'Device ID',
    
    time_paragraph:'PERIODS',
    time_paragraph1:'Data Sending Time Range:',
    time_paragraph2:'Alarm Delay Time Range:',
    time_paragraph3:'Online / Offline Time Range:',
    time_paragraph4:'Buzzer:',
    time_paragraph5:'Exit Trigger:',
    time_paragraph6:'Save',
    time_paragraph7:'',
    time_paragraph8:'',
    properties_paragraph:'SPECIFICATIONS',
    properties_paragraph1:'MAC Address:',
    properties_paragraph2:'Type:',
    properties_paragraph3:'Version:',
    properties_paragraph4:'Company:',
    properties_paragraph5:'Device name:',
    properties_paragraph6:'Address:',
    properties_paragraph7:'City:',
    properties_paragraph8:'Post code:',
    properties_paragraph9:'Number of Sensors:',
    properties_paragraph10:'Edit',
    properties_paragraph11:'Kaydet',
    properties_paragraph12:'click on a device to show device settings',
    notification_paragraph:'Notification Settings',
    notification_paragraph1:'Notification Options',
    notification_paragraph2:'Everyday',
    notification_paragraph3:'Sunday',
    notification_paragraph4:'Monday',
    notification_paragraph5:'Tuesday',
    notification_paragraph6:'Wednesday',
    notification_paragraph7:'Thursday',
    notification_paragraph8:'Friday',
    notification_paragraph9:'Saturday',
    notification_paragraph10:'E-Mail',
    notification_paragraph11:'Alarm',
    notification_paragraph12:'Exceeding the Lower Limit',
    notification_paragraph13:'Exceeding the Upper Limit',
    notification_paragraph14:'Error',
    notification_paragraph15:'Low Battery',
    notification_paragraph16:'Communication Fault',
    notification_paragraph17:'Normal situation',
    notification_paragraph18:'Save',
    notification_paragraph19:'Click on a device to see data\'s',
    registered_users_paragraph:'REGISTERED USERS',
    registered_users_paragraph1:'Copy',
    registered_users_paragraph2:'Print',
    registered_users_paragraph3:'Add User',
    registered_users_paragraph4:'New User',
    registered_users_paragraph5:'Email address:',
    registered_users_paragraph6:'User:',
    registered_users_paragraph7:'Authority level:',
    registered_users_paragraph8:'Send',
    registered_users_paragraph9:'REGISTERED USERS',
    registered_users_paragraph10:'Copy',
    registered_users_paragraph11:'Excel',
    registered_users_paragraph12:'Print',
    registered_users_paragraph13:'PDF',
    registered_users_paragraph14:'Add User',
    registered_users_paragraph15:'Search User',
    registered_users_paragraph16:'Add User',
    registered_users_paragraph17:'New User',
    registered_users_paragraph18:'Email address:',
    registered_users_paragraph19:'User:',
    registered_users_paragraph20:'Authority level:',
    registered_users_paragraph21:'Send',
    registered_users_paragraph22:'',
    registered_users_paragraph23:'',
    registered_users_paragraph24:'',
    registered_users_paragraph25:'',
    registered_users_paragraph26:'',
    registered_users_paragraph27:'',
    account_paragraph:'Name:',
    account_paragraph1:'Surname:',
    account_paragraph2:'Mobile:',
    account_paragraph3:'E-Mail:',
    account_paragraph4:'Authority Level:',
    account_paragraph5:'Registered Company:',
    account_paragraph6:'Country:',
    account_paragraph7:'Province:',
    account_paragraph8:'District:',
    account_paragraph9:'Address:',
    account_paragraph10:'Post Code:',
    account_paragraph11:'Image:',
    account_paragraph12:'Edit Profile',
    account_paragraph13:'ESENSE FREE PACKAGE',
    account_paragraph14:'You are using the eSense free package.',
    account_paragraph15:'1-month data retention, instant notifications are included in the package.',
    account_paragraph16:'If you want to receive a 1-year e-mail package, SMS or instant calls, you can add the packages to your account.',
    account_paragraph17:'Get a 1-year email package',
    account_paragraph18:'Get an SMS package',
    account_paragraph19:'Get a phone call package',
    account_paragraph20:'Click on a device to show device reports',
    account_paragraph21:'There is no sensor in this device',
    account_paragraph22:'Click on a device to show device settings',
    account_paragraph23:'',
    account_paragraph24:'',
    ozet_grafik:'Upper Limit cannot be less than -99!',
    ozet_grafik1:'Upper Limit cannot be higher than 99!',
    ozet_grafik2:'The Lower Limit cannot be less than -99!',
    ozet_grafik3:'The Lower Limit cannot be higher than 99!',
    ozet_grafik4:'The Lower Limit cannot be Greater than or equal to the Upper Limit!',
    ozet_grafik5:'Upper Limit cannot be Less than or equal to Lower Limit!',
    ozet_grafik6:'Upper Limit cannot be less than -1!',
    ozet_grafik7:'Upper Limit cannot be higher than 99!',
    ozet_grafik8:'The Lower Limit cannot be less than -1!',
    ozet_grafik9:'The Lower Limit cannot be higher than 99!',
    ozet_grafik10:'The Lower Limit cannot be Greater than or equal to the Upper Limit!',
    ozet_grafik11:'Upper Limit cannot be Less than or equal to Lower Limit!',
    ozet_grafik12:'Temperature Sensor',
    ozet_grafik13:'Humidity Sensor',
    ozet_grafik14:'Pressure sensor',
    ozet_grafik15:'Frequency Sensor',
    ozet_grafik16:'Voltage Sensor',
    ozet_grafik17:'Earth Leakage Voltage Sensor',
    ozet_grafik18:'Digital Input Sensor',
    ozet_grafik19:'Methane Sensor',
    ozet_grafik20:'Carbon Monoxide Sensor',
    ozet_grafik21:'mA Input Sensor',
    ozet_grafik22:'Noise Sensor',
    ozet_grafik23:'Your transaction has been completed!',
    ozet_grafik24:'Error during operation',
    ozet_grafik25:'There is a problem with your limits ...',
    ozet_grafik26:'1h',
    ozet_grafik27:'8h',
    ozet_grafik28:'24h',
    ozet_grafik29:'1w',
    ozet_grafik30:'Check your internet connection for Device. ',
    ozet_grafik31:'The Upper Limit cannot be higher than 300!',
    ozet_grafik32:'Upper Limit cannot be less than -300!',
    ozet_grafik33:'The Upper Limit cannot be less than -300!',
    ozet_grafik35:'Lower Limit cannot be higher than 300!',
    ozet_grafik36:'The Lower Limit cannot be less than -300!',
    ozet_grafik37:'',
    ozet_grafik38:'',
    event_paragraph:'PAST EVENTS',
    event_paragraph1:'Copy',
    event_paragraph2:'Excel',
    event_paragraph3:'Print',
    event_paragraph4:'PDF',
    event_paragraph5:'CHOOSE CONDITION',
    event_paragraph6:'DEVICES',
    event_paragraph7:'Search Device',
    event_paragraph8:'Loading Data',
    event_paragraph9:'CHOOSE SENSOR',
    event_paragraph10:'Battery',
    privacy_paragraph1:'This website is affiliated with EDS Elektronik Destek Sanayi ve Ticaret Ltd. Şti. (We), thus it is operated by the company and the material that is contained at the website  are protected by the international copyright and trademark laws. EDS Elektronik Destek San. Tic. Ltd. Sti. (We) are committed to protecting and respecting your privacy.',
    privacy_paragraph2:'Purpose of this privacy notice',
    privacy_paragraph3:'This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting below websites and applications you are accepting and consenting to the practices described in this policy. For the purpose of the Data Protection Act 1998 (the Act), the data controller is EDS Elektronik Destek San. Tic. Ltd. Sti. www.isense.com.tr and eSense related website ',
    privacy_paragraph4:'Information we collect from you',
    privacy_paragraph5:'"We will collect and process the following data about you: Information we collect about you. With regard to each of your visits to our site we will automatically collect the following information: technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), products you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer service number. Information we receive from other sources. This is information we receive about you if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data if we intend to share those data internally and combine it with data collected on this site. We will also have told you for what purpose we will share and combine your data. We are working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.',
    privacy_paragraph6:'Cookies',
    privacy_paragraph7:'Our websites and mobile applications use cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and mobile application and also allows us to improve our site. We use information held about you in the following ways: Information you give to us.We will use this information: to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us; to provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about; to notify you about changes to our service; to ensure that content from our site is presented in the most effective manner for you and for your computer. Information we collect about you.We will use this information: to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes; to improve our site to ensure that content is presented in the most effective manner for you and for your computer; to allow you to participate in interactive features of our service, when you choose to do so; as part of our efforts to keep our site safe and secure; to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them. Information we receive from other sources. We will combine this information with information you give to us and information we collect about you. We will use this information and the combined information for the purposes set out above (depending on the types of information we receive).',
    privacy_paragraph8:'Disclosure of your information',
    privacy_paragraph9:'You agree that we have the right to share your personal information with: Any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries. Selected third parties including: business partners, suppliers and sub-contractors for the performance of any contract we enter into with [them or] you; credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you. We will disclose your personal information to third parties: In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets.',
    privacy_paragraph10:'Where we store your personal data',
    privacy_paragraph11:'The data that we collect from you will be transferred to, and stored at, a destination outside the European Economic Area (\'EEA\'). It will also be processed by staff operating outside of the EEA who work for us or for one of our suppliers. This includes staff engaged in, among other things,  the fulfilment of your order, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy. All information you provide to us is stored on secure servers. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.',
    privacy_paragraph12:'Your rights',
    privacy_paragraph13:'You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at eds@eds.com.tr. Our site may, from time to time, contain links to and from the website  of our partner networks, advertisers and affiliates. If you follow a link to any of these websites and mobile applications, please note that these websites and mobile applications have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.',
    privacy_paragraph14:'Access to information',
    privacy_paragraph15:'The GDPR gives you the right to access information held about you. Your right of access can be exercised in accordance with the GDPR. Please contact eds@eds.com.tr',
    privacy_paragraph16:'Changes on our privacy policy',
    privacy_paragraph17:'Any changes we make to our privacy policy in the future will be posted on this page. Please check back frequently to see any updates or changes to our privacy policy.',
    privacy_paragraph18:'Contact',
    privacy_paragraph19:'Questions, comments and requests regarding this privacy policy are welcomed and should be addressed to eds@eds.com.tr',
    privacy_paragraph20:'Privacy Policy',
    privacy_paragraph21:'',
    privacy_paragraph22:'',
    site_map:'Frequently Asked Questions',
    site_map1:'Contact',
    site_map2:'About Us',
    site_map3:'Buy',
    site_map4:'Technical Support',
    site_map5:'Privacy Policy',
    site_map6:'Account',
    site_map7:'Settings',
    site_map8:'Camera',
    site_map9:'Past Events',
    site_map10:'Detailed Report',
    site_map11:'Devices',
    site_map12:'Site Map',

    copy:{
        p1:'Paragraph with <b>html</b> inside',
        p2:'Paragraph with %(link)s'    
    },
    link:'Link'
}