import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import Select from "react-select";
import en from './lang/en'
import tr from './lang/tr'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useParams
  } from "react-router-dom";

counterpart.registerTranslations('tr',tr);
counterpart.registerTranslations('en',en);


// if (navigator.language==='tr-TR') {
// 	counterpart.setLocale('tr')
// }else {
// 	counterpart.setLocale('en')
// }
localStorage.removeItem('ref',0)
var hours = 1; // Reset when storage is more than 24hours
		var now = new Date().getTime();
		var setupTime = localStorage.getItem('setupTime');
		if (setupTime == null) {
			localStorage.setItem('setupTime', now)
		} else {
			if(now-setupTime > hours*60*60*1000 && localStorage.getItem('remem')) {
				localStorage.clear()
				localStorage.setItem('setupTime', now);
			}
		}
localStorage.setItem('setupTime', new Date().getTime())

class Header extends Component {
	

	state={
        lang:this.setState({lang:'en'}),
        
        }
    onLangChange=(e)=>{
		
		(function()
		{


		if( window.localStorage )
		{
			if( !localStorage.getItem('ref') )
			{
			localStorage['ref'] = 0;
			window.location.reload();
			}  
			else
			localStorage.removeItem('ref');
		}
		})();

		console.log(e.target.value);

			// this.setState({
			// 	lang:e.target.value
			// });

       if (localStorage.getItem('lang')) {
            localStorage.removeItem('lang')
            localStorage.setItem('lang',e.target.value)
            this.setState({lang:e.target.value})
            counterpart.setLocale(e.target.value)
       }else{
            this.setState({lang:e.target.value})
            localStorage.setItem('lang',e.target.value)
            counterpart.setLocale(e.target.value)
       }
        // this.setState({lang:e.target.value})
        // counterpart.setLocale(e.target.value)
	}

	onClickk=(e)=>{
		
		(function()
		{


		if( window.localStorage )
		{
			if( !localStorage.getItem('ref') )
			{
			localStorage['ref'] = 0;
			window.location.reload();
			}  
			else
			localStorage.removeItem('ref');
		}
		})();

		console.log(e);

			// this.setState({
			// 	lang:e.target.value
			// });

       if (localStorage.getItem('lang')) {
            localStorage.removeItem('lang')
            localStorage.setItem('lang',e)
            this.setState({lang:e})
            counterpart.setLocale(e)
       }else{
            this.setState({lang:e})
            localStorage.setItem('lang',e)
            counterpart.setLocale(e)
       }
        // this.setState({lang:e.target.value})
        // counterpart.setLocale(e.target.value)
	}



	componentWillMount(){
		
		var which=localStorage.getItem('lang')
        if (which==='tr') {
            this.setState({lang:'tr'})
            counterpart.setLocale('tr')
        }else{
            this.setState({lang:'en'})
            counterpart.setLocale('en')
        }
	}



	constructor(props) {
		super(props);
		this.state={Login:this.props.Login};

	}
	handleLogout() {

			try {
				Auth.signOut();
				this.props.auth.setAuthStatus(false);
				  this.props.auth.setUser(null);
				  sessionStorage.setItem("Login","0");
						// window.location.href="/Login"

			} catch(error) {
				console.log(error.meesage);
			}
		}

  render(){
// console.clear();
// console.log("Header Login : "+this.props.Login);
  if(this.props.auth.isAuthenticated)
	{
	return (

		<div>
		<nav className="navbar navbar-default navbar-static-top m-b-0">
	<div className="navbar-header">
		<ul className="nav navbar-top-links navbar-left pull-left">
			<li><a  className="open-close waves-effect waves-light visible-xs"><i className="ti-close ti-menu"></i></a></li>
		</ul>
		<div className="top-left-part m-r-90">
			{/* <a href="/Dashboard"> */}
			<Link to="/Dashboard" className="light-logo">
				<div className="light-logo">
					<img src="plugins/images/esense-logo.png" title="" alt="" />
				</div>
				<div className="small-logo">
					<img src="plugins/images/esense-logo-small.png" title="" alt="" />
				</div>
				</Link>
			{/* </a>  */}
		</div>
		<div className="sidebar pull-left" role="navigation">
			<div className="sidebar-nav">
				<div className="sidebar-head">
					<h3><span className="fa-fw open-close"><i className="ti-menu hidden-xs"></i><i className="ti-close visible-xs"></i></span> <span className="hide-menu"><img src="plugins/images/esense-logo-small-nav.png" title="" alt="" /></span></h3>
				</div>
				<ul className="nav" id="side-menu">
				{/* <li><Link  to="/Dashboard"><div><span className="hide-menu"><Translate content="header_paragraph6"  /></span></div></Link></li> */}
				{/* <li><a className="waves-effect"><Link  to="/Dashboard"><div><span className="hide-menu"><Translate content="header_paragraph4"  /></span></div></Link></a></li> */}
				<li><Link  to="/Dashboard" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph4"  /></span></Link></li>
					{/* <li><a href="/Dashboard" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph4"  /></span></a></li> */}
					
					{/* <li><a className="waves-effect"><Link  to="/CanliKamera"><div><span className="hide-menu">CANLI KAMERA </span></div></Link></a></li> */}
					
					{/* <li><Link  to="/CanliKamera" ><div><span  className="hide-menu"><a className="waves-effect">CANLI KAMERA</a></span></div></Link></li> */}
					{/* <li><a href="/CanliKamera" className="waves-effect"><span className="hide-menu">CANLI KAMERA</span></a></li> */}
					<li><Link  to="/CanliKamera" className="waves-effect"><span className="hide-menu">CANLI KAMERA</span></Link></li>
					{/* <li><a href="/canli_kamera" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph8"  /></span></a></li> */}
					<li>
						<a className="waves-effect"><Link  to="/olaylar"><div><span className="hide-menu"><Translate content="header_paragraph9"  /> <span className="fa fa-angle-down"></span></span></div></Link></a>
						
					{/* <li><a href="/olaylar" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph9"  /> <span className="fa fa-angle-down"></span></span></a> */}
						<ul className="nav nav-second-level">
							{/* <li><a className="waves-effect"><Link to="/olaylar"><span className="hide-menu"><Translate content="header_paragraph10"  /></span></Link></a></li> */}
							<li><Link  to="/olaylar" className="settingss"><span className="hide-menu"><Translate content="header_paragraph10"  /> </span></Link></li>
							<li><Link  to="/rapor" className="settingss"><span className="hide-menu"><Translate content="header_paragraph11"  /> </span></Link></li>
							{/* <li><a href="/olaylar" className="settingss"><span className="hide-menu"><Translate content="header_paragraph10"  /></span></a></li> */}
							{/* <li><a href="/rapor" className="settingss"><span className="hide-menu"><Translate content="header_paragraph11"  /></span></a></li> */}

						</ul>
					</li>
					<li>
					<a className="waves-effect"><Link  to="/ayarlar"><div><span className="hide-menu"><Translate content="header_paragraph12"  /> <span className="fa fa-angle-down"></span></span></div></Link></a>
						{/* <a href="/ayarlar" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph12"  /> <span className="fa fa-angle-down"></span></span></a> */}
						<ul className="nav nav-second-level">
							{/* <li><a href="/ayarlar" className="settings"><span className="hide-menu"><Translate content="header_paragraph13"  /></span></a></li> */}
							<li><Link  to="/ayarlar" className="settings"><span className="hide-menu"><Translate content="header_paragraph13"  /> </span></Link></li>
							{/* <li><a href="/gelismis_ayarlar" className="settings"><span className="hide-menu"><Translate content="header_paragraph14"  /></span></a></li> */}
							
							
						</ul>
					</li>
					

					

				{/* <li className="dropdown">
				<a className="dropdown-toggle waves-effect waves-light" data-toggle="dropdown" href="/#"><i className="mdi mdi-bell"></i></a>
				<ul className="dropdown-menu mailbox">
					<li>
						<div className="drop-title">Toplam 1 bildirim var.</div>
					</li>
					<li>
						<div className="message-center">
							<a href="/#">
								<div className="mail-contnet">
									<h5>Admin</h5><span className="mail-desc">Yeni bir bildirim var</span> <span className="time">9:30 PM</span>
								</div>
							</a>
						</div>
					</li>
				</ul>
			</li> */}
				
			{/* <li className="dropdown">
				<a className="waves-effect" data-toggle="dropdown" href="" ><span className="hide-menu"><i className="mdi mdi-account user-account-border"></i> <span className="fa fa-angle-down"></span></span></a>
				<ul className="nav nav-second-level">
				<li><a href="/hesap" className="settings"><span className="hide-menu">Hesap</span></a></li> 
				<li><a href="/hesapduzenle" className="settings"><span className="hide-menu">Hesabı düzenle</span></a></li>
				<li><a href="" onClick={()=>{this.handleLogout()}} className="settings"><span className="hide-menu">Oturumu Kapat</span></a></li>
				</ul>
				</li> */}
				


				</ul>
			</div>
		</div>
		<ul className="nav navbar-top-links navbar-right pull-right mini-right-bar" >



		
			{/* <li className="dropdown">
				<a className="dropdown-toggle waves-effect waves-light" data-toggle="dropdown" href="/#"><i className="mdi mdi-bell"></i></a>
				<ul className="dropdown-menu mailbox">
					<li>
						<div className="drop-title">Toplam 1 bildirim var.</div>
					</li>
					<li>
						<div className="message-center">
							<a href="/#">
								<div className="mail-contnet">
									<h5>Admin</h5><span className="mail-desc">Yeni bir bildirim var</span> <span className="time">9:30 PM</span>
								</div>
							</a>
						</div>
					</li>
				</ul>
			</li> */}
			
			{/* <li className="dropdown">
				<a className="waves-effect" data-toggle="dropdown" href="" ><span className="hide-menu"><i className="mdi mdi-account user-account-border"></i> <span className="fa fa-angle-down"></span></span></a>
				<ul className="nav nav-second-level">
				<li><a href="/hesap" className="settings"><span className="hide-menu">Hesap</span></a></li> 
				<li><a href="/hesapduzenle" className="settings"><span className="hide-menu">Hesabı düzenle</span></a></li>
				<li><a href="" onClick={()=>{this.handleLogout()}} className="settings"><span className="hide-menu">Oturumu Kapat</span></a></li>
				</ul>
			</li> */}
			<li className="dropdown">
				<a className="dropdown-toggle" data-toggle="dropdown" href="/#"><i className="mdi mdi-account user-account-border"></i> <span className="caret"></span></a>
				<ul className="dropdown-menu dropdown-user">
					<li><Link  to="/hesap" >{localStorage.getItem('lang')=='tr'?'Hesap':'Account'}</Link></li>
					<li><Link  to="/hesapduzenle" >{localStorage.getItem('lang')=='tr'?'Hesabı Düzenle':'Edit Account'}</Link></li>
					{/* <li><a href="/hesap"><div>{localStorage.getItem('lang')=='tr'?'Hesap':'Account'}</div></a></li> */}
					{/* <li><a href="/hesapduzenle"><div>{localStorage.getItem('lang')=='tr'?'Hesabı Düzenle':'Edit Account'}</div></a></li> */}
					{/* <li><a  onClick={()=>{this.handleLogout()}}><div>{localStorage.getItem('lang')=='tr'?'Oturumu Kapat':'Log Out'}</div></a></li> */}
					<li><Link onClick={()=>{this.handleLogout()}} to="/Login"><div>{localStorage.getItem('lang')=='tr'?'Oturumu Kapat':'Log Out'}</div></Link></li>
				</ul>
			</li>

			<li className="dropdown hidden-xs">
		<a className="dropdown-toggle profile-pic" data-toggle="dropdown"  href="/#"  ><span value={this.state.lang}>{(localStorage.getItem('lang')).toUpperCase()}</span><span className="caret"></span></a>
					  <ul className="dropdown-menu dropdown-user">
						  <li><a type="button" onClick={()=>{this.onClickk('tr')}} value="tr"><img src="plugins/images/flag/tr.png" width="20" title="" alt="" /> TR</a></li>
						  <li><a  type="button" onClick={()=>{this.onClickk('en')}} value="en"><img src="plugins/images/flag/en.png" width="20" title="" alt="" /> EN</a></li>
					  </ul>
					  </li>


			{/* <li >
			<select className="dropdown-toggle selectpicker " onChange={this.onLangChange} style={{minWidth:'25px',minHeight:'30px',color:'#1862cf',backgroundColor:'#fefefe'}}  value={this.state.lang} >
								<option value="tr">TR</option>
								<option value="en">EN</option>

						</select>
						</li> */}
			
		</ul>
		
		
		{/* <div className="pull-right">
			<form id="sb-search" className="sb-search">
				<input type="search" name="search" className="sb-search-input" placeholder="Aranacak kelime..." />
				<span className="sb-icon-search"><i className="fa fa-search"></i></span>
				<span className="sb-icon-close"><a href=""><i className="ti-close"></i></a></span>
			</form>
		</div> */}

				



	</div>
</nav>
	  </div>

		);
  }
  else{

	return(

<div>

			<nav className="navbar navbar-default navbar-static-top m-b-0">
		  <div className="navbar-header">
			  <ul className="nav navbar-top-links navbar-left pull-left">
				  <li><a  className="open-close waves-effect waves-light visible-xs"><i className="ti-close ti-menu"></i></a></li>
			  </ul>
			  <div className="top-left-part m-r-90">
				  <a href="/Login">
					  <div className="light-logo">
						  <img src="plugins/images/eds-logo.png" title="" alt="" />
					  </div>
					  <div className="small-logo p-t-5">
						  <img src="plugins/images/eds-logo.png" title="" alt="" />
					  </div>
				  </a>
			  </div>
			  <div className="sidebar pull-left" role="navigation">
				  <div className="sidebar-nav">
					  <div className="sidebar-head">
						  <h3><span className="fa-fw open-close"><i className="ti-menu hidden-xs"></i><i className="ti-close visible-xs"></i></span> <span className="hide-menu"><img src="plugins/images/eds-logo-small-nav.png" title="" alt="" /></span></h3>
					  </div>
					  <ul className="nav" id="side-menu">
					  		<li><a  href="/Hakkimizda" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph"  /></span></a></li>
							  <li><a  target="_blank" href="https://www.eds.com.tr" rel="noopener noreferrer" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph1"  /></span></a></li>
							  <li><Link  to="/TeknikDestek" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph2"  /></span></Link></li>
							  <li><a href="/Iletisim1" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph3"  /></span></a></li>
							  {/* <li><Link  to="/Iletisim1" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph3"  /></span></Link></li> */}
							  <li><Link  to="/SikcaSorulanSorular" className="waves-effect"><span className="hide-menu"><Translate content="footer_paragraph5"  /></span></Link></li>
						  {/* <li><a href="/Hakkimizda" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph"  /></span></a></li>
						  
						  <li><a target="_blank" href="http://www.eds.com.tr" rel="noopener noreferrer" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph1"  /></span></a></li>
						  <li><a href="/TeknikDestek" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph2"  /></span></a></li>
						  <li><a href="/Iletisim" className="waves-effect"><span className="hide-menu"><Translate content="header_paragraph3"  /></span></a></li>
						  <li><a href="/SikcaSorulanSorular" className="waves-effect"><span className="hide-menu"><Translate content="footer_paragraph5" /></span></a></li> */}
					  </ul>
				  </div>
			  </div>
			  <ul className="nav navbar-top-links navbar-right pull-right mini-right-bar">
				  <li className="dropdown">
					  {/* <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="/#"><span className="lang">TR</span> <span className="caret"></span></a> */}
					  {/* <ul className="dropdown-menu dropdown-user">
						  <li><a href="language.html"><img src="plugins/images/flag/tr.png" width="20" title="" alt="" /> TR</a></li>
						  <li><a href="language.html"><img src="plugins/images/flag/en.png" width="20" title="" alt="" /> EN</a></li>
					  </ul> */}


				<ul className="nav navbar-top-links navbar-right pull-right mini-right-bar">
				  <li className="dropdown">
				  <a className="dropdown-toggle profile-pic" data-toggle="dropdown"  href="/#"  ><span value={this.state.lang}>{(localStorage.getItem('lang')).toUpperCase()}</span><span className="caret"></span></a>
					  <ul className="dropdown-menu dropdown-user">
						  <li><a  type="button" onClick={()=>{this.onClickk('tr')}} value="tr"><img src="plugins/images/flag/tr.png" width="20" title="" alt="" /> TR</a></li>
						  <li><a  type="button" onClick={()=>{this.onClickk('en')}} value="en"><img src="plugins/images/flag/en.png" width="20" title="" alt="" /> EN</a></li>
					  </ul>
					  </li>
			  	</ul>


							<li>
						{/* <select  value={this.state.lang} onChange={this.onLangChange}  className=" selectpicker"   > */}
						
					
						


						{/* <select onChange={this.onLangChange} style={{minWidth:'25px',minHeight:'30px',color:'#1862cf',backgroundColor:'#fefefe'}} className="selectpicker"   value={this.state.lang} >
								<option value="tr">TR</option>
								<option value="en">EN</option>

						</select> */}
						{/* <Select onChange={this.onLangChange} options={options}  value="en"  placeholder={"EN"} /> */}
						{/* <option value="tr" >TR</option>
						<option value="en" >EN</option> */}
						{/* </select> */}
					</li>
				  </li>
			  </ul>
		  </div>
		</nav>



		  </div>

	);
}

}
}



export default Header;
