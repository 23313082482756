import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import Axios from "axios";
import Grafik from '../Components/OzetGrafik';
import Translate from 'react-translate-component'

function SensorsGrafs(props) {
    var sensArray = null;
    const aa = [1, 2, 3];
    // console.log(aa);
    // console.log("in SensorsGrafs");
    sensArray = props.sensArr;
    const listItems = sensArray.map((n, index) =>
        <Grafik key={index} sensor={n.adres} CihazID={props.devid} sensad={n.ad}/>
    );
    // console.log(listItems);
    return (
        listItems
    );
}

class Graphs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sensArray: [],
            reload: true
        };
    }

    // deneme ali2
    loadSenseArr(devid) {
		Auth.user.getSession((err, session) => {
			if (err) {
				throw new Error(err);
			}

			const sessionToken = session.getIdToken().jwtToken;

			const postMessage = {
				"msgtype": "getdevs",
				"devId": devid
			};

			Axios.post("https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod", postMessage, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': sessionToken
				},
				// body: ''
			}).then((res) => {
				var tt = [];
				for (var i = 0; i < res.data.Items[0].devConfigs.sensNo; i++) {
					// console.log(i);
					// this.state.sensArray.push( i+1
					// {
					// 	"index": i + 1
					// }
                    // );
                    // console.log(res.data.Items[0].payload.sensorveri[i].altkan);
                    // tt = tt.concat(res.data.Items[0].payload.sensorveri[i].altkan);
                    tt.push({
                        "adres": res.data.Items[0].payload.sensorveri[i].altkan,
                        "ad": res.data.Items[0].payload.sensorveri[i].ad
                    })
                }
                // console.log("sensArray inside loadsens");
                // console.log(tt);
				this.setState({sensArray: tt});
                // console.log(this.state.sensArray);

				// console.log(tt);
				// this.state.sensArray = tt;
				

			}).catch(error => {
				console.log("Error : " + error);
			});

		});
    }
    
    componentDidMount() {
        this.loadSenseArr(this.props.deviceid);
        // console.log("ok");
        // this.setState({sensArray: [1, 2]})
        // console.log(this.props);
        // console.log(this.state);
    }

    render() {
        return (
            <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="row">

                    {
                        // (this.props.userinfo !== undefined) &&
                        (this.state.sensArray !== null) &&
                        (this.state.sensArray.length > 0) &&
                        
                        <SensorsGrafs sensArr={this.state.sensArray} devid={this.props.deviceid} />
                    }

                    {
                        // (this.state.sensArray !== null) &&
                        // (this.state.sensArray.length === 0) &&
                        // <div><Translate content="account_paragraph21"  /></div>
                    }
                </div>
            </div>
        );
    }

}

export default Graphs;