import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";



class PdfExcelEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: []
        };

    }

    editKul = (kul) => {
        this.setState({kulduzenlebuttonvisible: "hidden"});
        var tkulsevLabel;
        switch (kul.yetkisev) {
            case "sup":
                tkulsevLabel = "Super admin";
                break;
            case "oper":
                tkulsevLabel = "Operatör";
                break;
            case "izle":
                tkulsevLabel = "izleyici";
                break;
            case "adm":
                tkulsevLabel = "admin";
                break;
            default:
                break;
        }
        this.setState({
            duzenlenecekuserid: kul.userid,
            duzenlenecekadsoyad: kul.adsoyad,
            duzenlenecekemail: kul.usermail,
            duzenlenecektelefon: kul.usertel,
            duzenleneceyetkisevLable: tkulsevLabel,
            duzenleneceyetkisevValue: kul.yetkisev
        })
    };

    deleteKul = (kulbilgi) => {
        if (window.confirm("Bu kullanıcı bu cihazdan silinecek, emin misiniz?")) {
            this.setState({
                silinecekKulid: kulbilgi.userid
            });
            this.kuldelPOST(kulbilgi.userid);

        } else {
            console.log("no ok");
        }

    };

   


    bucihazausertable() {
        let evlist = [];
        if (this.props.eventlist) {
            for (var i = 0; i < this.props.eventlist.length; i++) {
                evlist.push(this.props.eventlist[i]);
                
            }
        }
        

        return evlist;
    }


    tableraw(list,fdrm, fsnsr) {
        let list1 = [];
        if (fdrm == -1 && fsnsr == -1) {
            list1 = list;
        }
        if (fdrm != -1) {
            for (var i = 0; i < list.length; i++) {
                if (fdrm == "Alarm") {
                    if ((list[i].event_t == "ustAlarm") || (list[i].event_t == "altAlarm")) {
                        list1.push(list[i]);
                    }
                } else if (fdrm == "Hata") {
                    if ((list[i].event_t == "pilHata") || (list[i].event_t == "comHata")) {
                        list1.push(list[i]);
                    }
                } else if (fdrm == "Normal") {
                    if (list[i].event_t == "normal") {
                        list1.push(list[i]);
                    }
                }
            }
        }

        if (fsnsr != -1) {
            for (var i = 0; i < list.length; i++) {
                if (fsnsr == list[i].sensor_name) {
                        list1.push(list[i]);
                    }
            }
        }

        let evlist1 = list1.map((ev, index) => {
            return (
                <tr key={index}>
                    <td>{ev.event_time_readable.replace("T", " / ")}</td>
                    <td>
                    {((ev.event_t == "altAlarm") || (ev.event_t == "ustAlarm"))  && "Alarm"}
                    {((ev.event_t == "pilHata") || (ev.event_t == "comHata")) && "Hata"}
                    {(ev.event_t == "normal") && "Normal"}
                    
                    </td>
                    <td>{ev.device_name}</td>
                    <td>{ev.sensor_name}</td>
                    <td>{ev.alt_limit}</td>
                    <td>{ev.ust_limit}</td>
                    <td>{ev.event_descript}</td>                    
                </tr>
            )
        });
        return evlist1.reverse();
    }



    

    



    render() {


        let options;
        if (this.props.kulsearch.length) {
            const searchPattern = new RegExp(this.props.kulsearch.map(term => `(?=.*${term})`).join(''), 'i');
            options = this.bucihazausertable().filter(option =>
                option.searchfield.match(searchPattern)
            );
        } else {
            options = this.bucihazausertable();
        }

        return (
            <div>
                <table id="example23" class="display nowrap table color-table muted-table table-hover text-center" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>TARİH/SAAT</th>
                            <th>DURUM</th>
                            <th>CİHAZ ADI</th>
                            <th>SENSOR ADI</th>
                            <th>ALT LİMİT</th>
                            <th>ÜST LİMİT</th>
                            <th>OLAY DETAYI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.tableraw(options, this.props.filterdrm, this.props.filtersensor)}
                    </tbody>
                </table>

                
            </div>
        );
    }
}

export default PdfExcelEvent;