import React, { Component } from 'react';
import Switch from '../utility/Switch';

class Agayarlar extends Component {
    state = {
        dhcp: true,
        autoconfig: true,
        ipaddressautomatic: true,
        booth: false,
        autonegotiate: true,
        netspeed100: true,
        netspeed10: false,
        dublexfull: true,
        dublexhalf: false,
        gratuitousarpbroadcast: false,
        ipaddressconfiguration: false,

    };

    render() {
        return (
            <div class="white-box">
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <h3 class="m-t-0 left-border">AĞ AYARLARI</h3>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 m-t-30">
                        <div class="row">
                            <div class="col-md-6 col-sm-12 col-xs-12 m-b-30">
                                <h4 class="m-t-0 m-b-30">IP Adress Configuration</h4>
                                <div class="form-horizontal">
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Obtain IP Adress Automatically:</label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.ipaddressautomatic}
                                                handleToggle={() => this.setState({ ipaddressautomatic: !this.state.ipaddressautomatic })}
                                                chid="check-ipaddressautomatic"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Auto Configuration:</label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.autoconfig}
                                                handleToggle={() => this.setState({ autoconfig: !this.state.autoconfig })}
                                                chid="check-autoconfig"
                                            />
                                            <span class="font-normal">&nbsp;&nbsp;&nbsp;&nbsp;AutoIP</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Methods:</label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.dhcp}
                                                handleToggle={() => this.setState({ dhcp: !this.state.dhcp })}
                                                chid="check-dhcp"
                                            />
                                            <span class="font-normal">&nbsp;&nbsp;&nbsp;&nbsp;DHCP</span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label"></label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.booth}
                                                handleToggle={() => this.setState({ booth: !this.state.booth })}
                                                chid="check-booth"
                                            />
                                            <span class="font-normal">&nbsp;&nbsp;&nbsp;&nbsp;BOOTH</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                                <h4 class="m-t-0 m-b-30">IP Adress Configuration</h4>
                                <div class="form-horizontal">
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">MTU Size:</label>
                                        <div class="col-sm-7">
                                            <input type="text" name="mtu" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Auto Negotiate:</label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.autonegotiate}
                                                handleToggle={() => this.setState({ autonegotiate: !this.state.autonegotiate })}
                                                chid="check-autonegotiate"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Speed:</label>
                                        <div class="col-sm-7">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-6">
                                                    <Switch
                                                        isOn={this.state.netspeed100}
                                                        handleToggle={() => this.setState({ netspeed100: !this.state.netspeed100 })}
                                                        chid="check-netspeed100"
                                                    />
                                                    <span class="font-normal">&nbsp;&nbsp;100 Mbps</span>
                                                </div>
                                                <div class="col-sm-6 col-xs-6">
                                                    <Switch
                                                        isOn={this.state.netspeed10}
                                                        handleToggle={() => this.setState({ netspeed10: !this.state.netspeed10 })}
                                                        chid="check-netspeed10"
                                                    />
                                                    <span class="font-normal">&nbsp;&nbsp;10 Mbps</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Dublex:</label>
                                        <div class="col-sm-7">
                                            <div class="row">
                                                <div class="col-sm-6 col-xs-6">
                                                    <Switch
                                                        isOn={this.state.dublexfull}
                                                        handleToggle={() => this.setState({ dublexfull: !this.state.dublexfull })}
                                                        chid="check-dublexfull"
                                                    />
                                                    <span class="font-normal">&nbsp;&nbsp;Full</span>
                                                </div>
                                                <div class="col-sm-6 col-xs-6">
                                                    <Switch
                                                        isOn={this.state.dublexhalf}
                                                        handleToggle={() => this.setState({ dublexhalf: !this.state.dublexhalf })}
                                                        chid="check-dublexhalf"
                                                    />
                                                    <span class="font-normal">&nbsp;&nbsp;Half</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Gratuitous ARP Boardcast:</label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.gratuitousarpbroadcast}
                                                handleToggle={() => this.setState({ gratuitousarpbroadcast: !this.state.gratuitousarpbroadcast })}
                                                chid="check-gratuitousarpbroadcast"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12 m-t-30">
                                <div class="form-horizontal">
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">
                                            <h4 class="m-t-0 m-b-10 pull-left">IP Adress Configuration</h4>
                                        </label>
                                        <div class="col-sm-7">
                                            <Switch
                                                isOn={this.state.ipaddressconfiguration}
                                                handleToggle={() => this.setState({ ipaddressconfiguration: !this.state.ipaddressconfiguration })}
                                                chid="check-ipaddressconfiguration"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Obtain IP Adress Automatically:</label>
                                        <div class="col-sm-7">
                                            <input type="text" name="obtain_ip" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Auto Configuration:</label>
                                        <div class="col-sm-7">
                                            <input type="text" name="auto_configuration" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-5 control-label">Methods:</label>
                                        <div class="col-sm-7">
                                            <input type="text" name="methods2" class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Agayarlar;