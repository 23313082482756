import React, { Component } from 'react';
import axios from 'axios'
import { Auth } from "aws-amplify";
import { Route } from 'react-router-dom';
import $ from 'jquery'

class CanliKamera extends Component {

      
    constructor (props){
        super(props);
        this.state={
            streamUrl:[],
            flag:[],
            streamName:'',
            camName:'',
            arnVideo:'',
            showForm:false
        }
    }


    onInputChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
        // document.getElementById(event.target.id).classList.remove("is-danger");
        
      }

      refreshSubmit(){
        // return href=window.location.href
         
      }
      showForm = () => {
        return ( <div > 
               <div id="wrapper" style={{width:"40%",height:"%40",margin:"0px auto",textAlign:"center",top:"50%",left:"50%"}}>
               
                                        <div className="white-box m-b-0">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <h3 className="m-t-0 m-b-30 left-border">Kamera Ekle</h3>
                                                </div>
                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <form action="" data-toggle="validator">

                                                        <div className="form-horizontal">
                                                        <form id="loginform" onSubmit={this.handleSubmit}>
                                                        <h3 >Yeni Kamera Eklemek İçin RTSP ve Kamera Adı Bilgileri Giriniz</h3>
                                                        {/* <div className="form-group">
                                                        <input placeholder="Arn linki" value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange}  ></input>
                                                        
                                                        </div> */}
                                                        <div className="form-group">
                                                        <input placeholder="Stream Adı"  value={this.state.streamName} id="streamName" className="form-control" onChange={this.onInputChange} required ></input>
                                                        
                                                        </div>
                                                        <div className="form-group">
                                                        <input placeholder="RTSP Linki"  value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange} required  ></input>
                                                        
                                                        </div>
                                                        <div className="form-group">
                                                        <button type="submit" style={{backgroundColor:"#000"}} id="btnRegisterCam" class="btn btn-info btn-lg btn-block waves-effect waves-light">Kaydet</button>
                                                        
                                                        </div>
                                                        <h5>* Kamera Görüntüsü İzlemek İçin Internet Explorer 11 Sürümünün Yüklü olması gerekir, VLC media player'ın 64 bit versiyonu desteklenmiyordur.</h5>


                                                        </form>
                                                            
                                                            
                                                         
                                                            <div className="form-group">
                                                                {this.state.kuleklealertShow && this.ShowMessage()}
                                                            </div>
                                                        </div>




                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                            
           </div>
          );
      }

      handleSubmit = async event => {
        event.preventDefault();
    
      await  Auth.user.getSession((err, session) => {
            const accessTok=Auth.user.signInUserSession.accessToken.jwtToken;
            const sessionToken = session.getIdToken().jwtToken;
            
            axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod',
            
            {
              
              streams:{
                "msgtype": "getUserStreamInfo",
                "streamName": this.state.streamName,
                "userId": this.props.userinfo.userId,
                "arnVideo":this.state.arnVideo
              }
              
              
              }, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionToken
                      },
                    }).then(response => {
                      // const parsedRes=JSON.parse(response)
                  console.log('response', response); //api error
                  console.log('response', response.status);
                  if (response.status=='200') {
                    alert('Kamerayı başarıyla eklediniz')
                  }
                  this.refreshSubmit()
                }).catch(error => {
                  console.log('api error ', +error); //api error
               });
            })

      }



    componentDidMount(){


    
      this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
       
            // axios.get('https://pg6dms2h4j.execute-api.eu-west-1.amazonaws.com/frb/stream',).then(response=>{
            //     console.log(response.data);
            //     console.log(response.data.errorMessage);
            //     if (response.data.errorMessage) {
            //         this.setState({flag:response.data.url})
            //     }
            //     console.log(response.data.url);
            //     this.setState({streamUrl:response.data.url})
            // }).catch(error=>{
            //     console.log(error);
            // })

    }
 

   
       
    
    
    
    render() {
       
        // {console.log(this.state.streamUrl)}
        // this.getuserInfos();
        var arr =[]
        var arr2=[]
        if (this.props.userinfo!==undefined) {
          arr.push(this.props.userinfo.streams)
        }
        arr2=arr.flat(2)
        
       
        return (
              


          <div>
          {this.props.userinfo&&this.props.userinfo.authLevel==1?
          <div>
         
          {/* <h3>Kamera Görüntüsü İzlemek İçin Internet Explorer 11 Sürümünün Ve VLC media player'ın 32 bit versiyonunun yüklü olması gerekir.</h3> */}
          {
            this.props.userinfo&&this.props.userinfo.streams!==undefined?
          <div>
<div style={{overflow:"hidden"}}>
            
            {/* <input data-target="#kameraEkleModal" style={{float:"right",clear:"left",marginTop:"5px",marginRight:"5px"}} data-toggle="modal" type="button" id="openBtn1" data-id="1" className="btn btn-info waves-effect waves-light" value="Kamera Ekle" /> */}
            </div>
            <div style={{"zIndex":"9999","position":"fixed"}}  className="modal fade" id="kameraEkleModal" tabIndex="-1" role="dialog" aria-labelledby="kameraEkleModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">

                            <button id={"CloseButtonKulEkle"} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <div id="wrapper">
                                <div className="white-box m-b-0">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <h3 className="m-t-0 m-b-30 left-border">Kamera Ekle</h3>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <form action="" data-toggle="validator">

                                                <div className="form-horizontal">
                                                <form id="loginform" onSubmit={this.handleSubmit}>
                                                <h3 style={{color:"#fff"}} >Yeni Kamera Eklemek İçin RTSP ve Kamera Adı Bilgileri Giriniz</h3>
                                                {/* <div className="form-group">
                                                <input placeholder="Arn linki" value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange}  ></input>
                                                
                                                </div> */}
                                                <div className="form-group">
                                                <input placeholder="Stream Adı"  value={this.state.streamName} id="streamName" className="form-control" onChange={this.onInputChange}  ></input>
                                                
                                                </div>
                                                <div className="form-group">
                                                <input placeholder="RTSP Linki"  value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange}  ></input>
                                                
                                                </div>
                                                <div className="form-group">
                                                <button type="submit" style={{backgroundColor:"#000"}} id="btnRegisterCam" class="btn btn-info btn-lg btn-block waves-effect waves-light">Kaydet</button>
                                                
                                                </div>
                                                <h5>* Kamera Görüntüsü İzlemek İçin Internet Explorer 11 Sürümünün Yüklü olması gerekir, VLC media player'ın 64 bit versiyonu desteklenmiyordur.</h5>


                                                </form>
                                                    
                                                    
                                                 
                                                    <div className="form-group">
                                                        {this.state.kuleklealertShow && this.ShowMessage()}
                                                    </div>
                                                </div>




                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{overflow:"hidden"}}>
            <button style={{float:"right",clear:"left",marginTop:"5px",marginRight:"5px"}} class="btn btn-info  waves-effect waves-light"  onClick={() => this.setState({showForm: true}) }>Kamera Ekle</button>
            </div>
            {this.state.showForm ? this.showForm() : null}
            
  {
    arr2.map((x,index)=>{
      return <div style={{"zIndex":"0","position":"relative","paddingBottom":"100px"}}  className="new-login-register"  key={index} >
        <div className="form-group">
        <button type="submit" style={{backgroundColor:"#000",float:"right"}} class="btn btn-info btn-lg  waves-effect waves-light">Kamerayı Sil</button>
         </div>
        <h1>{x.streamname}</h1>
        <object width="1000" height="720"   classid="clsid:9BE31822-FDAD-461B-AD51-BE1D1C159921"
      id="vlc_1"
      codebase="http://download.videolan.org/pub/videolan/vlc/last/win32/axvlc.cab">
      <param name="src" value={x.arnvideo} />
      <param name="autoplay" value="true" />
      <param name="toolbar" value="true" />
      <param width="640" height="480" />
    </object>
      </div> 
    })

    
  }
  

 
  

  
    </div>
    
    :
    
    
    <div>
    
        <section className="new-login-register">
        <div className="new-login-box">
        <div class="new-register-box-header" > </div>
        <div className="white-box"  style={{color:"#000",textAlign:"left"}}>
        <form action="/CanliKamera" id="loginform" onSubmit={this.handleSubmit}>
        <h2 style={{color:"#fff"}} >Kamera Bağlı Değil</h2>  
        <h3 style={{color:"#fff"}} >Yüklemek İçin Bilgileri Giriniz</h3>
        {/* <div className="form-group">
        <input placeholder="Arn linki" value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange}  ></input>
        
        </div> */}
        <div className="form-group">
        <input placeholder="Stream Adı"  value={this.state.streamName} id="streamName" className="form-control" onChange={this.onInputChange}  ></input>
        
        </div>
        <div className="form-group">
        <input placeholder="RTSP Linki"  value={this.state.arnVideo} id="arnVideo" className="form-control" onChange={this.onInputChange}  ></input>
        
        </div>
        <div className="form-group">
        <button type="submit" style={{backgroundColor:"#000"}} id="btnRegisterCam" class="btn btn-info btn-lg btn-block waves-effect waves-light">Kaydet</button>
        
        </div>
        <h5>* Kamera Görüntüsü İzlemek İçin Internet Explorer 11 Sürümünün Yüklü olması gerekir, VLC media player'ın 64 bit versiyonu desteklenmiyordur.</h5>
        </form>
        </div>
        </div>
        </section>
        


    </div>
 
}
</div>
:

<div>
{
    arr2.map((x,index)=>{
      return <div style={{"zIndex":"0","position":"relative"}}  className="new-login-register"  key={index} >
        {/* <div className="form-group">
        <button type="submit" style={{backgroundColor:"#000",float:"right"}} class="btn btn-info btn-lg  waves-effect waves-light">Kamerayı Sil</button>
         </div> */}
        <h1>{x&&x.streamname}</h1>
        <object width="1000" height="720" classid="clsid:9BE31822-FDAD-461B-AD51-BE1D1C159921"
      id="vlc_1"
      codebase="http://download.videolan.org/pub/videolan/vlc/last/win32/axvlc.cab">
      <param name="src" value={x&&x.arnvideo} />
      <param name="autoplay" value="true" />
      <param name="toolbar" value="true" />
      <param width="640" height="480" />
    </object>
      </div> 
    })

    
  }
</div>

}

        </div>

  
       
   

    
      
        
  
);

}

}

export default CanliKamera;