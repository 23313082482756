import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Translate from 'react-translate-component'

class Register extends Component {
  state = {
    referred: false,
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    name:"",
    family_name: "",
    firma: "",
    devid: "",
    kulsev: "",
    ustkulid: "",
    address: "",
    phone_number: "",
    postakodu: "",
    picture:"",
    mobil_cihaz_id: "-2",
    crypt_id: "-1",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    const { name,family_name, firma, devid, kulsev, ustkulid, address, postakodu, picture, phone_number, username, email, password, mobil_cihaz_id, crypt_id } = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          name: name,
          family_name: family_name,
          'custom:firma' : firma,
          address: address,
          phone_number: phone_number.split(' ').join(''),
          'custom:postakodu': postakodu,
          picture: picture,
          'custom:il': "", 
          'custom:ilce': "",
          'custom:ulke': "",
          'custom:devid': devid,
          'custom:kulsev': kulsev,
          'custom:ustkulid': ustkulid,
          'custom:mobil_cihaz_id': mobil_cihaz_id,
          'custom:crypt_id': crypt_id
        }
      });
      this.props.history.push("/welcome");
      console.log(signUpResponse);
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
    
  }

  componentDidMount() {
    var CryptoJS = require("crypto-js");
    if (this.props.match.params.devid) {
      this.setState({
        devid: CryptoJS.AES.decrypt(this.props.match.params.devid.split('aRtI').join('+').split('tAkSiM').join('/').split('EsIt').join('='), 'edsgizemi').toString(CryptoJS.enc.Utf8),
        referred: true,
        firma: CryptoJS.AES.decrypt(this.props.match.params.firma.split('aRtI').join('+').split('tAkSiM').join('/').split('EsIt').join('='), 'edsgizemi').toString(CryptoJS.enc.Utf8),
        kulsev: CryptoJS.AES.decrypt(this.props.match.params.kulsev.split('aRtI').join('+').split('tAkSiM').join('/').split('EsIt').join('='), 'edsgizemi').toString(CryptoJS.enc.Utf8),
        ustkulid: CryptoJS.AES.decrypt(this.props.match.params.ustkulid.split('aRtI').join('+').split('tAkSiM').join('/').split('EsIt').join('='), 'edsgizemi').toString(CryptoJS.enc.Utf8),
        email: this.props.match.params.email1 + "@" + this.props.match.params.email2,
        username: this.props.match.params.email1 + "@" + this.props.match.params.email2
      });

      
      
    }
  
    
    
  }

  render() {
        
    return (
      <div style={{ paddingTop:"10px"}}>
       
      <section className="new-login-register">
        
        <div className="new-login-box">
        <div class="new-register-box-header" > </div>
        <div className="white-box"  style={{color:"#000",textAlign:"left"}}>
          
          

          <form id="loginform" onSubmit={this.handleSubmit}>
          <div className="form-group">
              
          <Translate component="input"
           attributes={{placeholder:'register_account_paragraph'}} 
           value={this.state.name}
           aria-describedby="NameHelp"
           id="name"
            className="form-control " 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph1'}} 
           value={this.state.family_name}
           aria-describedby="NameHelp"
           placeholder="Soyadınız"
           id="family_name"
           type="text"
            className="form-control " 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph2'}} 
           value={this.state.firma}
           placeholder="Firma"
           type="text"
           aria-describedby="NameHelp"
           id="firma"
           disabled={this.state.referred?true:false}
            className="form-control " 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="textarea"
           attributes={{placeholder:'register_account_paragraph3'}} 
           value={this.state.address}
           placeholder="Adres"
           type="text"
           aria-describedby="NameHelp"
           id="address"
            className="form-control " 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
              <Translate component="input"
             attributes={{placeholder:'register_account_paragraph11'}} 
             value={this.state.devid}
             placeholder="Tekrar şifre"
             type="text"
             id="devid"
              className="form-control"
              onChange={this.onInputChange} />
                 </div>

            <div className="form-group">
              
               
               <PhoneInput  autoFormat="true" className="form-control" id="phone_number"
  country={'tr'}
  value={this.state.phone_number}
  onChange={phone_number => this.setState({ phone_number })}
/>
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph4'}} 
           value={this.state.postakodu}
           placeholder="Posta kodu"
           type="text"
           aria-describedby="NameHelp"
           id="postakodu"
            className="form-control" 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph5'}} 
           value={this.state.username}
           placeholder="Eposta"
           type="text"
           aria-describedby="NameHelp"
           id="username"
            className="form-control"
            disabled={this.state.referred?true:false} 
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph6'}} 
           value={this.state.email}
           placeholder="Tekrar eposta"
           type="email"
           aria-describedby="emailHelp"
           id="email"
            className="form-control"
            disabled={this.state.referred?true:false} 
            onChange={this.onInputChange} />
            
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph7'}} 
           value={this.state.password}
           placeholder="Şifre"
           type="password"
           id="password"
            className="form-control"
            onChange={this.onInputChange} />
              
            </div>
            <div className="form-group">
              
            <Translate component="input"
           attributes={{placeholder:'register_account_paragraph8'}} 
           value={this.state.confirmpassword}
           placeholder="Tekrar şifre"
           type="password"
           id="confirmpassword"
            className="form-control"
            onChange={this.onInputChange} />

           
            
              
            </div>
            <div class="form-group">
					<div class="col">
						<button type="submit" id="btnRegister" class="btn btn-info btn-lg btn-block waves-effect waves-light"  ><Translate content="register_account_paragraph9"  /></button>
					</div>
          
				</div>
        <div style={{position:"fixed",position:"relative",textAlign:"center"}}>
        <FormErrors formerrors={this.state.errors} />
        </div>
          </form>
        </div>
     </div>
      </section>
      </div>
    );
  }
}

export default Register;