import React from 'react';
import './App.css';
import LogIn from './Components/Login';
import Dashboard from './Components/Dashboard';
import Dashy from './Components/Dashy';
import Footer from './Components/Footer';
import Hakkimizda from './Components/Hakkimizda';
import TeknikDestek from './Components/TeknikDestek';
import Iletisim from './Components/Iletisim';
import Iletisim1 from './Components/Iletisim1';
import Header from './Components/Header';
import GizlilikPolitikasi from './Components/GizlilikPolitikasi'
import Hesapduzenle from './Components/auth/hesapduzenle';
import Register from './Components/auth/Register';
import ForgotPassword from './Components/ForgotPassword';
import SifreDogrulama from './Components/auth/ForgotPasswordVerification';
import Hesap from './Components/auth/hesap';
import Welcome from './Components/auth/Welcome';
import Ayarlar from './Components/ayarlar';
import Olaylar from './Components/history/events';
import Rapor from './Components/history/rapor';
import Translate from 'react-translate-component'
import counterpart from 'counterpart'
import en from './Components/lang/en'
import tr from './Components/lang/tr'
import Recaptcha from 'react-recaptcha'
import SiteHaritasi from './Components/SiteHaritasi'
import CanliKamera from './Components/CanliKamera'
import SikcaSorulanSorular from './Components/SikcaSorulanSorular'
import Harita from './Components/Harita'


import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";

import { Auth } from 'aws-amplify'
import Axios from "axios";

var userLang = navigator.language ; 

class App extends React.Component {

  componentWillMount(){
    // Checks the browser language 
    
    if (!localStorage.getItem('lang')) {
      if (userLang==='tr-TR') {
        localStorage.setItem('lang','tr')
        counterpart.setLocale('tr')
      }else{
        localStorage.setItem('lang','en')
        counterpart.setLocale('en')
      }
    }
  }


  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    userid: null
  }

  setAuthStatus = authenticated => {

    this.setState({ isAuthenticated: authenticated });
  }

  
//deneme
  getUserInfo = (posturl) => {
    
    Auth.user.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }
      const sessionToken = session.getIdToken().jwtToken;
      
      let msg = {
        "msgtype": "userchkcmd",
        "useremail": Auth.user.attributes.email
      };


      Axios.post(posturl, msg, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionToken
        },
      }).then((response) => {
 
        if (this.state.userinfo == null) {
          this.setState({ userinfo: response.data })
        }
        

      }).catch((error) => {
        alert("Post sırasında hata : " + error);
      });
    });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }
    this.setState({ isAuthenticating: false });
  }
  
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      getUserInfo: this.getUserInfo,
    }
    
    return (



      !this.state.isAuthenticating &&
      <Router >
        
          <Header auth={authProps} />

         
        
        {(authProps.isAuthenticated) ?
          <Switch>
            <Route exact path="/" render={(props) => <Dashboard {...props} auth={authProps} userinfo={this.state.userinfo}/>} />
            <Route exact path="/CanliKamera" render={(props) => <CanliKamera {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Dashboard" render={(props) => <Dashboard {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Dashboard/:devindex" render={(props) => <Dashboard {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Dashy" render={(props) => <Dashy {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/hesap" render={(props) => <Hesap {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/hesapduzenle" render={(props) => <Hesapduzenle {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/ayarlar" render={(props) => <Ayarlar {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/ayarlar/:devindex" render={(props) => <Ayarlar {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/olaylar" render={(props) => <Olaylar {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/olaylar/:devindex" render={(props) => <Olaylar {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/rapor" render={(props) => <Rapor {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/rapor/:devindex" render={(props) => <Rapor {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Iletisim" render={(props) => <Iletisim {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/TeknikDestek" render={(props) => <TeknikDestek {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Hakkimizda" render={(props) => <Hakkimizda {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/GizlilikPolitikasi" render={(props) => <GizlilikPolitikasi {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/SiteHaritasi" render={(props) => <SiteHaritasi {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/SikcaSorulanSorular" render={(props) => <SikcaSorulanSorular {...props} auth={authProps} userinfo={this.state.userinfo} />} />
            <Route exact path="/Harita" render={(props) => <Harita {...props} auth={authProps} userinfo={this.state.userinfo} />} />
          </Switch>
          :
          <Switch>
            {/* <Route exact path="/Harita" render={(props) => <Harita {...props} auth={authProps} />} /> */}
            <Route exact path="/SikcaSorulanSorular" render={(props) => <SikcaSorulanSorular {...props} auth={authProps} />} />
            <Route exact path="/SiteHaritasi" render={(props) => <SiteHaritasi {...props} auth={authProps} />} />
            {/* <Route exact path="/CanliKamera" render={(props) => <CanliKamera {...props} auth={authProps} />} /> */}
            <Route exact path="/GizlilikPolitikasi" render={(props) => <GizlilikPolitikasi {...props} auth={authProps} />} />
            <Route exact path="/login" render={(props) => <LogIn {...props} auth={authProps} />} />
            <Route exact path="/Hakkimizda" render={(props) => <Hakkimizda {...props} auth={authProps} />} />
            <Route exact path="/TeknikDestek" render={(props) => <TeknikDestek {...props} auth={authProps} />} />
            <Route exact path="/Iletisim" render={(props) => <Iletisim {...props} auth={authProps} />} />
            <Route exact path="/Iletisim1" render={() => <Iletisim1  />} />
            <Route exact path="/YeniKayit" render={(props) => <Register {...props} auth={authProps} />} />
            <Route exact path="/YeniKayit/:devid/:firma/:kulsev/:ustkulid/:email1/:email2" render={(props) => <Register {...props} auth={authProps} />} />
            <Route exact path="/Kayipsifre" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
            <Route exact path="/forgotpasswordverification" render={(props) => <SifreDogrulama {...props} auth={authProps} />} />
            <Route exact path="/welcome" render={(props) => <Welcome {...props} auth={authProps} />} />
            <Route exact path="/*" render={(props) => <LogIn {...props} auth={authProps} />} />
          </Switch>
        }
         <Footer  auth={authProps} userinfo={this.state.userinfo}/>
      </Router>
    );


  }

};

export default App;
