import React, { Component } from 'react';
import Grafik from '../Components/OzetGrafik';
// import Axios from "axios";
import Sidebar from '../Components/sidebar';
import Graphs from '../Components/Graphs';
import { Auth } from "aws-amplify";
import Axios from "axios";
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'


export default class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// sensArray: null,
			// reload: true
		};
	}

	


	async componentDidMount() {
		//sessionStorage.setItem("Login","1");
		
		// if (localStorage.getItem('prevent'!==1)) {
		// 	localStorage.setItem('prevent',1)
		// }
		
		this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
		// console.log(this.props.match.params.devindex);
		// console.log(this.props.userinfo);
		// if ((this.props.match.params.devindex !== undefined) && (this.props.userinfo !== undefined)) {
		// console.log("okmount");
		// 	this.loadSenseArr(this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid);
		// }

	}

	

	componentDidUpdate() {
		// Refreshes the page after logging in
		
				(function()
		{
		if( window.localStorage )
		{
			if( !localStorage.getItem('prevent') )
			{
			localStorage['prevent'] = 0;
			window.location.reload();
			}  
			else
			localStorage.removeItem('prevent');
		}
		})();
		// if ((this.props.match.params.devindex !== undefined) && (this.props.userinfo !== undefined)) {
		// 	console.log("okupdate");
		// 	this.loadSenseArr(this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid);
		// 	// if (this.state.reload)
		// 	// 	this.setState({reload: false});
		// }
	}
	render() {
		if (this.props.userinfo&&this.props.userinfo.configs) {
			this.props.userinfo.configs.regdevices=this.props.userinfo.configs.regdevices.filter(function(el){return el.deviceid!=""})
		}
		//   console.log("state");
		//   console.log(this.state.sensArray);
		//   console.log("props");
		//console.log(this.props.userinfo.data.userid);
		//   console.log("end");
		// this.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
		// this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');
		//  console.log(this.props);
		// console.log(this.props.match.params.devindex);
		// console.log(this.props.userinfo);
		// if ((this.props.match.params.devindex !== undefined) && (this.props.userinfo !== undefined)) {
		// 	console.log("okupdate");

		// 	if (this.state.reload) {
		// 		this.loadSenseArr(this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid);
		// 		this.setState({reload: false});
		// 	}

		// }
		// console.log(this.state);
		// if (this.state.sensArray !== null)


	

		return (
			
		

			<div id="page-wrapper">
				<div className="container-fluid p-t-20">
					<div className="row">
						<div className="col-md-2 col-sm-12 col-xs-12">
							{this.props.userinfo !== undefined &&
							this.props.userinfo.configs !== undefined && 
								 <Sidebar devlist={this.props.userinfo.configs.regdevices} kulsev={this.props.userinfo.authLevel} selecteddev={this.props.match.params.devindex} />	
							}
							{/* {this.props.userinfo !== undefined &&
							this.props.userinfo.configs === undefined &&
								<Sidebar devlist={"1"} kulsev={this.props.userinfo.authLevel} selecteddev={this.props.match.params.devindex} />	
							} */}

							{/* <Sidebar devlist={(this.props.userinfo !== undefined) && this.props.userinfo.configs.regdevices}/> */}
						
						</div>
						<div className="col-md-10 col-sm-12 col-xs-12">
						{
	this.props.match.params.devindex === undefined &&
	<div role="alert"  style={{ "padding": "10px","border":"3px solid #138BAC","borderRadius":"10px","backgroundColor":"#fff" }} >
		<strong  ><Translate content="summary_system_paragraph14"  /></strong>
		{/* <button type="button" className="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button> */}
	</div>
	}
							<div className="row">
								<div className="col-md-6 col-sm-12 col-xs-12">
								<h3 className="m-t-0"> {Auth.user.attributes.name } - {this.props.userinfo !== undefined && this.props.userinfo.firm&&this.props.userinfo.firm.toUpperCase()}</h3>
								</div>
								{/* <div className="col-md-6 col-sm-12 col-xs-12">
									<div className="row">
										<div className="col-md-4 col-sm-6 col-xs-12">
										

											<select name="firma" className="m-b-20 selectpicker" data-style="form-control">
												<option value="" data-hidden="true">FİRMA</option>
												<option value="firma1">FİRMA 1</option>
												<option value="firma2">FİRMA 2</option>
											</select>
										</div>
										<div className="col-md-4 col-sm-6 col-xs-12">
											<div className="selectdiv">
												<select name="durum" className="m-b-20 selectpicker" data-style="form-control">
													<option value="" data-hidden="true">DURUM</option>
													<option value="alarm">ALARM</option>
													<option value="hata">HATA</option>
												</select>
											</div>
										</div>
										<div className="col-md-4 col-sm-6 col-xs-12">
											<div className="selectdiv">
												<select name="sensor" className="m-b-20 selectpicker" data-style="form-control">
													<option value="" data-hidden="true">SENSÖR</option>
													<option value="sensor1">SENSÖR 1</option>
													<option value="sensor2">SENSÖR 2</option>
												</select>
											</div>
										</div>
									</div>
								</div> */}
								<div style={{float:'right'}}>
						<a href={window.location.href} class="button"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
						<path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
						<path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
						</svg></a>
						</div>
								{(this.props.match.params.devindex !== undefined) &&
									this.props.userinfo !== undefined &&
									<Graphs deviceid={this.props.userinfo.configs&&this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1]&&this.props.userinfo.configs.regdevices[this.props.match.params.devindex - 1].deviceid} />
								}
								{/* {
									this.props.match.params.devindex === undefined &&
									<div >
                                        <strong className={"alert alert-info"} role="alert"  style={{"padding":"10px"}}><Translate content="summary_system_paragraph14"  /></strong>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
								} */}
							</div>
							
						</div>
					
					</div>
				</div>
<div style={{height:40}}></div>
			</div>

		);
	}
}

//export default Dashboard;
