import React, { Component } from 'react';

class Kurallar extends Component {
    render() {
        return (
            <div class="white-box">
                <h3 class="m-t-0 m-b-30 left-border">KURALLAR</h3>
                <div class="table-responsive">
                    <table id="example24" class="display nowrap table color-table muted-table table-hover text-center" cellspacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>Durum</th>
                                <th>Sonuç</th>
                                <th>Gecikme</th>
                                <th>Düzenle</th>
                                <th>Sil</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Manyetik Açık</td>
                                <td>Kamera Kayıt</td>
                                <td>2 dk</td>
                                <td><a href="javascript:void(0)" id="openBtn4" data-id="1"><img src="plugins/images/edit.png" title="" alt="" /></a></td>
                                <td><a href=""><img src="plugins/images/delete.png" title="" alt="" /></a></td>
                            </tr>
                            <tr>
                                <td>Manyetik Açık</td>
                                <td>Kamera Kayıt</td>
                                <td>2 dk</td>
                                <td><a href="javascript:void(0)" id="openBtn4" data-id="2"><img src="plugins/images/edit.png" title="" alt="" /></a></td>
                                <td><a href=""><img src="plugins/images/delete.png" title="" alt="" /></a></td>
                            </tr>
                            <tr>
                                <td>Manyetik Açık</td>
                                <td>Kamera Kayıt</td>
                                <td>2 dk</td>
                                <td><a href="javascript:void(0)" id="openBtn4" data-id="3"><img src="plugins/images/edit.png" title="" alt="" /></a></td>
                                <td><a href=""><img src="plugins/images/delete.png" title="" alt="" /></a></td>
                            </tr>
                            <tr>
                                <td>Manyetik Açık</td>
                                <td>Kamera Kayıt</td>
                                <td>2 dk</td>
                                <td><a href="javascript:void(0)" id="openBtn4" data-id="4"><img src="plugins/images/edit.png" title="" alt="" /></a></td>
                                <td><a href=""><img src="plugins/images/delete.png" title="" alt="" /></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Kurallar;