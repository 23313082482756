import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Select from "react-select";
import Axios from "axios";
import { Auth } from "aws-amplify";



class cihazakayitliliste extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adminusers: [],
            duzenlenecekuserid: "",
            duzenlenecekadsoyad: "",
            duzenlenecekemail: "",
            duzenleneceyetkisevLable: "",
            duzenleneceyetkisevValue: "",
            duzenlenecektelefon: "",
            kulbilgiguncelalertShow: false,
            kulbilgiguncelalertMessage: "",
            kulbilgiguncelalertType: "",
            kulduzenlebuttonvisible: "hidden",
            silinecekKulid: "",
            search: []
        };
        this.kulbilgiupdate = this.kulbilgiupdate.bind(this);

    }

    editKul = (kul) => {
        // console.log(kul.userid);
        this.setState({kulduzenlebuttonvisible: "hidden"});
        // console.log(kul);
        var tkulsevLabel;
        switch (kul.yetkisev) {
            case "sup":
                tkulsevLabel = "Super admin";
                break;
            case "oper":
                tkulsevLabel = "Operatör";
                break;
            case "izle":
                tkulsevLabel = "izleyici";
                break;
            case "adm":
                tkulsevLabel = "admin";
                break;
            default:
                break;
        }
        this.setState({
            duzenlenecekuserid: kul.userid,
            duzenlenecekadsoyad: kul.adsoyad,
            duzenlenecekemail: kul.usermail,
            duzenlenecektelefon: kul.usertel,
            duzenleneceyetkisevLable: tkulsevLabel,
            duzenleneceyetkisevValue: kul.yetkisev
        })
    };

    deleteKul = (kulbilgi) => {
        if (window.confirm("Bu kullanıcı bu cihazdan silinecek, emin misiniz?")) {
            // console.log(kulbilgi);
            this.setState({
                silinecekKulid: kulbilgi.userid
            });
            this.kuldelPOST(kulbilgi.userid);

        } else {
            console.log("no ok");
        }

    };

    kuldelPOST(kulid) {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;

            let msg = {
                "msgtype": "deleteuser",
                "userid": kulid,
                "devid": this.props.cihazid
            };


            Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
                // body: ''
            }).then(() => {
                // console.log("ok");
                this.setState({ kulbilgiguncelalertType: "alert-success", kulbilgiguncelalertMessage: "İşleminiz gerçekleşti!", kulbilgiguncelalertShow: true });

                setTimeout(() => {
                    this.setState({ kulbilgiguncelalertShow: false });
                    document.getElementById("CloseButtonbilgiGuncel").click();
                }, 2000
                );
                // this.setState({
                //     silinecekKulid: ""
                // });
                alert("kullanıcı bu cihazdan silindi!");
                window.location.reload(false);

            }).catch((error) => {
                // console.log("error");
                // console.log(error);
                // this.setState({ PostSuccess: false });
                this.setState({ kulbilgiguncelalertType: "alert-warning", kulbilgiguncelalertMessage: "İşlem sırasında hata " + error, kulbilgiguncelalertShow: true });
            });
        });
    }


    bucihazausertable() {
        let kullist = [];

        for (var i = 0; i < this.props.adminusers.length; i++) {
            // console.log(this.props.adminusers[i].regdevs);
            // console.log(this.props.cihazid);
            for (var j = 0; j < this.props.adminusers[i].regdevs.length; j++) {
                if ((this.props.adminusers[i].regdevs[j].deviceid == this.props.cihazid) /*&& (this.props.adminusers[i].yetkisev != "sup") && (this.props.adminusers[i].yetkisev != "adm")*/) {
                    // console.log("ok");
                    kullist.push(this.props.adminusers[i]);
                }
            }
        }

        // console.log(kullist);
        // console.log(this.props.cihazid);
        return kullist;
    }


    tableraw(list) {
        let kullist1 = list.map((kul, index) => {
            return (
                <tr key={index}>
                    <td>{kul.adsoyad}</td>
                    <td>{kul.usermail}</td>
                    <td>{kul.usertel}</td>
                    <td>
                        {(kul.yetkisev == "sup") && "Süper Admin"}
                        {(kul.yetkisev == "oper") && "Operatör"}
                        {(kul.yetkisev == "izle") && "İzleyici"}
                        {(kul.yetkisev == "adm") && "Admin"}
                    </td>
                    <td><Button variant="link" data-target={"#kul_EditModal"} data-toggle="modal" onClick={() => this.editKul(kul)}><img src="plugins/images/edit.png" title="" alt="" /></Button></td>
                    <td><Button variant="link" onClick={() => this.deleteKul(kul)}><img src="plugins/images/delete.png" title="" alt="" /></Button></td>
                </tr>
            )
        });
        return kullist1;
    }



    kulbilgiupdate(event) {
        this.setState({kulduzenlebuttonvisible: "visible"});
        switch (event.target.id) {
            case "kul_ad_soyad":
                this.setState({
                    duzenlenecekadsoyad: event.target.value,
                });
                break;
            case "kul_email":
                this.setState({
                    duzenlenecekemail: event.target.value,
                });
                break;
            case "kul_tel":
                this.setState({
                    duzenlenecektelefon: event.target.value,
                });
                break;
            default:
                break;
        }
    }

    kulbilgiupdatePOST() {
        Auth.user.getSession((err, session) => {
            if (err) {
                throw new Error(err);
            }

            const sessionToken = session.getIdToken().jwtToken;

            let msg = {
                "msgtype": "edituser",
                "userid": this.state.duzenlenecekuserid,
                "usertel": this.state.duzenlenecektelefon,
                "authlevel": this.state.duzenleneceyetkisevValue,
            };


            Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', msg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionToken
                },
            }).then(() => {
                console.log("ok");
                this.setState({ kulbilgiguncelalertType: "alert-success", kulbilgiguncelalertMessage: "İşleminiz gerçekleşti!", kulbilgiguncelalertShow: true });

                setTimeout(() => {
                    this.setState({ kulbilgiguncelalertShow: false });
                    document.getElementById("CloseButtonbilgiGuncel").click();
                }, 2000
                );
                this.setState({

                });

            }).catch((error) => {
               
                this.setState({ kulbilgiguncelalertType: "alert-warning", kulbilgiguncelalertMessage: "İşlem sırasında hata " + error, kulbilgiguncelalertShow: true });
            });
        });
    }

    ShowMessage() {

        return (
            <div className={"alert " + this.state.kulbilgiguncelalertType} role="alert">
                <strong>{this.state.kulbilgiguncelalertMessage}</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    }

    render() {


        let options;
        if (this.props.kulsearch.length) {
            const searchPattern = new RegExp(this.props.kulsearch.map(term => `(?=.*${term})`).join(''), 'i');
            options = this.bucihazausertable().filter(option =>
                option.searchfield.match(searchPattern)
            );
        } else {
            options = this.bucihazausertable();
        }

        return (
            <div >
                <table id="example23" class="display nowrap table color-table muted-table table-hover text-center" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>AD SOYAD</th>
                            <th>E-MAİL</th>
                            <th>TELEFON NO</th>
                            <th>YETKİ SEVİYESİ</th>
                            <th>DÜZENLE</th>
                            <th>SİL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.tableraw(options)}
                    </tbody>
                </table>

                <div className="modal fade" id={"kul_EditModal"} tabIndex="-1" role="dialog" aria-labelledby="kul_EditModal" aria-hidden="true" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button id={"CloseButtonbilgiGuncel"} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div id="wrapper">
                                    <div className="white-box m-b-0" >
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <h3 className="m-t-0 m-b-30 left-border">Kullanıcı Bilgileri Düzenle</h3>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <form action="" data-toggle="validator">

                                                    <div className="form-horizontal">
                                                        <div className="form-group">
                                                            <label className="col-xs-4 control-label">Email Adresi:</label>
                                                            <div className="col-xs-8 pull-right">
                                                                <input type="text" name="kul_email" id="kul_email" className="form-control" Value={this.state.duzenlenecekemail} required disabled />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-xs-4 control-label">Telefon No:</label>
                                                            <div className="col-xs-8 pull-right">
                                                                <input type="text" name="kul_tel" id="kul_tel" className="form-control" Value={this.state.duzenlenecektelefon} required onChange={this.kulbilgiupdate} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-xs-4 control-label">Yetki seviyesi:</label>
                                                            <div className="col-xs-8 pull-right">
                                                                <Select
                                                                    defaultValue={{ value: "", label: "Yeni yetki seviyesini seç" }}
                                                                    options={[
                                                                        { value: 'izle', label: 'İzleyici' },
                                                                        { value: 'oper', label: 'Operatör' }
                                                                    ]}
                                                                    values={[]}
                                                                    onChange={(value) => this.setState({ duzenleneceyetkisevValue: value.value, kulduzenlebuttonvisible: "visible" })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-xs-4 control-label hidden-xs"></label>
                                                            <div className="col-xs-8 pull-right">
                                                                <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "120px", "visibility": this.state.kulduzenlebuttonvisible }} value="data-id" onClick={() => { this.kulbilgiupdatePOST() }} >Kaydet</button>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            {this.state.kulbilgiguncelalertShow && this.ShowMessage()}
                                                        </div>
                                                    </div>




                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default cihazakayitliliste;