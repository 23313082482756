import React, { Component } from "react";
import { Chart } from "react-google-charts";
import Axios from "axios";
import { Auth } from "aws-amplify";
import ReactLoading from "react-loading";
import { Section, Title, Article, Prop, list, SectionAler } from "./generik";
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import en from './lang/en'
import tr from './lang/tr'
import jsPDF from 'jspdf'
import autotable from 'jspdf-autotable'
import ReactToExcel from 'react-html-table-to-excel'
import moment from 'moment'

counterpart.registerTranslations('tr',tr);
counterpart.registerTranslations('en',en);

class OzetGrafik extends Component {

  state={
    lang:'tr'
    
      }
      addFromLS=(e)=>{
      sessionStorage.setItem('lang',e.target.value)
      // console.log(213);
      }
      onLangChange=(e)=>{

      this.setState({lang:e.target.value})
          counterpart.setLocale(e.target.value)
      }

      
  constructor(props) {
    super(props);
    this.state = {
      pdf:'',
      SenStatus: "",
      ApiAdress: "",
      GrpData: [["Saat", "AltLimit", "Deger", "UpperLimit"]],
      AC1: "border",
      AC2: "border",
      AC3: "border",
      AC4: "border selected",
      datalar: [],
      Ad: [],
      Hassasiyeti: "",
      UstLimiti: "",
      AltLimiti: "",
      // adres sensor'e sensor sensorNo'ya
      SensorNo: this.props.sensor,
      Zaman: "h",
      // CihazID props.devid
      CihazID: this.props.CihazID,
      ackapa: false,
      SensorTipi: "Sıcaklık Sensörü",
      MAC: "Mac adresi",
      Reload: false,
      modalShow: false,
      SenStatusClass: "hour-border",
      DataSendStatus: false,
      SensorType: "t",
      SaveButtonHide: "hidden",
      AlertShow: false,
      AlertType: "alert-warning",
      AlertMessage: "",
      PostSuccess: false,
      SensorIcon: "°C",
      SensorNName:"",
      SensorNDeger:"",
      cihazAdii:[],
      farkkk:[],
      alarmfarkkk:[],
      sensorEvents:[],
      allSensorEvents:[],
      CihazTipi:[],
      excelShow:false,
      GrpFlag:[],
      farkMin:[],
      devIndex:""
      // flag=false

    };

    this.ChangeTime = this.ChangeTime.bind(this);
    this.getData = this.getData.bind(this);
    this.DataProcess = this.DataProcess.bind(this);
    this.UpdateCommandSend = this.UpdateCommandSend.bind(this);
    this.handleChangeUpper = this.handleChangeUpper.bind(this);
    this.handleChangeName=this.handleChangeName.bind(this)
    
  }
  handleChangeName(){
    // console.log((document.getElementById("sensor_adi" + this.props.sensor).value));
    let devName=document.getElementById("sensor_adi" + this.props.sensor).value
    let devNameLength=(document.getElementById("sensor_adi" + this.props.sensor).value).length
    // console.log(devNameLength);
    if (devNameLength>=16) {
      this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: 'Device Name Can\'t be greater than 16 character' , AlertType: "alert-danger" });
    }else if (devNameLength<1) {
      this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: 'Device Name Can\'t be smaller than 1 character' , AlertType: "alert-danger" });
    } else{
      this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
    }
  }

  handleChangeUpper() {

    let UUlimit = parseInt(document.getElementById("ust_limit" + this.props.sensor).value);
    let UAltLimit = parseInt(document.getElementById("alt_limit" + this.props.sensor).value);
    // this.state.flag=true
    //  let SenVal = parseFloat(this.state.GrpData[this.state.GrpData.length - 1][2]);
    let SenAltLimit = parseInt(this.state.GrpData[this.state.GrpData.length - 1][1]);
    let SenUstlimit = parseInt(this.state.GrpData[this.state.GrpData.length - 1][3]);
    // let UpLimitChange = false;
    // let LowLimitChange = false;
    let CheckIt = false;
    if (UAltLimit !== SenAltLimit) {
      CheckIt = true;
      // LowLimitChange = true;
    }
    if (UUlimit !== SenUstlimit) {
      CheckIt = true;
      // UpLimitChange = true;
    }
    if (CheckIt) {



      switch (this.state.SensorType) {
        case "t":
          if (UUlimit < -99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik" />, AlertType: "alert-danger" });
          }
          else if (UUlimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik1" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit < -99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik2" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik3" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit >= UUlimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik4" />, AlertType: "alert-danger" });
          }
          else if (UUlimit <= UAltLimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik5" />, AlertType: "alert-danger" });
          }
          else {
            this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
          }
          break;

        case "h":
          if (UUlimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik6" />, AlertType: "alert-danger" });
          }
          else if (UUlimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik7" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik8" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik9" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit >= UUlimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik10" />, AlertType: "alert-danger" });
          }
          else if (UUlimit <= UAltLimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik11" />, AlertType: "alert-danger" });
          }
          else {
            this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
          }
          break;
          case "v":
          if (UUlimit < -300) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik32" />, AlertType: "alert-danger" });
          }
          else if (UUlimit > 300) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik31" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit < -300) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik36" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit > 300) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik35" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit >= UUlimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik10" />, AlertType: "alert-danger" });
          }
          else if (UUlimit <= UAltLimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik11" />, AlertType: "alert-danger" });
          }
          else {
            this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
          }
          break;
          case "f":
          if (UUlimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik6" />, AlertType: "alert-danger" });
          }
          else if (UUlimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik7" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik8" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik9" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit >= UUlimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik10" />, AlertType: "alert-danger" });
          }
          else if (UUlimit <= UAltLimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik11" />, AlertType: "alert-danger" });
          }
          else {
            this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
          }
          break;
          case "g":
          if (UUlimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik6" />, AlertType: "alert-danger" });
          }
          else if (UUlimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik7" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit < -1) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik8" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit > 99) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik9" />, AlertType: "alert-danger" });
          }
          else if (UAltLimit >= UUlimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik10" />, AlertType: "alert-danger" });
          }
          else if (UUlimit <= UAltLimit) {
            this.setState({ SaveButtonHide: "hidden", DataSendStatus: false, AlertShow: true, AlertMessage: <Translate content="ozet_grafik11" />, AlertType: "alert-danger" });
          }
          else {
            this.setState({ DataSendStatus: true, SaveButtonHide: "visible", AlertShow: false });
          }
          break;
        // case "p":
        //   "Yapılacak";
        // break;
        // case "f":
        //   "Yapılacak";
        // break;
        // case "v":
        //   "Yapılacak";
        // break;
        // case "g":
        //   "Yapılacak";
        // break;
        // case "d":
        //   "Yapılacak";
        // break;
        // case "m":
        //   "Yapılacak";
        // break;
        // case "c":
        //   "Yapılacak";
        // break;
        // case "a":
        //   "Yapılacak";
        // break;
        // case "e":
        //   "Yapılacak";
        // break;
        default:

      }
    }

  }

  getData() {


    // console.log('data çağırıldı');
    //console.clear();

    Auth.user.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }

      const sessionToken = session.getIdToken().jwtToken;
      Axios.get(this.state.ApiAdress, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionToken
        },
        // body: ''
      })
        .then(res => {
          const Cihazlar = res.data.Items;

          // console.log('Cihazlar '+JSON.stringify(res.data.Items[0]));

          // console.log(this.props.sensor);
          // console.log(Cihazlar);
          this.setState({
            datalar: Cihazlar,
            Reload: true
            //SensorType
            
          });



        })
        .catch(error => {
          console.log("Hatavar : " + error);
        });
    });
  }
  componentDidMount() {

    // var timerid= setTimeOut(() => this.setState({ show: true}), 3000)

    Auth.user.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }

      const sessionToken = session.getIdToken().jwtToken;
      Axios.get('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/devs/' + this.state.CihazID, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionToken
        },
        // body: ''
      })
        .then(res => {
          // console.log('res data :'+JSON.stringify(res.data.Items[0].payload.sensorveri));
          // console.log('res data type :'+JSON.stringify(res.data.Items[0].payload.sensorveri[0]));
          // console.log('res data type st :'+JSON.stringify(res.data.Items[0].payload.sensorveri[0].st));
          // console.log('res data length:'+res.data.Items[0].payload.sensorveri.length);
          let typeArr=[]
          // for(let j=0;i<=res.data.Items[j].payload.sensorveri.length;j++)
          for(let i=0;i<=res.data.Items[i].payload.sensorveri.length;i++){
            typeArr[i]=JSON.stringify(res.data.Items[i].payload.sensorveri.st)
            // typeArr.push(JSON.stringify(res.data.Items[0].payload.sensorveri[0].st))
            // console.log(typeArr[i]);
            // this.setState.CihazTipi.push(res.data.Items[i].payload.sensorveri[i].st)
            // console.log('type Array'+this.state.CihazTipi);
            // typeArr.push.(res.data.Items[i].payload.sensorveri[i].st)
          }
          
          
          


          
          this.setState({CihazTipi:res.data.Items[0].payload.sensorveri[0].st})
          
       
        })
        .catch(error => {
          console.log("Hatavar : " + error);
        });
    });


    // console.log(this.state.datalar)

    let ServiceAdress =
      "https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/" +
      this.props.CihazID +
      "/h/" +
      this.state.SensorNo;
    this.setState({ ApiAdress: ServiceAdress }, () => {
      this.getData();

    });
    // console.clear();
  }
  SetSensorName(Name) {
    this.setState({ Ad: Name });
  }
  ChangeTime = (value, acNo) => {
    this.setState({LoadStart:true});
    let ServiceAdress =
      "https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/" +
      this.state.CihazID +
      "/" +
      value +
      "/" +
      this.state.SensorNo;
    // value h,d,w ayarlari
    if (acNo === 1) {
      this.setState(
        {
          pdf:'week',
          datalar: [],
          GrpData: [["Saat", "AltLimit", "Deger", "UpperLimit"]],
          AC1: "border selected",
          AC2: "border",
          AC3: "border",
          AC4: "border",
          Zaman: value,
          ApiAdress: ServiceAdress
        },
        () => {
          this.getData();
        }
      );
    } else if (acNo === 2) {
      this.setState(
        {
          pdf:'twentyfour',
          datalar: [],
          GrpData: [["Saat", "AltLimit", "Deger", "UpperLimit"]],
          AC1: "border",
          AC2: "border selected",
          AC3: "border",
          AC4: "border",
          Zaman: value,
          ApiAdress: ServiceAdress
        },
        () => {
          this.getData();
        }
      );
    } else if (acNo === 3) {
      this.setState(
        {
          pdf:'eight',
          datalar: [],
          GrpData: [["Saat", "AltLimit", "Deger", "UpperLimit"]],
          AC1: "border",
          AC2: "border",
          AC3: "border selected",
          AC4: "border",
          Zaman: value,
          ApiAdress: ServiceAdress
        },
        () => {
          this.getData();
        }
      );
    } else if (acNo === 4) {
      this.setState(
        {
          pdf:'one',
          datalar: [],
          GrpData: [["Saat", "AltLimit", "Deger", "UpperLimit"]],
          AC1: "border",
          AC2: "border",
          AC3: "border",
          AC4: "border selected",
          Zaman: value,
          ApiAdress: ServiceAdress
        },
        () => {
          this.getData();
        }
      );
    }
    this.setState({LoadStart:false});
  };

  

  UpdateCommandSend() {
    this.setState({ PostSuccess: false });
    // let SetDate=new Date().getFullYear()+"-"+new Date().getMonth()+"-"+new Date().getDay()+"T"+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds()+":"+new Date().getMilliseconds();
    //alert(this.props.sensor);
    // console.log(this.props.sensor);
    if (this.state.DataSendStatus) {
      if (this.state.GrpData[this.state.GrpData.length - 1][3] !== document.getElementById("ust_limit" + this.props.sensor).value) {
        Auth.user.getSession((err, session) => {
          if (err) {
            throw new Error(err);
          }

          const sessionToken = session.getIdToken().jwtToken;

          Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', {
            "msgtype": "devcmd",
            "cid": this.props.CihazID,
            "z": new Date().toISOString(),
            "altkan": this.props.sensor,
            "ad": this.props.sensad,
            "dp": "u",
            "d": document.getElementById("ust_limit" + this.props.sensor).value
            
          }, {
            method: 'GET',
            headers: {
              // Accept: 'application/json',
              'Content-Type': 'application/json',
              // // 'Authorization': this.props.auth["user"]["storage"]["CognitoIdentityServiceProvider." + this.props.auth.user.pool.clientId + "." + this.props.auth.user.username + ".idToken"]
              'Authorization': sessionToken
            },
            // body: ''
          }).then((res) => {
            // console.log('hii');
            // console.log('here'+JSON.stringify(res.data));
            this.setState({ AlertType: "alert-success", AlertMessage: <Translate content="ozet_grafik23" />, AlertShow: true });

            setTimeout(() => {
              this.setState({ AlertShow: false });
              document.getElementById("CloseButton" + this.props.sensor).click();
            }, 2000
            );

          }).catch((error) => {
            this.setState({ PostSuccess: false });
            this.setState({ AlertType: "alert-warning", AlertMessage: <Translate content="ozet_grafik24" /> + error, AlertShow: true });
          });
        });
      }
      if (this.state.GrpData[this.state.GrpData.length - 1][1] !== document.getElementById("alt_limit" + this.props.sensor).value) {
        Auth.user.getSession((err, session) => {
          console.log("ook");
          if (err) {
            throw new Error(err);
          }

          const sessionToken = session.getIdToken().jwtToken;
          Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', {
            "msgtype": "devcmd",
            "cid": this.props.CihazID,
            "z": new Date().toISOString(),
            "altkan": this.props.sensor,
            "ad": this.props.sensad,
            "dp": "a",
            "d": document.getElementById("alt_limit" + this.props.sensor).value
          }, {
            method: 'GET',
            headers: {
              // Accept: 'application/json',
              'Content-Type': 'application/json',
              // // 'Authorization': this.props.auth["user"]["storage"]["CognitoIdentityServiceProvider." + this.props.auth.user.pool.clientId + "." + this.props.auth.user.username + ".idToken"]
              'Authorization': sessionToken
            },
            // body: ''
          }).then((res) => {
            console.log('api');
            console.log(res);


            this.setState({ AlertType: "alert-success", AlertMessage: <Translate content="ozet_grafik23" />, AlertShow: true });

            setTimeout(() => {
              this.setState({ AlertShow: false });
              document.getElementById("CloseButton" + this.props.sensor).click();
            }, 2000
            );

          }).catch((error) => {
            this.setState({ PostSuccess: false });
            this.setState({ AlertType: "alert-warning", AlertMessage: <Translate content="ozet_grafik24" /> + error, AlertShow: true });
          });
        });
      }
      //update device name
      if (this.state.Ad[0] !== document.getElementById("sensor_adi" + this.props.sensor).value) {
        Auth.user.getSession((err, session) => {
          console.log("ook");
          if (err) {
            throw new Error(err);
          }

          const sessionToken = session.getIdToken().jwtToken;
          Axios.post('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod', {
            "msgtype": "devcmd",
            "cid": this.props.CihazID,
            "z": new Date().toISOString(),
            "altkan": this.props.sensor,
            "ad": this.props.sensad,
            "dp": "ad",
            "d": document.getElementById("sensor_adi" + this.props.sensor).value
            // .replaceAll('İ','I').replaceAll('Ğ','G').replaceAll('Ü','U').replaceAll('ü','u').replaceAll('Ş','S').replaceAll('Ç','C').replaceAll('Ö','O').replaceAll('ğ','g')
            // .replaceAll('ş','s')
            // .replaceAll('ı','i')
            // .replaceAll('ö','o')
            // .replaceAll('ç','c')
          }, {
            method: 'GET',
            headers: {
              // Accept: 'application/json',
              'Content-Type': 'application/json',
              // // 'Authorization': this.props.auth["user"]["storage"]["CognitoIdentityServiceProvider." + this.props.auth.user.pool.clientId + "." + this.props.auth.user.username + ".idToken"]
              'Authorization': sessionToken
            },
            // body: ''
          }).then((res) => {


            this.setState({ AlertType: "alert-success", AlertMessage: <Translate content="ozet_grafik23" />, AlertShow: true });

            setTimeout(() => {
              this.setState({ AlertShow: false });
              document.getElementById("CloseButton" + this.props.sensor).click();
            }, 2000
            );

          }).catch((error) => {
            this.setState({ PostSuccess: false });
            this.setState({ AlertType: "alert-warning", AlertMessage: <Translate content="ozet_grafik24" /> + error, AlertShow: true });
          });
        });
      }

    }
    else {
      this.setState({ PostSuccess: false });
      alert(<Translate content="ozet_grafik25" />);
    }




  }
  handlePDF=()=>{
    var doc = new jsPDF();
    doc.autoTable({html:'#deneme'})
    // doc.text(Raporliste.options)
    doc.save('rapor.pdf')
  }

  
  componentWillMount(){
    var lastIndex=window.location.href
    let n=lastIndex.lastIndexOf('/');
    let strResult=lastIndex.substring(n+1);
    console.log(strResult)
    // var newLastIndex=lastIndex.substring(35,lastIndex.length)
    this.setState({ lastIndex: strResult });
    Auth.user.getSession((err, session) => {
      if (err) {
        throw new Error(err);
      }

      const sessionToken = session.getIdToken().jwtToken;
      Axios.get('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/devs/' + this.state.CihazID, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionToken
        },
        // body: ''
      })
        .then(res => {
          // console.log('res data :'+JSON.stringify(res.data.Items));
          // console.log('res data :'+JSON.stringify(res.data.Items[0].payload.sensorveri));
          // console.log('res data type :'+JSON.stringify(res.data.Items[0].payload.sensorveri[0]));
          // console.log('res data type st :'+JSON.stringify(res.data.Items[0].payload.sensorveri[0].st));
          // console.log('res data length:'+res.data.Items[0].payload.sensorveri.length);
          let typeArr=[]
          
          // console.log(typeArr);
          // console.log('type Array'+typeArr);

          // console.log((res.data.Items[0].timestampepoch)*1000);
          
          var cihazAdi=res.data.Items[0].devConfigs.devN
          var myDate = Date.now()
          // console.log(myDate);
          // console.log(myDate-((res.data.Items[0].timestampepoch)*1000));
          var fark=myDate-((res.data.Items[0].timestampepoch)*1000)

          // console.log(Math.floor(fark/1000));
           var day, hour, minute, seconds;
          seconds = Math.floor(fark / 1000);
          minute = Math.floor(seconds / 60);
          seconds = seconds % 60;
          hour = Math.floor(minute / 60);
          minute = minute % 60;
          day = Math.floor(hour / 24);
          hour = hour % 24;

          // console.log('day ' + day);
          // console.log('hour ' + hour);
          // console.log('minute ' + minute);
          // console.log('seconds ' + seconds);
          // console.log('Cihaz ' + day +' gün,'+hour+' saat,'+minute+' dakika'+seconds+' saniyedir offline' );
          var minutes = Math.floor(fark / 60000);

          // var farkk=(((minutes/60))*1000).toFixed(2)+" hour"
          if(localStorage.getItem('lang')=='tr'){
            var farkk='Cihazı ' + day +' gün, '+hour+' saat, '+minute+' dakika '+seconds+' saniyedir offline' 
          }
          else
          {
            var farkk='Device is offline for ' + day + ' day(s), ' + hour + ' hour(s),'
            
          }
          
          // console.log((((minutes/60))*1000).toFixed(2)+" hour");
          // console.log(cihazAdi);
          // this.setState({CihazTipi:res.data.Items[0].payload.sensorveri[0].st})
          this.setState({farkkk:farkk})
          this.setState({farkMin:minute})
          this.setState({cihazAdii:res.data.Items[0].devConfigs.devN})
          // console.log(this.state.cihazAdii);
          // console.log(this.state.cihazAdii);
          
        })
        .catch(error => {
          console.log("Hatavar : " + error);
        });
    });


}


  DataProcess() {

    
    if (this.state.Reload) {
      this.setState({ Reload: false });


      this.state.datalar.map((CihazListesi, key) => {
        if (CihazListesi.payload&&CihazListesi.payload.sensorveri.length > 0) {

          let sure =
            this.state.Zaman === "w"
              ? CihazListesi.payload.z
                .toString()
                .substr(0, 16)
                .replace("T", " ")
              : CihazListesi.payload.z.toString().substr(11, 5);
              
          let deger = parseFloat(CihazListesi.payload.sensorveri[0].d);
          let ust = parseInt(CihazListesi.payload.sensorveri[0].u);
          let alt = parseInt(CihazListesi.payload.sensorveri[0].a);
          let statu = parseInt(
            CihazListesi.payload.sensorveri[0].drm.toString()
          );
          // console.log(https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod/2/h/2);
          // console.log(statu);
          let tipi = CihazListesi.payload.sensorveri[0].st.toLowerCase();
            
          //let SenAd=CihazListesi.payload.sensorveri[0].ad;
          let StatusList = [
            "Normal",
            "Alt limit alarmı",
            "Üst limit alarmı",
            " ",
            "Pil düşük",
            "Alt limit alarmı ve pil düşük",
            "Üst limit alarmı ve pil düşük",
            " ",
            "Sensör haberleşme hatası",
            "Sensör haberleşme hatası ve alt limit alarmı",
            "Sensör haberleşme hatası ve üst limit alarmı",
          ];
          let StatusListEn = [
            "Normal",
            "Lower limit alarm",
            "Upper limit alarm",
            " ",
            "Battery low",
            "Low limit alarm and battery low",
            "Upper limit alarm and battery low",
            " ",
            "Sensor communication error",
            "Sensor communication error and lower limit alarm",
            "Sensor communication error and high limit alarm",
          ];

          var adi = CihazListesi.payload.sensorveri[0].ad;
          // cihazAdi=this.setState()
          //chartData.push([sure,alt,deger,ust]);
          this.state.GrpData.push([sure, alt, deger, ust]);
          if (this.state.datalar.length === key + 1) {
            if (localStorage.getItem('lang')==='tr') {
              var StatusVerisi = StatusList[statu].toString();
            }else{
              var StatusVerisi = StatusListEn[statu].toString();
            }


            // this.Stttat = StatusVerisi;
            this.state.Ad.push(CihazListesi.payload.sensorveri[0].ad);
            if (this.state.SensorNName !== CihazListesi.payload.sensorveri[0].ad) {
              if (this.state.SensorType !== tipi) {

                this.setState({ SensorType: tipi, });
                switch (tipi) {
                  case "t":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik12" />, SensorIcon: "°C" });
                    break;
                  case "h":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik13" />, SensorIcon: "%RH" });
                    break;
                  case "p":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik14" />, SensorIcon: "P" });
                    break;
                  case "f":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik15" />, SensorIcon: "Hz" });
                    break;
                  case "v":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik16" />, SensorIcon: "V" });
                    break;
                  case "g":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik17" />, SensorIcon: "V" });
                    break;
                  case "d":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik18" />, SensorIcon: "I/O" });
                    break;
                  case "m":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik19" />, SensorIcon: "CH4" });
                    break;
                  case "c":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik20" />, SensorIcon: "CO" });
                    break;
                  case "a":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik21" />, SensorIcon: "mA" });
                    break;
                  case "e":
                    this.setState({ SensorTipi: <Translate content="ozet_grafik22" />, SensorIcon: "dB" });
                    break;
                  default:
                }
              }
              this.setState({ SensorNName: adi });
              this.setState({ AltLimiti: alt });
              this.setState({ UstLimiti: ust });
              this.setState({ SensorNDeger: deger });
              this.setState({CihazTipi:tipi})
              // this.setState({SensorIcon:tipi});
              this.setState({ SenStatus: StatusVerisi });
            }
            // console.log("Status verisi . "+SenAd);
            //console.log("Sensor adı : "+CihazListesi.payload.sensorveri[0].ad);
            //this.SetSensorName(CihazListesi.payload.sensorveri[0].ad);

          }
        }
        return null;
      })
    }
  }
 
  ShowMessage() {

    //    setTimeout(()=>{
    //   this.setState({AlertShow:false});
    // clearTimeout();  },3000);



    return (
      <div className={"alert " + this.state.AlertType} role="alert">
        <strong>{this.state.AlertMessage}</strong>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
  


  render() {
    this.state.GrpFlag=this.state.GrpData[this.state.GrpData.length - 1][2]
    // console.log('GrpFlag burda : '+this.state.GrpData[this.state.GrpData.length - 1][3]);
    // console.log('Grpflag 2 burda: '+this.state.GrpData[this.state.GrpData.length - 1][2]);
    // console.log(this.state.myArray);
    // console.log('Adlar : '+this.state.Ad[0])
    // console.log('Adlar : '+this.state.Ad)
    // console.log('stateler arraysiz :'+this.state.GrpData[this.state.GrpData.length - 1]);
    // console.log('stateler arraysiz :'+this.state.GrpData[this.state.GrpData.length - 1][0]);
    // console.log('stateler :'+this.state.GrpData[this.state.GrpData.length - 1][3]);
    this.state.GrpFlag=this.state.GrpData[this.state.GrpData.length - 1][2]
    var isEn=localStorage.getItem('lang');
    
// console.log(this.state.datalar);
    return (

      <div>
        <template>
         <div>
      {(() => {
         if (this.state.pdf=="one" || this.state.pdf=="") {
          //  this.state.pdf="one"
          // console.log('stateler 1 saatlik :'+this.state.GrpData[this.state.GrpData.length -1][1]);
          // console.log('stateler 1 saatlik :'+this.state.GrpData[this.state.GrpData.length -1][3]);
          // console.log('stateler 1 saatlik :'+this.state.GrpData[this.state.GrpData.length -1][2]);
          return (

                  <table id="table-to-xls">
                    <tr>
                  <th>Sensor Name</th>
                  <th>Upper Limit</th>
                  <th>Lower Limit</th>
                  <th>Sensor Sensitivity</th>
                </tr>
                <tr>
                  <td>{this.state.Ad[0]}</td>
                  <td>{this.state.GrpData[this.state.GrpData.length - 1][3]}</td>
                  <td>{this.state.GrpData[this.state.GrpData.length - 1][1]}</td>
                  <th>{this.state.GrpData[this.state.GrpData.length - 1][2]}</th>
                </tr>  
                  </table>
                  
            
          )
        } else if (this.state.pdf=="eight") {
          
          var d = new Date();
         var h = (d.getHours()<10?'0':'') + d.getHours();
         var m = (d.getMinutes()<10?'0':'') + d.getMinutes();
          var indents=[]
          for(var i= 1;i<=8;i++){
            indents.push(
              
            
              
                <tr key={i}>
                  <td>{(h-i)>0?((h-i)):((h-i)+24)} {":" + m}</td>
                  <td>{this.state.Ad[0]}</td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][3]}</div></td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][1]}</div> </td>
                  <th><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][2]}</div> </th>
                </tr>  
                
                
                 
            )
          }
          return (
            
              
                  <table id="table-to-xls">
                  <tbody>
                  <tr>
                  <th>Time</th>
                  <th>Sensor Name</th>
                  <th>Upper Limit</th>
                  <th>Lower Limit</th>
                  <th>Sensor Sensitivity</th>
                </tr>
                    {indents}
                    </tbody></table>
                  
            
          )
        } else if (this.state.pdf=="twentyfour") {
          var d = new Date();
         var h = (d.getHours()<10?'0':'') + d.getHours();
         var m = (d.getMinutes()<10?'0':'') + d.getMinutes();
          var indents=[]
          for(var i= 1;i<=24;i++){
            indents.push(
              
            
                  
                  
                <tr key={i}>
                <td>{(h-i)>0?((h-i)):((h-i)+24)} {":" + m}</td>
                  <td><div>{this.state.Ad[0]?this.state.Ad[0]:""}</div></td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][3]}</div></td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][1]}</div> </td>
                  <th><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][2]}</div> </th>
                </tr>  
                
                 
            )
          }
          return (
            
              
                  <table id="table-to-xls">
                    <tbody>
                    <tr>
                  <th>Time</th>
                  <th>Sensor Name</th>
                  <th>Upper Limit</th>
                  <th>Lower Limit</th>
                  <th>Sensor Sensitivity</th>
                </tr>
                    {indents}
                    </tbody></table>
                  
            
          )
        }
        else if (this.state.pdf=="week") {
         
          // this.state.pdf="week"
          var indents=[]
          for(let i= 0;i<=168;i++){
            let saatler=(moment().add(-i, 'hours')._d).toString()
            // console.log('here is grpData'+this.state.GrpData[this.state.GrpData.length - i][3]);
            // console.log(saatler.slice(0,25));
            // console.log('Grp Data 30'+this.state.GrpData[this.state.GrpData.length - 30][1]);
            // console.log('Grp Data 30'+this.state.GrpData[this.state.GrpData.length - 31][1]);
            // console.log('Grp Data 30'+this.state.GrpData[this.state.GrpData.length - 32][1]);
            // console.log('Grp Data 30'+this.state.GrpData[this.state.GrpData.length - 33][1]);
            // console.log(this.state.GrpData.length);
            indents.push(
                  
                <tr key={i} >
                  <td>{saatler.slice(0,25)}</td>
                  <td><div>{this.state.Ad[0]!==undefined||this.state.Ad[0]!==null?this.state.Ad[0]:undefined}</div></td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][3]}</div></td>
                  <td><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][1]}</div> </td>
                  <th><div>{this.state.GrpData[this.state.GrpData.length - i]&&this.state.GrpData[this.state.GrpData.length - i][2]}</div> </th>
                </tr>  
                
                 
            )
          }

          
          return (
            
              
                  <table id="table-to-xls">
                    <tr>
                  <th>Time</th>
                  <th>Sensor Name</th>
                  <th>Upper Limit</th>
                  <th>Lower Limit</th>
                  <th>Sensor Sensitivity</th>
                </tr>
                    {indents}</table>
                  
            
          )
        }
      })()}
    </div>
    </template>
      
        {
          this.DataProcess()
        }
        <div className="col-md-6 col-sm-12 col-xs-12">
          {/* <div className="like">
            <a href="favoriler.html">
              <i className="fa fa-heart-o"></i>
            </a>
          </div> */}
          <div className="white-box p-0">
            <div className="row p-10 dashboard">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <h4 className="left-border"><Translate content="summary_system_paragraph"/> 
                {(this.state.SensorNName==="")?"...":this.state.SensorNName}
                
                </h4>
                
                {/* <p>{(this.state.SensorNName===this.state.sensorEvents)?"...":""}</p> */}
                <h5 className="left-border2">
                <Translate content="summary_system_paragraph1"/> {" "}
                  {
                    (this.state.SensorNName==="")?"...":
                  this.state.UstLimiti
                  }
                </h5>
                <h5 className="left-border2">
                <Translate content="summary_system_paragraph2"/> {" "}
                  {
                   (this.state.SensorNName==="")?"...":this.state.AltLimiti
                }
                </h5>
                <h5 className="left-border2">
                <Translate content="summary_system_paragraph3"/> {" "}
                  {(this.state.SensorNName==="")?"...":this.state.SensorNDeger}
                </h5>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="hour-border">
                  <h4>
                    {(this.state.SensorNName==="")?"...":this.state.SensorNDeger} 
                    {(this.state.SensorNName==="")?"...":this.state.SensorIcon}
                  </h4>
                </div>
                <div className={
                  (this.state.SenStatus === "Normal") ? "hour-border"  :
                (this.state.SenStatus=== "Alarm")?"hour-border red-border":(this.state.SenStatus === "Üst limit alarmı"||this.state.SenStatus === "Low limit alarm and battery low"||this.state.SenStatus === "Upper limit alarm"||this.state.SenStatus === ""||this.state.SenStatus==='Sensör haberleşme hatası ve alt limit alarmı'||this.state.SenStatus==='Sensor communication error and lower limit alarm'||this.state.SenStatus==='Upper limit alarm and battery low'||this.state.SenStatus==='Üst limit alarmı ve pil düşük'||this.state.SenStatus==='Sensör haberleşme hatası'||this.state.SenStatus==='Sensor communication error'||this.state.SenStatus==='Lower limit alarm'||this.state.SenStatus==='Sensör haberleşme hatası ve üst limit alarmı'||this.state.SenStatus==='Sensor communication error and high limit alarm'||this.state.SenStatus==='Alt limit alarmı')?"hour-border red-border":
                "hour-border"
                }>
                   
                  
                 <h5> {  (this.state.allSensorEvents.filter(e=>{
                  return this.state.allSensorEvents[0]===this.state.SensorNName?
                  this.state.allSensorEvents[1]:""

                }).toString().substring(10,57))
                }</h5>
                
                <h5><span>{(this.state.SensorNName!="")?
              
              
                  
              <div>
                {((this.state.CihazTipi==='d')&&this.state.SenStatus==='Normal')?<div>{localStorage.getItem('lang')=='tr'?"Boş":"Empty"}</div>:
                <div>
                  {((this.state.CihazTipi==='d')&&(this.state.SenStatus==='Üst limit alarmı'||this.state.SenStatus==='Alt limit alarmı'||this.state.SenStatus==='Upper limit alarm'||this.state.SenStatus==='Lower limit alarm'))?<div>{localStorage.getItem('lang')=='tr'?"Dolu":"Full"}</div>:
                  <div>
                    {this.state.SenStatus==='Sensor communication error and high limit alarm'?
                    <div>
                    <div style={{fontSize:"10px",marginBottom:"-23px"}}>sensor communication error </div>
                  <div style={{fontSize:"10px",marginTop:"-10px"}}>and high limit alarm</div>
                    </div>
                    
                    :
                    this.state.SenStatus==='Sensör haberleşme hatası ve üst limit alarmı'?
                    <div>
                    <div style={{fontSize:"10px",marginBottom:"-23px"}}>sensor haberleşme hatası </div>
                  <div style={{fontSize:"10px",marginTop:"-10px"}}>ve üst limit alarmı</div>
                    </div>
                    
                    :
                    this.state.SenStatus==='Sensor communication error and lower limit alarm'?
                    <div>
                    <div style={{fontSize:"10px",marginBottom:"-23px"}}>sensor communication error </div>
                  <div style={{fontSize:"10px",marginTop:"-10px"}}>and lower limit alarm</div>
                    </div>
                    
                    :
                    this.state.SenStatus==='Sensör haberleşme hatası ve alt limit alarmı'?
                    <div>
                      <div style={{fontSize:"10px",marginBottom:"-23px"}}>sensor haberlesme hatasi </div>
                        <div style={{fontSize:"10px",marginTop:"-10px"}}>ve alt limit alarmı</div>
                          </div>
                          :
                    this.state.SenStatus.length>30?
                    <div style={{fontSize:"10px"}}>{this.state.SenStatus}</div>
                    
                    :
                    this.state.SenStatus.length>20?
                    <div style={{fontSize:"small"}}>{this.state.SenStatus}</div>:
                    
                    this.state.SenStatus}
                  </div>
                  }
                </div>
                }
                </div>:
                <div>{this.state.farkMin<3?
                  
                  localStorage.getItem('lang')=='tr'?
                  "Yükleniyor"
                  :
                  "Loading"
                  :
                  localStorage.getItem('lang')=='tr'?
                  "Hata Offline"
                  :

                  "Error Offline"
                  
                  }</div>  
                
                }</span>
              <span> </span>
              
              
              </h5>
                   
                  
                </div>
                <div className="mini-hour">
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <div
                      className={this.state.AC1}
                      onClick={() => this.ChangeTime("w", 1)}
                    >
                      <a
                        href="\#\"
                        style={{ "pointer-events": "none", cursor: "default" }}
                      >
                        <Translate content="ozet_grafik29"/> 
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <div
                      className={this.state.AC2}
                      onClick={() => this.ChangeTime("d", 2)
                    }
                    >
                      <a
                        href="\#\"
                        style={{ "pointer-events": "none", cursor: "default" }}
                      >
                        <Translate content="ozet_grafik28"/> 
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <div
                      className={this.state.AC3}
                      onClick={() => this.ChangeTime("h8", 3)}
                    >
                      <a
                        href="\#\"
                        style={{ "pointer-events": "none", cursor: "default" }}
                      >
                        <Translate content="ozet_grafik27"/> 
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-xs-3">
                    <div
                      className={this.state.AC4}
                      onClick={() => this.ChangeTime("h", 4)}
                    >
                      <a
                        href="\#\"
                        style={{ "pointer-events": "none", cursor: "default" }}
                      > 
                      {/* {console.log(this.state.SensorNName)} */}
                      {/* {console.log(this.state.GrpData[0])} */}
                        <Translate content="ozet_grafik26"/> 
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             
              <h5>
              {/* {console.log('cihaz tipi:'+this.state.CihazTipi)} */}
              {/* {console.log('statu:'+this.state.SensorNName)} */}
              {/* {this.state.farkkk} */}
                  </h5>
              {/* <h3>{this.state.alarmfarkkk}</h3> */}
              {/* {console.log('user id '+this.state.User);} */}
              {/* <h3>{this.state.farkkk}</h3> */}
              
              
          
   
            {
            
            (this.state.GrpData[this.state.GrpData.length - 1][2]==="Deger")?
            
              <div>
                {
                  this.state.farkMin<3?
                  <Section style={{backgroundColor:'#fff'}}>
                        <Title style={{color:'#138BAC'}}>Veriler İşleniyor</Title>
                        <ReactLoading type="spin" color="#138BAC"/>
                      </Section>
                  
                  :
                  <div>
                <section style={{justifyContent:"center",backgroundColor:"#fff",border:"1px solid #138BAC",width:"100%",height:"100%",flexWrap:"wrap"}} >
                <Title><h5  >{this.state.cihazAdii}<br></br></h5></Title>
                
                <Title  ><h5>{this.state.farkkk}</h5><Translate style={{color:"black"}}  content="ozet_grafik30"></Translate></Title>
               
                </section>
                </div>
                }
                
              </div>

              

              
            
             
            :
           
                      <div >
                        <div >
                        {
                this.state.CihazTipi==='d'?
                "":
                    <div >
                      <Chart
                      width={"100%"}
                      height={"100%"}
                      chartType="Line"
                      loader={<div></div>}
                      data={this.state.GrpData} //{chartData}
                      options={{
                        colors: ["#f7290e", "#138bac", "#f7290e"],
                        legend: { position: "none" },
                        hAxis: { title: "Zaman (s)" },
                        vAxis: { title: "Sıcaklık" },
                        width: "100%",
                        height: 300
                      }
                    }
                      rootProps={{ "data-testid": "4" }}
                      />
                   
                    </div>      
                      
                
              }
                        </div>



                        </div>


               

            }
            
        
        
            <div className="row m-t-30 p-10">
              <div  className="col-md-6 col-sm-12 col-xs-12 m-b-10">
                {/* <a className="btn btn-block btn-outline btn-default btn-lg" onClick={()=>{this.handlePDF()}}  ><Translate content="summary_system_paragraph6" /></a> */}
                {
                  
                           (isEn=='en' )?(
                            
                            <button className="btn btn-block btn-outline btn-default btn-lg"  onClick={() => {
                              window.location.href=`/rapor/${this.state.lastIndex}`
                            }}>Get Report</button>
                            // <ReactToExcel className="btn btn-block btn-outline btn-default btn-lg" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px"}} table="table-to-xls" filename="excelFile" sheet="Esense Report" buttonText="Get Report" ></ReactToExcel>
                          
                            
                            ):(
                              <button className="btn btn-block btn-outline btn-default btn-lg"  onClick={() => {
                                window.location.href=`/rapor/${this.state.lastIndex}`
                              }}>Rapor Al</button>
                            // <ReactToExcel  className="btn btn-block btn-outline btn-default btn-lg" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} table="table-to-xls" filename="excelFile" sheet="Esense Report" buttonText="Rapor Al" ></ReactToExcel>
                           )
                }
                {/* <ReactToExcel className="btn btn-block btn-outline btn-default btn-lg" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} table="table-to-xls" filename="excelFile" sheet="Esense Report" buttonText="Rapor Al" ></ReactToExcel> */}
                {/* <ReactToExcel className="btn btn-info waves-effect waves-light" style={{ "width": "60px", "margin-right": "5px", "border-radius": "4px" }} table="table-to-xls" filename="excelFile" sheet="Esense Report" buttonText="Excel" /> */}
              </div>

              <div className="col-md-6 col-sm-12 col-xs-12">
                
                
                <Translate data-target={"#exampleModal" + this.props.sensor} component="button"  content="summary_system_paragraph13" type="button" data-toggle="modal" attributes={{placeholder:'summary_system_paragraph13'}} id="openBtn1" data-id="1"  className="btn btn-block btn-outline btn-default btn-lg"  onClick={() => {
                  this.setState({ SaveButtonHide: "hidden" });
                  // console.log(this.props.sensor);
                }}></Translate>

              </div>
            </div>

            <div>



              <div className="modal fade" id={"exampleModal" + this.props.sensor} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" >
                  <div className="modal-content" >
                    <div className="modal-header" >

                      <button  id={"CloseButton" + this.props.sensor} type="button" className="close " data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" className="mt-20" >&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">

                      <div id="wrapper">
                        <div className="white-box m-b-0">
                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <h3 className="m-t-0 m-b-30 left-border"><Translate content="summary_system_paragraph7" /></h3>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                              <form action="" data-toggle="validator">

                                <div className="form-horizontal">
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label"><Translate content="summary_system_paragraph8" /></label>
                                    <div className="col-xs-8 pull-right">
                                      <input type="text" name="mac_adresi" id="mac_adresi" className="form-control" Value={this.props.CihazID} readOnly disabled />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label"><Translate content="summary_system_paragraph9" /></label>
                                    <div className="col-xs-8 pull-right">
                                      <input type="text" name="sensor_adi" id={"sensor_adi"+this.props.sensor} className="form-control" Value={this.state.Ad[0]} onChange={this.handleChangeName} required  />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label"><Translate content="summary_system_paragraph10" /></label>
                                    <div className="col-xs-8 pull-right">
                                      <input type="number" name="ust_limit" id={"ust_limit" + this.props.sensor} className="form-control" onChange={this.handleChangeUpper} Value={this.state.GrpData[this.state.GrpData.length - 1][3]} required />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label"><Translate content="summary_system_paragraph11" /></label>
                                    <div className="col-xs-8 pull-right">
                                      <input type="number" name="alt_limit" id={"alt_limit" + this.props.sensor} className="form-control" onChange={this.handleChangeUpper} Value={this.state.GrpData[this.state.GrpData.length - 1][1]} required />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label"><Translate content="summary_system_paragraph12" /></label>
                                    <div className="col-xs-8 pull-right">
                                      <input type="text" name="sensor_hassasiyeti" id="sensor_hassasiyeti" className="form-control" Value={this.state.GrpData[this.state.GrpData.length - 1][2]} required disabled />
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label className="col-xs-4 control-label hidden-xs"></label>
                                    <div className="col-xs-8 pull-right">
                                      <button type="button" className="btn btn-info waves-effect waves-light" style={{ "width": "120px", "visibility": this.state.SaveButtonHide }} onClick={() => { this.UpdateCommandSend() }} value="data-id" >Kaydet</button>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    {this.state.AlertShow ? this.ShowMessage() : null}
                                  </div>
                                </div>




                              </form>
                              {/* Pdf Print */}
                                  <template>
                                  <table className="deneme" id="deneme" ref="deneme">
                                        
                                        <tr>
                                        <th>Sensor Name</th>
                                        <th>Upper Limit</th>
                                        <th>Lower Limit</th>
                                        <th>Sensor Sensitivity</th>
                                      </tr>
                                      <tr>
                                        <td>{this.state.Ad[0]}</td>
                                        <td>{this.state.GrpData[this.state.GrpData.length - 1][3]}</td>
                                        <td>{this.state.GrpData[this.state.GrpData.length - 1][1]}</td>
                                        <th>{this.state.GrpData[this.state.GrpData.length - 1][2]}</th>
                                      </tr>
                                                
                                               
                                       </table>
                                </template>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>


            </div>

          </div>

        </div>


      </div>


    );
  }
}



export default OzetGrafik;
