import React, { Component } from 'react';
import Translate from 'react-translate-component'
import counterpart from 'counterpart'





class GizlilikPolitikasi extends Component {
   

    render() {
        // console.log(this.props);
        // console.log(this.state);
        // this.props.auth.getUserInfo('https://lrnbpg9397.execute-api.eu-west-1.amazonaws.com/prod');

        

        const placeholder=counterpart.translate('place')

        return (
            
            <div id="page-wrapper" className="m-t-20 m-b-20">

                {/* <select value={this.state.lang} onChange={this.onLangChange}  >
                    <option value="tr" >TR</option>
                    <option value="en" >EN</option>
                </select> */}
                {/* <Translate content="hakkimizda" component="h5" className="m-t-0" /> */}

                {/* <Translate content="copy.p1" component="p" unsafe={true} /> */}

                {/* <Translate content="copy.p2" component="p" with={{link}} /> */}

                <Translate component="div" type="text" attributes={{}}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h3><Translate content="site_map12"  /></h3>
                        {/* <Translate content="hakkimizda" component="h3" className="m-t-0"/> */}
                    </div>
                    
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="white-box">
                            <p content="hakkimizda">

                            {

                            }
                            <div >
                            <h5 ><a href="/SikcaSorulanSorular"><Translate content="site_map" style={{color:"black"}} /></a></h5>
                            <h5><a href="/Iletisim"><Translate content="site_map1" style={{color:"black"}}/></a></h5>
                            <h5><a href="/Hakkimizda"><Translate content="site_map2"  style={{color:"black"}}/></a></h5>
                            <h5><a href="https://www.eds.com.tr/"><Translate content="site_map3" style={{color:"black"}} /></a></h5>
                            <h5><a href="/TeknikDestek"><Translate content="site_map4" style={{color:"black"}} /></a></h5>
                            <h5><a href="/GizlilikPolitikasi"><Translate content="site_map5"  style={{color:"black"}}/></a></h5>
                            <h5><a href="/hesap"><Translate content="site_map6" style={{color:"black"}} /></a></h5>
                            <h5><a href="/ayarlar"><Translate content="site_map7" style={{color:"black"}} /></a></h5>
                            <h5><a href="/CanliKamera"><Translate content="site_map8" style={{color:"black"}} /></a></h5>
                            <h5><a href="/olaylar"><Translate content="site_map9" style={{color:"black"}} /></a></h5>
                            <h5><a href="/rapor"><Translate content="site_map10" style={{color:"black"}} /></a></h5>
                            <h5><a href="/Dashboard"><Translate content="site_map11" style={{color:"black"}} /></a></h5>
                            
                            </div>
                            

                                <br /><br />
        
                                
        
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default GizlilikPolitikasi;